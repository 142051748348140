<!-- usado en resutlados de busqueda -->
<div *ngIf="mode === 'normal'" class="mybox">
  <div class="anotherbox">
    <div class="fondoCard" (click)="openArtModal(); artOpened(articulo)">
      <div class="ofertareal" *ngIf='articulo.isdeOferta()'>
        <div class="labeloferta">
          Oferta!
        </div>
      </div>
      <div class="ofertareal" *ngIf='articulo.misPromo.length !== 0'>
        <div class="labeloferta">
          {{articulo.getPromos()[0].getAbreviaturaPromocion() || articulo.getPromos()[0].getNombrePromocion()  }}
        </div>
      </div>

      <div class="imag">
        <img [width]="125" [height]="125" *ngIf="articulo.getImgDownload()" class="imasrc" id="imagsrc" [src]="articulo.getFotoImagen()"
          loading="lazy" [alt]="articulo.getNombreMarca()">
      </div>
      <div class="anotherandanother">
        <div class="nombreMarca" >
          {{ articulo.getNombreMarca() }}
        </div>
        <div class="mindesc" style="text-align: center;">
          {{articulo.getDescripCion()}}
        </div>
        <div class="tittle">
          {{articulo.getPresentacion()}}
        </div>
        <div class="slimorden" *ngIf='articulo.getCantidadMayorista() > 0'>
          <div class="oferta-may">
            <div>Llevando {{articulo.getCantidadMayorista()}} o más </div>
            <div style="font-weight: bold; font-size: 24px">${{articulo.getPrecioMayorista()}}</div>
            <div> c/u</div>
          </div>
        </div>

        <!-- <span class="tachado" *ngIf='articulo.misPromo.length !== 0'>{{articulo.getPrecio() | currency}}</span> -->
        <span class="tachado"
          *ngIf='articulo.isdeOferta() || articulo.getCantidadMayorista() > 0 && articulo.getCantidad() >= articulo.getCantidadMayorista()'>
          <div *ngIf="!articulo.getFraccion()" >
            {{articulo.getPrecio() | currency}}
          </div>
          <div *ngIf="articulo.getFraccion()" >
            {{ articulo.getPrecioTotalFraccion() | currency }}
          </div>
        </span>
        <div class="precio"  style="justify-content: center;">
          <div class="preciogrande" *ngIf='articulo.isdeOferta() && articulo.misPromo.length !== 0'>
            <div *ngIf="!articulo.getFraccion()" >
              {{articulo.getPrecioOferta() | currency}}
            </div>
            <div *ngIf="articulo.getFraccion()" >
              {{ articulo.getPrecioOferta() * articulo.getFraccion() | currency }}
            </div>
          </div>
          <div class="preciogrande" *ngIf='articulo.isdeOferta() && articulo.misPromo.length === 0'>
            <div *ngIf="!articulo.getFraccion()" >
              {{articulo.getPrecioOferta() | currency}}
            </div>
            <div *ngIf="articulo.getFraccion()" >
              {{ articulo.getPrecioOferta() * articulo.getFraccion() | currency }}
            </div>
          </div>
          <div class="preciogrande" *ngIf='articulo.isdeOferta() === false && articulo.misPromo.length !== 0'>
            {{articulo.getPrecioAmostrar() | currency}}
          </div>
          <div class="preciogrande"
            *ngIf='articulo.isdeOferta() === false && articulo.misPromo.length === 0 && articulo.getCantidadMayorista() > 0 && articulo.getCantidad() >= articulo.getCantidadMayorista()'>
            {{articulo.getPrecioMayorista() | currency}}
          </div>
          <div class="preciogrande"
            *ngIf='articulo.isdeOferta() === false && articulo.misPromo.length === 0 && articulo.getCantidad() < articulo.getCantidadMayorista() || articulo.isdeOferta() === false && articulo.misPromo.length === 0 && articulo.getCantidadMayorista() == 0'
            >
            <div *ngIf="!articulo.getFraccion()" >
              {{articulo.getPrecio() | currency}}
            </div>
            <div *ngIf="articulo.getFraccion()" >
              {{ articulo.getPrecioTotalFraccion() | currency }}
            </div>
          </div>
          <div *ngIf="articulo.getUnidadMedida().length" class="porcantidad" >
            x {{articulo.getFraccion()?articulo.getFraccion():''}} {{ articulo.getUnidadMedida() }}
          </div>
        </div>
        <div class="preciochico" *ngIf="articulo.getPrecioPartido() !== -1" style="text-align: center;" >
          {{articulo.getPrecioPartido() | currency}} {{articulo.getOtrosPrecios()}}
        </div>
      </div>
    </div>
    <boton-sumador class="sumador" [fraccion]="articulo.getFraccion()" [unidadMedida]="articulo.getUnidadMedida()"(quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()">
    </boton-sumador>
  </div>
</div>
<!-- usado en pantalla de seleccion de envio/retiro y eleccion de medio de pago-->
<div *ngIf="mode==='postcheckout'" class="boxcheckout" (click)="artOpened(articulo)">
  <img *ngIf="articulo.getImgDownload()" class="checkoutimagen" [heigth]="150" [width]="150" [src]="articulo.getFotoImagen()" loading="lazy">
  <div class="detallescheckout">

    <div class="descripcheckout">
      {{articulo.getDescripCion()}}
    </div>

    <div class="marcachekout">
      {{articulo.getPresentacion()}}
    </div>

    <div class="unidadescheckout">
      <div *ngIf="!articulo.getFraccion()" >
        {{ articulo.getCantidad() }} {{ articulo.getCantidad() === 1 ? 'unidad': 'unidades'}}
      </div>
      <div *ngIf="articulo.getFraccion()" >
        {{articulo.getCantidad()}} {{ articulo.getUnidadMedida() }}
      </div>
      <div *ngIf="articulo.misPromo.length == 0">
        <div>
          {{ articulo.getPrecioCalculado() | currency}}
        </div>
      </div>
      <div
        *ngIf="articulo.misPromo.length != 0 && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() != 0">
        <div >
          {{ articulo.getPrecioCalculado() | currency}}
        </div>
      </div>
      <div
        *ngIf="articulo.misPromo.length != 0 && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() === 0">
        <div *ngIf="!articulo.getFraccion()" >
          {{ articulo.misPromo[0].getPrecioCalculado() | currency}}
        </div>
        <div *ngIf="articulo.getFraccion()" >
          {{ articulo.misPromo[0].getPrecioCalculado() * articulo.getFraccion() | currency }}
        </div>
      </div>
    </div>
  </div>

</div>
<!-- usado en carrito modal -->
<div *ngIf="mode === 'slim'" class="slimorden border">
  <div class="myboxslim">
    <div style="height: 100px; width: 100px;flex-shrink:0" >
      <img *ngIf="articulo.getImgDownload()" [height]="100" [width]="100" class="minimages myimg" [src]="articulo.getFotoImagen()" loading="lazy"
        [alt]="articulo.getNombreMarca()">
    </div>
    <div class="newcontainer">
      <div (click)="eliminar()" class="eliminarbtn">
        Quitar <span class="naiz">X</span>
      </div>
      <div class="minititle">
        {{articulo.getDescripCion()}}.
      </div>
      <div class="descmini">
        {{articulo.getPresentacion()}}.
      </div>
      <div class="miyoferta">
        <div class="slimorden">
          <div *ngFor="let oferta of articulo.getPromosActivas()" class="oferta">
            {{oferta}}
          </div>
        </div>

      </div>
      <div class="endline">
        <div class="porunidpc">
          <div *ngIf="articulo.isdeOferta()">
            <span class="tachado">
              <div *ngIf="!articulo.getFraccion()" >
                {{ articulo.getPrecio() | currency}}
              </div>
              <div *ngIf="articulo.getFraccion()" >
                {{ articulo.getPrecio() * articulo.getFraccion() | currency}}
              </div>
            </span>
            <div *ngIf="!articulo.getFraccion()" >
              {{articulo.getPrecioOferta() | currency}} u.
            </div>
            <div *ngIf="articulo.getFraccion()" >
              {{ articulo.getPrecioOferta() * articulo.getFraccion() | currency }} u.
            </div>
          </div>
          <div *ngIf="!articulo.isdeOferta()" >
            <div *ngIf="!articulo.getFraccion()" >
              {{articulo.getPrecio() | currency}} u.
            </div>
            <div *ngIf="articulo.getFraccion()" >
              {{ articulo.getPrecio() * articulo.getFraccion() | currency }} u.
            </div>
          </div>
        </div>
        <div class="botonsun">
          <boton-sumador class="botonSumadorSlim" [fraccion]="articulo.getFraccion()" [unidadMedida]="articulo.getUnidadMedida()"(quantyEvent)="sumaevent($event)" [modo]="'detail'"
            [contador]="articulo.getCantidad()"></boton-sumador>
        </div>
        <div class="preciomini" *ngIf="articulo.getPromosActivas().length==0 ; else conpromo" >
          <div *ngIf="!articulo.getFraccion()" >
            {{articulo.getPrecioCalculado() | currency}}
          </div>
          <div *ngIf="articulo.getFraccion()" >
            {{ articulo.getPrecioCalculado() * articulo.getFraccion() | currency }}
          </div>
        </div>
        <ng-template #conpromo>
          <div class="preciomini">
            <span class="tachado">
              <div>
                {{(articulo.getPrecio() * articulo.getCantidad()) | currency}}
              </div>
            </span>
            <div class="preciron"
              *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() != 0">
              <div>
                {{articulo.getPrecioCalculado() | currency}}
              </div>
            </div>
            <div class="preciron"
              *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() === 0">
              {{ articulo.misPromo[0].getPrecioCalculado() | currency }}
            </div>
            <div class="preciron" *ngIf="articulo.isdePromo === false && articulo.isdeOferta() === true">
              <div>
                {{ articulo.getPrecioOferta() * articulo.getCantidad() | currency}}
              </div>
            </div>
            <div class="preciron" *ngIf="articulo.isdePromo === false && articulo.isdeOferta() === false">
              <div>
                {{articulo.getPrecioCalculado() | currency}}
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<!-- resultados temporales en barra de busqueda -->
<div *ngIf="mode==='ultraslim'" class="minibox">
  <div class="descuentodel">
    0% de Descuento
  </div>
  <div class="nombredelprod">
    <span class="resaltado"> {{articulo.getNombreMarca()}}</span> {{articulo.getDescripCion()}}.
    {{articulo.getPresentacion()}}
  </div>
  <div class="preciodeofert">
    {{articulo.getPrecio() | currency}}
  </div>
  <img *ngIf="articulo.getImgDownload()" class="carruimages" [heigth]="150" [width]="150" [src]="articulo.getFotoImagen()" loading="lazy"
    [alt]="articulo.getNombreMarca()">


</div>
<!-- usado en pantalla de detalles de pedido -->
<div *ngIf="mode==='detalletrakin'" class="mytabla2">

  <div class="prod2">
    <img class="myimg2" [src]="articulo.getFotoImagen()" loading="lazy">
    <div class="labelgrande2">
      <span class="descrmarca2">{{articulo.getDescripCion()}}. {{articulo.getPresentacion()}}</span>
    </div>

  </div>
  <div class="containerPrecio">
    <div class="preciu center" *ngIf='articulo.isdeOferta()'>
      <span class="tachado" *ngIf='articulo.isdeOferta()'>{{articulo.getPrecio() | currency}}</span>
      {{articulo.getPrecioOferta() | currency}}
    </div>
    <div class="preciu center" *ngIf='!articulo.isdeOferta()'>
      {{(articulo.getPrecio()) | currency}}
    </div>
    <div *ngIf="!articulo.getFraccion()" class="canti2 center" >
      x {{articulo.getCantidad()}} unidades
    </div>
    <div *ngIf="articulo.getFraccion()" class="canti2 center" >
      x {{ articulo.getCantidad()}} {{ articulo.getUnidadMedida()}}
    </div>
    <div class="preciureal center"
      *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() != 0">
      {{articulo.getPrecioCalculado() | currency}}
    </div>
    <div class="preciureal center"
      *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() === 0">
      <span class="tachado">{{(articulo.getPrecio() * articulo.getCantidad()) | currency}}</span>
      {{articulo.misPromo[0].getPrecioCalculado() | currency}}
    </div>
    <div class="preciureal center" *ngIf="articulo.isdePromo === false">
      {{articulo.getPrecioCalculado() | currency}}
    </div>
  </div>
  <!-- <div class="preciureal2 center">
        {{ articulo.getPrecioCalculado() | currency}} (precio total)
    </div> -->
  <div class="options2 center">

  </div>

</div>
<!-- usado en carrito -->
<div *ngIf="mode==='detalle'" class="mytabla">
  <div class="elimin noselect" (click)="eliminar()">
    <div>
      <img src="../../assets/icons/borrardetalle.svg"  loading="lazy" class="eliminarArt" [alt]="'eliminarArt'">
    </div>
    <div class="icolabel">
      Eliminar
    </div>
  </div>
  <img class="myimg imgajuster" [src]="articulo.getFotoImagen()" [heigth]="100" [width]="100" loading="lazy">
  <div class="labelgrande">
    <span class="descrmarca">{{articulo.getDescripCion()}}. {{articulo.getPresentacion()}}</span>
  </div>
  <div class="preciu center" *ngIf='articulo.isdeOferta()'>
    <div *ngIf="width < 600">
      Precio x Un:
    </div>
    <span class="tachado" *ngIf='articulo.isdeOferta()'>
      <div *ngIf="!articulo.getFraccion()" >
        {{articulo.getPrecio() | currency}}
      </div>
      <div *ngIf="articulo.getFraccion()" >
        {{ articulo.getPrecio() * articulo.getFraccion() | currency }}
      </div>
    </span>
    <div *ngIf="!articulo.getFraccion()" >
      {{articulo.getPrecioOferta() | currency}}
    </div>
    <div *ngIf="articulo.getFraccion()" >
      {{ articulo.getPrecioOferta() * articulo.getFraccion() | currency }}
    </div>
  </div>
  <div class="preciu center" *ngIf='articulo.isdeOferta() === false'>
    <div *ngIf="width < 600">
      Precio x Un:
    </div>
    <div>
      {{articulo.getPrecio() | currency}}
    </div>
  </div>
  <div class="canti center">
    <boton-sumador [fraccion]="articulo.getFraccion()" [unidadMedida]="articulo.getUnidadMedida()"(quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()" [modo]="'detail'">
    </boton-sumador>
    {{articulo.getFraccion()?'Peso':'Unidades'}}
  </div>
  <div class="preciureal center" *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() !== 0">
    <div *ngIf="width < 600" >
      Total:
    </div>
    <div>
      {{ articulo.getPrecioCalculado() | currency}}
    </div>
  </div>
  <div class="preciureal center" *ngIf="articulo.isdePromo === true && articulo.getCantidad() % articulo.misPromo[0].cantidadenPromo() === 0">

    <div *ngIf="width < 600" >
      Total:
    </div>
    <span *ngIf="articulo.isdeOferta()" class="tachado">
      <div>
        {{(articulo.getPrecioOferta() * articulo.getCantidad()) | currency}}
      </div>
    </span>
    <span *ngIf="!articulo.isdeOferta()" class="tachado">
      <div>
        {{(articulo.getPrecio() * articulo.getCantidad())| currency}}
      </div>
    </span>
    <div>
      {{ articulo.misPromo[0].getPrecioCalculado() | currency}}
    </div>
  </div>
  <div class="preciureal center" *ngIf="articulo.isdePromo === false">
    <div *ngIf="width < 600" >
      Total:
    </div>
    <div>
      {{ articulo.getPrecioCalculado() | currency}}
    </div>
  </div>
  <!--
  <div class="options center">
    <mmax-input-selector [opciones]="articulo.getRemplazos()" (onSelect)="categoriaSelec($event)">
    </mmax-input-selector>
  </div> -->

</div>
<!-- los resultados adentro de barra de busqueda -->
<div *ngIf="mode==='buqueda'" class="prediccion" (click)="iraSeach();" onclick="window.scrollTo(0,0)" >
  <div class="icono">
  </div>
  <div class="descrip">
    {{articulo.getDescripCion()}}.
  </div>
</div>
<!-- usado en pantalla de Ofertas y Promociones -->
<div *ngIf="mode === 'ofertas'" class="anotherbox" >
  <div (click)="openArtModal(); artOpened(articulo)">
    <div class="ofertareal" *ngIf='articulo.isdeOferta()'>
      <div class="labeloferta">
        Oferta.
      </div>
    </div>
    <div class="imag">
      <img *ngIf="articulo.getImgDownload()" class="imasrc" id="imagsrc" [heigth]="150" [width]="150" [src]="articulo.getFotoImagen()" loading="lazy"
        [alt]="articulo.getNombreMarca()">
    </div>
    <div class="anotherandanother">
      <div class="mindesc">
        {{articulo.getDescripCion()}}
      </div>
      <div class="tittle">
        {{articulo.getPresentacion()}}
      </div>
      <!-- <div class="preciochico">
                    {{articulo.getPrecioPartido() | currency}} {{articulo.getOtrosPrecios()}}
                </div> -->
      <span class="tachado">{{articulo.getPrecio() | currency}}</span>
      <div class="precio">
        <div class="preciogrande">
          {{( articulo.getPrecioOferta() * 1)| currency}}
        </div>
        <div class="porcantidad">
          x un
        </div>
      </div>
    </div>
  </div>
  <boton-sumador class="sumador" [fraccion]="articulo.getFraccion()" [unidadMedida]="articulo.getUnidadMedida()"(quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()"></boton-sumador>
</div>
<!-- usado en landing page en seccion ofertas  -->
<div *ngIf="mode === 'ofertas-land'" class="anotherboxOfertaLand" >
  <div (click)="openArtModal(); artOpened(articulo)">
    <div style="display: flex;">
      <div class="ofertareal" *ngIf='articulo.isdeOferta()'>
        <div class="labeloferta">
          Oferta.
        </div>
      </div>
      <div class="imag">
        <img *ngIf="articulo.getImgDownload()" class="imasrc" id="imagsrc" [heigth]="150" [width]="150" [src]="articulo.getFotoImagen()" loading="lazy"
          [alt]="articulo.getNombreMarca()">
      </div>
    </div>
    <div class="anotherandanother">
      <div class="mindesc">
        {{articulo.getDescripCion()}}
      </div>
      <div class="tittle">
        {{articulo.getPresentacion()}}
      </div>
      <!-- <div class="preciochico">
                    {{articulo.getPrecioPartido() | currency}} {{articulo.getOtrosPrecios()}}
                </div> -->
      <span class="tachado">{{articulo.getPrecio() | currency}}</span>
      <div class="precio">
        <div class="preciogrande">
          {{( articulo.getPrecioOferta() * 1)| currency}}
        </div>
        <div class="porcantidad">
          x un
        </div>
      </div>
    </div>
  </div>
  <boton-sumador style="zoom: 90%;" [fraccion]="articulo.getFraccion()" [unidadMedida]="articulo.getUnidadMedida()"(quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()"></boton-sumador>

</div>
<div *ngIf="mode === 'promos' && articulo.getPrecioAmostrar() != 0" class="ofertacard" >
  <div class="contenidoofert">
      <div class="oferlogo">
          {{articulo.getTipoPromo()}}
      </div>
      <div class="ofertext">
          {{articulo.getDescripCion()}}
      </div>
      <div class="imagepr">
          <img class="img-pr" *ngIf="articulo.getImgDownload()" [src]="articulo.getFotoImagen()" [heigth]="150" [width]="150" loading="lazy"
              [alt]="articulo.getDescripCion()">
      </div>
      <div class="descriofer">
          <div class="descprod">

          </div>
          <div class="cantidadofer">
              <!--$ x Lt. 100-->

          </div>
          <div class="precie">
              {{articulo.getPrecioAmostrar() | currency}}
          </div>
          <div class="btnarena">
              <boton-sumador (quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()">
              </boton-sumador>
          </div>

      </div>

  </div>


</div>

<div class="layout-mobile" *ngIf="mode==='precheckout'">
  <div class="remplazosview">
    <img *ngIf="articulo.getImgDownload()" class="myimg" [src]="articulo.getFotoImagen()" [heigth]="150" [width]="150" loading="lazy">
    <div class="detalles">
      <div class="leyendaprecheck">
        {{articulo.getDescripCion()}}
      </div>
      <div class="presecheckout">
        {{articulo.getPresentacion()}}
      </div>

      <div class="remplazadorchekout">

      </div>
    </div>
    <div class="unidades">
      <div class="preciocheckoutmobile">
        {{ articulo.getPrecioCalculado() | currency}}
      </div>
      <div class="blueboton">
        {{articulo.getCantidad()}} un.
      </div>
    </div>
  </div>
  <div class="sectorremplz">
    Reemplazo:
    <selector-generico [mode]="'inputSel'" [remplazos]="misremplasos" [seleRem]="articulo.getRemplazo()" (onSelect)="categoriaSelec($event)" ></selector-generico>
  </div>

</div>
