import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ArticulosGeneral, EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';
import { GenericoSlideAnim } from '../etc/generico-animation';
import { ActivatedRoute } from '@angular/router';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';
declare global {
  interface Window {
    search: boolean;
  }
}

let search = window.search;

@Component({
  selector: 'searchview-generic',
  templateUrl: './search-view-generic.component.html',
  styleUrls: ['./search-view-generic.component.scss'],
  animations: [GenericoSlideAnim]
})

export class SearchViewGenericComponent implements OnInit {
  @Input('mode') modo: string;

  arrowsrc: string;
  resultados: ArticulosGeneral[]
  busquedaActual: string;
  currentSlide: number;
  toggle: boolean;
  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService,private route: ActivatedRoute) {
    this.arrowsrc = '../../assets/icons/arrow-left-blue.svg';
    this.busquedaActual = "";
    this.currentSlide = -1;
    this.toggle = false;
  }


  ngOnInit(): void {
    new Image().src = this.arrowsrc;
    this.genServ.getMiniSearchProvider().subscribe(data => {
      this.resultados = data;
    });
    this.modo = this.modo || 'normal';
  }

  get desplegado() {
    return this.serv.desplegadosearch;
  }

  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  onChange(texto: string) {
    this.currentSlide = -1;
    this.serv.abriryCerrrarSearch(true);
    //console.log('onchange', this.busquedaActual)
    this.serv.inciarbusqueda(this.busquedaActual);
    this.genServ.getBusqueda(this.busquedaActual);
  }

  onclick() {
    this.toggle = !this.toggle;
    this.serv.abriryCerrrarSearch(this.toggle);

  }

  onSearch() {
    window.addEventListener('click', function (e) {
      this.search = false;
      if (document.getElementById('searchInput').contains(e.target as Node)) {
        this.search = true
      } else {
        this.search = false
      }
    })
  }

  searchLupa() {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    console.log('searchlupa', this.busquedaActual)
    this.serv.inciarbusqueda(this.busquedaActual);
    this.serv.navigator.goSearch(this.route);
    this.serv.abriryCerrrarSearch(false);
    this.genServ.getBusqueda(this.busquedaActual);
  }

  oncloseGap(event) {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.abriryCerrrarSearch(false);
  }

  selecionadoItem(item: ArticulosGeneral) {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.busquedaActual = item.getDescripCion();
    //console.log('seleccionado item', this.busquedaActual)
    this.serv.inciarbusqueda(this.busquedaActual);
    this.serv.navigator.goSearch(this.route);
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "ArrowDown") {
      if ((this.currentSlide + 1) < this.resultados.length) {
        this.currentSlide += 1;
      }

    }

    if (event.key === "ArrowUp") {
      if ((this.currentSlide - 1) >= -1) {
        this.currentSlide -= 1;
      }
    }

    if (event.key === "Enter") {
      if (window.search == true) {
        if (this.resultados[this.currentSlide] != undefined) {
          this.selecionadoItem(this.resultados[this.currentSlide]);
          this.serv.abriryCerrrarSearch(false);
        }
        else {
          for (let i = 0; i < this.menus.length; i++) {
            const element = this.menus[i];
            element.activo = false;
          }
          console.log('hostlistener')
          this.serv.inciarbusqueda(this.busquedaActual);
          this.serv.navigator.goSearch(this.route);
          this.serv.abriryCerrrarSearch(false);
          this.genServ.getBusqueda(this.busquedaActual);
        }
      }
    }
  }

  volveratras() {
    this.serv.navigator.goLandPage(this.route);
  }

  volvermobile() {
    this.serv.navigator.goLandPage(this.route);
  }
}
