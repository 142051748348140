import { ArticulosGeneral } from '../class/articulos-general.interface';
import { PromocionesEccomerce } from '../class/promociones-class';
import { OfertasEcommerce } from './ofertas-ecommerce.interface';
import { RemplazosMMax } from './remplazos-ecommerce.interface';
import { TagsEcommerce } from './tags-eccomerce.interface';

export class ArticulosEcommerce extends ArticulosGeneral {
  constructor(
    marca: number,
    codigo: number,
    private precio: number,
    private precionormal: number,
    private cmax: number,
    private da: string,
    public descripcion: string,
    private unidadesxb: number,
    private presentacion: string,
    private pesoxb: number,
    private pesable: number,
    private pesableporunidad: number,
    private nombremarca: string,
    marcareal: number,
    private cantidad: number,
    private coeficiente: number,
    private preciopor: number,
    private unidaddmedida: string,
    private cantidadmayorista: number,
    private preciomayorista: number,
    private etiquetamedida: string,
    private foto: any,
    private remplazo: number,
    private remplazos: RemplazosMMax[],
    private frecuente?: boolean,
    cantiadadpedido?: number,
    private fraccion?: number,
    private ppfraccion?: number //precio fraccion
  ) {
    super(marca, codigo, marcareal);
    this.cantiadadpedido = this.cantiadadpedido || 0;
    this.misPromo = [];
    this.cantidadInicial = 0;
    this.descargoimagen = false;
    this.modificacionPrecio = 0;
    this.precioAyuda = this.precio;
    this.promoCiones = [];
    this.isdeoferta = false;
    this.tags = [];
    this.isdePromo = false;
    this.counterPromOferta = 0;
  }

  setTag(tag: TagsEcommerce) {
    this.tags.push(tag);
  }

  getTag(): TagsEcommerce[] {
    return this.tags;
  }

  setMyBlob(body: Blob) {
    this.myBlob = body;
  }

  getMyBlob(): Blob {
    return this.myBlob;
  }

  getImgDownload(): boolean {
    return this.descargoimagen;
  }

  setImgDownload(valor: boolean) {
    this.descargoimagen = valor;
  }

  setdeOferta(ofertas: OfertasEcommerce) {
    this.isdeoferta = true;
    this.deOferta = ofertas;
  }

  isdeOferta(): boolean {
    return this.isdeoferta;
  }

  getPrecioCalculado(): number {
    //codigo 10205 marca 3
/*     if(this.codigo === 10205 && this.marca === 3){
      console.log('articulo precio calculado', 'isdeoferta', this.isdeoferta, 'this.precioOferta',this.precioOferta,'cantidadpedido',this.cantiadadpedido,)
    } */
    if (this.isdeoferta === true) {
      if (this.isdePromo === true) {
        return (
          this.precioOferta + -this.deOferta.precio * this.misPromo[0].counter
        );
      }
      else {
        return this.getPrecioOferta() * this.cantiadadpedido;
      }
    }
    else {
      return this.precio * this.cantiadadpedido + this.modificacionPrecio;
    }
  }

  getPrecioOferta(): number {
    return this.deOferta.precio * 1;
  }

  async operacionesExtras() {
    let numeros: number[] = [];
    this.promoCiones = [];
    let descripciones: Map<number, string> = new Map();
    this.modificacionPrecio = 0;
    this.precioOferta = 0;
    this.isdePromo = false;

    if (this.isdeOferta()) {
      this.precioOferta = this.deOferta.precio * this.cantiadadpedido;
      numeros.push(this.precioOferta);
      descripciones.set(this.precioOferta, ' Oferta');
    }
    if (this.misPromo.length > 0) {
      this.promoCiones = [];
      this.modificacionPrecio = 0;
      let cantidadRestantePromo = this.cantiadadpedido;

      this.misPromo.forEach((k) => {
        k.counter = 0;
        let cantidadpromo = k.cantidadenPromo();

        if (cantidadRestantePromo >= cantidadpromo) {
          let veces = cantidadRestantePromo / cantidadpromo;
          veces = Math.floor(veces);

          if (veces >= 1) {
            this.promoCiones.push(k.getDescripCion());
            descripciones.set(0, k.getDescripCion());
          }
          for (let index = 0; index < veces; index++) {
            k.counter++;
            cantidadRestantePromo -= cantidadpromo;
            this.aplicarPromo(cantidadpromo, k);
          }
        }
      });

      let promo = descripciones.get(0);
      descripciones.delete(0);
      descripciones.set(this.getPrecioCalculado(), promo);

      numeros.push(this.getPrecioCalculado());
    }

    if (this.cantidadmayorista != 0) {
      if (this.cantiadadpedido >= this.cantidadmayorista) {
        if (this.preciomayorista != 0) {
          let precioMayorista = this.preciomayorista * this.cantiadadpedido;
          descripciones.set(
            precioMayorista,
            ' Llevando ' +
              this.cantidadmayorista +
              ' o más pagas ' +
              this.preciomayorista +
              ' por c/d'
          );
          numeros.push(precioMayorista);
        } else {
          // this.precio=this.precioAyuda;
        }
      }
    }

    numeros.sort(this.sortFun);

    if (numeros.length > 0 && numeros[0] != this.getPrecio() * this.cantiadadpedido) {
      let amodif = this.getPrecio() * this.cantiadadpedido - numeros[0];
      this.modificacionPrecio = 0;
      this.modificacionPrecio += -amodif;

      this.promoCiones = [];
      descripciones.forEach((k, v) => {
        this.promoCiones.push(k);
      });
    }
  }

  sortFun = (a: number, b: number): number => {
    return a - b;
  };

  private aplicarPromo(cantidadpromo: number, k: PromocionesEccomerce) {
    this.isdePromo = true;
    this.modificacionPrecio += k.preconPromo(
      this.precio,
      cantidadpromo,
      this.modificacionPrecio
    );
  }
  getPromosActivas(): string[] {
    return this.promoCiones;
  }

  resetPrecios() {
    this.cantiadadpedido = 0;
    this.modificacionPrecio = 0;
  }

  getOtrosPrecios(): string {
    return this.etiquetamedida;
  }

  getPrecioPartido(): number {
    return this.preciopor;
  }

  setFoto(arg0: string) {
    this.foto = arg0;
  }

  getFotoImagen() {
    return this.foto;
  }

  getPrecio() {
    return this.precio;
  }

  getPromos() {
    return this.misPromo;
  }

  setPromo(k: PromocionesEccomerce) {
    //console.log('setpromo', k)
    if (k != undefined) {
      this.misPromo.push(k);
    }
  }

  getCantidad() {
    return this.cantiadadpedido;
  }

  getNombreMarca() {
    return this.nombremarca;
  }

  getMarcaReal() {
    return this.marcareal;
  }

  getDescripCion() {
    return this.descripcion;
  }

  getPresentacion() {
    return this.presentacion;
  }

  sumarCantPromo(arg0: number) {
    this.cantiadadpedido += arg0;
  }

  agregarCantidad(numero: number) {
    this.cantiadadpedido += numero;
  }

  setCantidad(arg0: number) {
    this.cantiadadpedido = arg0;
  }

  getPrecioNormal() {
    return this.precionormal;
  }

  getPrecioMayorista() {
    return this.preciomayorista;
  }

  getPrecioAmostrar() {
    return this.precio;
  }

  forzarAddPromo(v: number) {
    throw new Error('Method not implemented.');
  }

  getTipoPromo() {
    throw new Error('Method not implemented.');
  }

  getTipo() {
    throw new Error('Method not implemented.');
  }

  setCantidadInicial(v: number) {
    this.cantidadInicial = v;
  }

  getCantidadInicial() {
    return this.cantidadInicial;
  }

  getCantidadMayorista() {
    return this.cantidadmayorista;
  }

  getRemplazos() {
    return this.remplazos;
  }

  getRemplazo() {
    return this.remplazo;
  }

  setRemplazo(numero) {
    this.remplazo = numero;
  }

  setModificacionPrecio(arg0: number) {
    this.modificacionPrecio = arg0;
  }

  getDescripcionAdicional() {
    return this.da;
  }

  getCantidadMaxima() {
    return this.cmax;
  }

  getUnidadMedida() {
    return this.unidaddmedida;
  }

  getFraccion() {
    return this.fraccion;
  }

  getPrecioTotalFraccion() {
    return this.ppfraccion;
  }

  misPromo: PromocionesEccomerce[];
  cantidadInicial: number;
  promoCiones: string[];
  isdeoferta: boolean;
  isdePromo: boolean;
  deOferta: OfertasEcommerce;
  precioOferta: number;
  cantiadadpedido: number;
  counterPromOferta: number;

  private modificacionPrecio: number;
  private myBlob: Blob;
  private descargoimagen: boolean;
  private precioAyuda: number;
  private tags: TagsEcommerce[];
}
