import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MarianomGeneral } from '../../app-shared/marianom-general.service';
import { MenuMmaxInterface } from '../../app-shared/etc/mmax-menu.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { EcommerceGenericoService, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { ModalService } from 'projects/app-shared/src/public-api';
import { Ciudad } from 'projects/ecommerce-generico/src/lib/interfaces/ciudades-envío.interface';


@Component({
  selector: 'mariano-maxtopbar',
  templateUrl: './mariano-maxtopbar.component.html',
  styleUrls: ['./mariano-maxtopbar.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        //'border-bottom': 'solid 6px #FD0D1B',
      })),
      state('closed', style({
        'zoom': '95%',
      })),

      transition('open => closed', [
        animate('250ms ease-in-out')
      ]),
      transition('closed => open', [
        animate('500ms ease-in-out')
      ]),
    ]),
  ],
})

export class MarianoMaxtopbarComponent implements OnInit {
  @Input() productosTitle: string;
  @Input() logoPosition: string;

  scroll: boolean;
  usuario: Usuario;
  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private route: ActivatedRoute, private modal: ModalService) {
    this.scroll = false;
    this.genServ.getUsuarioProvider().subscribe(data => {
      this.usuario = data;
    });
    genServ.getTerminoDeIniciarCarro().subscribe({
      next:terminoDeCargar => {
          this.serv.inciarbusqueda('');
          this.genServ.getBusqueda('');
      }
    })
    // this.productosTitle = this.productosTitle || 'flex';
    // this.logoPosition = this.logoPosition || 'inherit';
  }

  ngOnInit(): void {
    if(this.genServ.ciudadesHabilitadasParaEnvio.length === 0){
      this.genServ.getCiudades().subscribe({
        next:respuestaCiudades =>{
          respuestaCiudades['registros'].forEach(
            ciudad =>{
              let nuevaCiudad : Ciudad = {
                ciudad: ciudad['ciudad'],
                nombre: ciudad['nombre'].trim(),
                cp: ciudad['cp'],
                costo: ciudad['costo'],
              }
              this.genServ.ciudadesHabilitadasParaEnvio.push(nuevaCiudad)
            }
          )
        }
      })
    }
  }

  get desplegado() {
    return this.serv.desplegado;
  }

  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  get logosrc() {
    // if (document.location.hash == "#/busqueda") {
    //   this.productosTitle = 'none';
    //   this.logoPosition = 'relative';
    // } else {
    //   this.productosTitle = 'flex';
    //   this.logoPosition = 'inherit';
    // }
    return this.genServ.getLogo();
  }

  get nombreSucursal() {
    if (!this.usuario) {
      return ' Sin Definir'
    }
    if (this.usuario.sucursal === 0) {
      return (" Suc " + this.genServ.getSucursalDefecto());
    }
    else {
      return this.usuario?.sucursalNombre;
    }
  }

  get menuActivoNombre(){
    return this.serv.getMenuActivoNombre()
  }

  cerrarModale(nombre) {
    this.modal.close(nombre);
  }

  menuclick(menuElegido: MenuMmaxInterface) {
    //console.log('menuclick',localStorage, localStorage.getItem('currentUser') === null || localStorage.getItem('currentUser') === undefined, this.genServ.getUserSync())
    if (localStorage.getItem('currentUser') === null || localStorage.getItem('currentUser') === undefined) {
      if (menuElegido.ruta == "historial" || menuElegido.ruta == "sucursales") {
        this.serv.abrirModalLogin();
      } else {
        this.serv.gotoURL("/" + menuElegido.ruta, this.route);
        this.serv.activatethis(menuElegido);
      }
    } else {
      this.serv.gotoURL("/" + menuElegido.ruta, this.route);
      this.serv.activatethis(menuElegido);
      if(menuElegido.nombre === 'Productos'){
        this.serv.inciarbusqueda('');
        this.genServ.getBusqueda('');
      }
    }

  }

  iraLand() {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.navigator.goLandPage(this.route);
    this.serv.setMenuActivoNombre('landing');
    this.serv.cerrarUI();
  }

  mostrarBotonCategoriasProducto(){
    return (this.menuActivoNombre === 'Productos' && window.innerWidth >599 )
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 40 && this.scroll == false) {
      this.scroll = true;
    }
    if (document.body.scrollTop < 40 && this.scroll == true) {
      this.scroll = false;
    }
  }
}
