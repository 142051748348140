import { Component, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'modal-open-point',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnInit {
  @Input() deshabilitarClickFueraDeContexto : boolean
  @Input() mode: string;
  @Input() id: string;
  constructor(private modalService: ModalService, private el: ElementRef, private render: Renderer2) {
    this.mode = this.mode || 'normal'
    this.openModal = false;
    if(this.deshabilitarClickFueraDeContexto === undefined){
      this.deshabilitarClickFueraDeContexto = false;
    }
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      return;
    }
    this.render.addClass(this.el.nativeElement, 'notvisible');
    if (this.mode === 'normal') {
      this.render.appendChild(document.body, this.el.nativeElement);
    }    
    this.modalService.add(this);
  }

  /* // close modal on background click
   @HostListener('click') onClick() {
       if (this.el.nativeElement.target.className === 'modal') {
           this.close();
       }
   }*/


  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.remove()
    //this.el.nativeElement.remove();
  }
  remove() {
    this.modalService.remove(this.id);
    var parentnode = this.render.parentNode(this.el.nativeElement);
    //console.log(parentnode);
    this.render.removeChild(parentnode, this.el.nativeElement);
  }
  // open modal
  open(): void {
    let bodyPage = document.querySelector('body');
    if (this.mode != "noBackground") {
      bodyPage.style.cssText = 'overflow: hidden;';
    }
    this.render.addClass(this.el.nativeElement, 'visible');
    this.openModal = true;
  }

  // close modal
  close(): void {
    let bodyPage = document.querySelector('body');
    bodyPage.style.cssText = '';
    this.render.removeClass(this.el.nativeElement, 'visible');
    this.openModal = false;
    //this.remove();
  }

  closeControl() {
    if(this.deshabilitarClickFueraDeContexto){
      return
    }
    if (this.id != 'modal-error' && this.id != 'spinner-modal') {
      this.close();
    }
  }

  /* @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    //console.log(event.key);
    if (event.key === "Escape") {
      if (this.id != 'modal-error' && this.id != 'spinner-modal') {
        this.close();
      }
    }
  } */
  get isOpen() {
    return this.openModal;
  }
  private openModal: boolean;

}
