import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'send-reset-pass',
  templateUrl: './send-reset-pass.component.html',
  styleUrls: ['./send-reset-pass.component.scss']
})

export class SendResetPassComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() loader: string;
  @Input() form: string;
  @Input() saltearPedidoDeMail : boolean; //Si es True se salta el pedido de mail y muestra pantalla de pedido de código, enviando el mail del usuario

  imagen: string;
  resetform: FormGroup;
  createForm: FormGroup;
  errorForm: string;
  constructor(private formBuilder: FormBuilder, private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private router: ActivatedRoute, private modal: ModalService) {
    this.imagen = '../../assets/misc/Illustración_CompraVIrutal.svg';
    this.modo = this.modo || 'normal';
    this.saltearPedidoDeMail = this.saltearPedidoDeMail !== undefined ? this.saltearPedidoDeMail : false;

  }

  ngOnInit(): void {
    this.router.data.subscribe({
      next:data => {
        this.modo = data['mode'] || this.modo || 'normal';
      },
    })
    new Image().src = this.imagen;
    this.resetform = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });
    this.loader = this.loader || 'none';
    this.form = this.form || 'grid';
    if(this.saltearPedidoDeMail){
      this.resetform.patchValue({email : this.genServ.getUser().email})
      // this.cambiarPass()
    }
  }

  get formFields() {
    return this.resetform.controls;
  }

  salirMobile() {
    this.serv.goLandPage();
    this.serv.navigator.goLandPage(this.router);
  }

  goToReset() {
    this.modal.close('send-reset-pass-modal-1');
    this.modal.open('reset-pass-modal-1');
  }

  async cambiarPass() {
    this.form = 'none';
    this.loader = 'flex';
    console.log(this.resetform, this.resetform.value.email)
    await this.genServ.pedirCambioPass(this.formFields.email.value).toPromise().then(() => {
      this.form = 'grid';
      this.loader = 'none';
      this.serv.email = this.formFields.email.value;
      this.modal.close('send-reset-pass-modal-1');
      this.modal.open('reset-pass-modal-1');
    }).catch(err => {
      this.genServ.pendingFalse();
      this.genServ.sendErrorMessage(err.error.mensaje);
      this.form = 'grid';
      this.loader = 'none';
      this.modal.open('modal-alert-pass');
      this.errorForm = err.error.mensaje;
      console.log(this.errorForm);
    });
  }
}
