import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ModalService } from 'projects/app-shared/src/public-api';
import { ArticulosGeneral, EcommerceGenericoService, HistorialPedidos, ItemsMMaxResumen } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'app-mmax-tracking-pedidos',
  templateUrl: './mmax-tracking-pedidos.component.html',
  styleUrls: ['./mmax-tracking-pedidos.component.scss']
})

export class MmaxTrackingPedidosComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() displayFactura: string;

  calendaretiro: string;
  horaretiro: string;
  ubicaSuc: string;
  iconreference: string;
  imagen: any;
  usaTurnos: string;
  horasMinimasTurno: string;
  retiroEnvio: string;
  direccionPedido: string;
  costoFlete : number;
  historia;
  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private router: ActivatedRoute, private modal: ModalService) {
    this.modo = this.modo || 'normal';
    this.iconreference = '../../assets/icons/checkpedidos.svg';
    this.calendaretiro = '../../assets/icons/date_range-24px-blue.svg';
    this.ubicaSuc = '../../assets/icons/location-pointer-blue.svg';
    this.horaretiro = '../../assets/icons/alarm-blue.svg';
    this.displayFactura = this.displayFactura || 'none';
  }

  async ngOnInit(): Promise<void> {
    this.router.data.subscribe({
      next:data => {
        console.log('router data',data)
        this.modo = data['mode'] || this.modo || 'normal';
      },
    });
    window.addEventListener("beforeunload", function (e) {
      return window.location.hash = "#/";
    });
    if (this.genServ.getDetalleElegido() as string == "[object Object]") {
      this.historia = this.genServ.getDetalleElegido()
    } else {
      this.historia = JSON.parse(this.genServ.getDetalleElegido() as string);
    }
/*     console.log('historia', this.historia)
    let montoHistoria = 0;
    this.historia.items.forEach(
      articuloSimplificado => {
        const articulo : ItemsMMaxResumen = articuloSimplificado
        let montoTotalArticulo = articulo.monto * articulo.cantidad
        if(articulo.fraccion){
          montoTotalArticulo = montoTotalArticulo * articulo.fraccion;
        }
        montoHistoria += montoTotalArticulo;
      }
    );

    console.log('historia monto total', montoHistoria);
    this.historia.monto = montoHistoria; */

    this.genServ.setImagenModal(this.historia.factura);
    await this.genServ.getImagenPeticion().toPromise().then(
      (data) => {
        let blob = new Blob([data['body']], { type: "application/pdf" });
        this.imagen = blob;
      }
    )
    if (this.historia.estado == 'Cerrado') {
      this.displayFactura = 'none';
    } else {
      this.displayFactura = 'flex'
    }
    this.genServ.returnUsaTurnos().subscribe({
      next:data => {
        this.usaTurnos = data;
      },
    });
    this.genServ.returnHorasMinimasTurno().subscribe({
      next:data => {
        this.horasMinimasTurno = data;
      }
    });
    if (this.historia.turnos[0].modo == 0) {
      this.retiroEnvio = "Retirar";
      this.direccionPedido = this.sucursal
    } else if (this.historia.turnos[0].modo == 1) {
      this.retiroEnvio = "Envio";
      this.direccionPedido = this.historia.turnos[0].ciudad + ', ' + this.historia.turnos[0].barrio + ', ' + this.historia.turnos[0].calle;
    }
    else
    {
      this.retiroEnvio = "Envio Express";
      this.direccionPedido = this.historia.turnos[0].ciudad + ', ' + this.historia.turnos[0].barrio + ', ' + this.historia.turnos[0].calle;
    }

  }

  get articulos(): ItemsMMaxResumen[] {
    let articulosDePedido : ItemsMMaxResumen[] = this.historia.myitemsdetalle;
    let articulosSinFlete : ItemsMMaxResumen[] = articulosDePedido.filter(
      articulo => {
        if(articulo.marca !== parseInt(this.genServ.marcaFlete) || articulo.codigo !== parseInt(this.genServ.codigoFlete)){
          return true
        }
        else {
          //console.log('flete', articulo)
          this.costoFlete = articulo.precio;
          return false;
        }
      }
    )
    //console.log('articulos sin flete',articulosSinFlete)
    return articulosSinFlete;
  }

  get turno() {
    let miturno = this.historia.turnos[0];
    miturno.mfecha = moment(miturno.fecha);
    miturno.mfecha.locale('es');
    miturno.dia = miturno.mfecha.format("dddd DD[/]MM");

    let hora: string = miturno.horainicio.toString();
    miturno.hora = '';
    if (hora.length == 3) {

      hora = '0' + hora;
    }
    for (let index = 0; index < 4; index++) {

      miturno.hora += hora.charAt(index) || '';
      if (index == 1) {
        miturno.hora += ':';
      }
    }
    return miturno;
  }

  get sucursal() {
    let sucursal = this.historia.turnos[0]['sucursal'];
    if (sucursal) {
      return this.genServ.getSucursalPorNumero(sucursal);
    }
    return '';
  }

  verFactura() {
    var _url = window.URL.createObjectURL(this.imagen);
    //console.log('articulos sin flete', this.articulos)
    window.open(_url, "_blank");
  }

  backToHistorial() {
    this.serv.gotoURL('historial', this.router);
  }
}
