import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'modal-work-withAs',
  templateUrl: './modal-work-withAs.component.html',
  styleUrls: ['./modal-work-withAs.component.scss']
})

export class ModalWorkWithAsComponent implements OnInit {

  constructor(private modal: ModalService, private genServ : EcommerceGenericoService) { }

  ngOnInit(): void {
  }

  aceptar() {
    this.modal.close('modal-work-withAs');
  }

  get workWithA(){
    return this.genServ.getworkWithA()
  }

  get errorMessage() {
    return this.genServ.getErrorMessage();
  }

}
