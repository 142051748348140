<div *ngIf="modo==='normal'">
  <div class="conteiner">
    <div class="endbox">
      <div class="contenidoend">
        <div class="titule">
          Su compra fue realizada con éxito
        </div>
        <div class="mintitle">
          En tu celular y en tu mail recibirás una notificación y un recordatorio para que retires tu compra.
        </div>
        <div class="imgael">
          <img class="womencart" [src]="womencart">
        </div>
        <div class="titule">
          Gracias!
        </div>
        <div class="botns">
          <button (click)="volverAchan()" onclick="window.scrollTo(0,0)" class="buttonpc blubutton noselect">
            Volver a Inicio
          </button>
        </div>

      </div>

    </div>

  </div>
  <mmax-helpfootbar [mode]="'flotante'"></mmax-helpfootbar>
</div>
