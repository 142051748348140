import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'modal-pass-error',
  templateUrl: './modal-pass-error.component.html',
  styleUrls: ['./modal-pass-error.component.scss']
})

export class ModalPassErrorComponent implements OnInit {

  constructor(private modal: ModalService) { }

  ngOnInit(): void {
  }

  aceptar() {
    this.modal.close('modal-pass-error');
  }

}
