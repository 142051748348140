import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { GenericoSlideAnim } from '../etc/generico-animation'
import { MarianomGeneral } from '../marianom-general.service';
import { MenuMmaxInterface } from '../../app-shared/etc/mmax-menu.interface';
import { ArticulosGeneral, EcommerceGenericoModule, EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'carrito-generico',
  templateUrl: './carrito-generico.component.html',
  styleUrls: ['./carrito-generico.component.scss'],
  animations: [GenericoSlideAnim],
})

export class CarritoGenericoComponent implements OnInit {
  @Input('mode') modo: string;

  articulosconcantidad : ArticulosGeneral[] = [];
  total : number = 0;
  totales: number = 0;
  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService , private route: ActivatedRoute, private modal: ModalService) {
    this.modo = this.modo || 'normal';
    genServ.getTerminoDeIniciarCarro().subscribe({
      next: terminoDeCargar =>{
        this.articulosconcantidad = this.genServ.getCarrito().slice();
        this.total = this.genServ.getCantidadTotal()
        this.totales = this.genServ.getTotal()
      }
    })
    genServ.getObservableSeAgregaronItemsAlCarrito().subscribe({
      next: nuevosArticulos =>{
        this.articulosconcantidad = this.genServ.getCarrito().slice();
        this.total = this.genServ.getCantidadTotal()
        this.totales = this.genServ.getTotal()
      }
    })
  }

  ngOnInit(): void {
    this.articulosconcantidad = this.genServ.getCarrito().slice();
    this.total = this.genServ.getCantidadTotal()
    this.totales = this.genServ.getTotal()
   }

  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  get desplegado(): boolean {
    return this.serv.desplegado;
  }

  get sincronizandoCarritoConBack(){
    return this.genServ.getsincronizandoCarritoConBack()
  }

  preguntarVaciar() {
    if (this.total === 0) {
      this.modal.open('modal-agregar-producto');
    }
    else {
      this.modal.open('vaciar-carro-modal-1');
    }
  }

  preguntarVaciarMobile() {
    this.modal.open('vaciar-carro-modal-2');
  }

  irAresumenPC() {
    console.log('iraresumenpc',this.menus)
    if (this.total != 0) {
      for (let i = 0; i < this.menus.length; i++) {
        const element = this.menus[i];
        element.activo = false;
      }
      this.abriryCerrar();
      this.serv.navigator.irResumenCompra(this.route)
      this.serv.setMenuActivoNombre('carro');

    }
    else {
      this.modal.open('modal-agregar-producto');
    }

  }

  irAresumenMobile() {
    this.serv.navigator.irResumenCompra(this.route)
  }

  volverAtrasMobile() {
    this.serv.navigator.goLandPage(this.route);
  }

  abriryCerrar() {
    this.serv.abrircerrarCart(!this.desplegado);
  }

  volverAlMenu() {
    this.serv.goLandPage();
    this.serv.navigator.goLandPage(this.route);
  }

  cerrarModale(nombre) {
    this.modal.close(nombre);
  }


}
