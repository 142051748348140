import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { ArticulosGeneral, CategoriasWebMenu, EcommerceGenericoService, HistorialPedidos, PedidosMmaxEccomerce, PromocionesEccomerce, ResultadosDeBusquedaInterface, SucursalEcommerce, TurnosRetiroEccomerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { Observable } from 'rxjs';
import { MenuMmaxInterface } from './etc/mmax-menu.interface'
import { MyNavigator } from './etc/navegador-class';
import { PCNavigator } from './etc/pcmode-class';
import { CheckboxSubcategoria } from './etc/categoria-checkbox.interface';
import { TagsEcommerce } from 'projects/ecommerce-generico/src/lib/interfaces/tags-eccomerce.interface';
import { DatosEnvio } from 'projects/ecommerce-generico/src/lib/interfaces/envios.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MarianomGeneral {
  private turnoselecionado: TurnosRetiroEccomerce;
  desplegado: boolean;
  rutas: MenuMmaxInterface[];
  desplegadocat: boolean;
  desplegadousa: boolean;
  desplegadosearch: boolean;
  articuloEnPopUp: ArticulosGeneral;
  navigator: MyNavigator;
  email: any;
  whitelist: Map<string, boolean>;
  slides: any;
  selectorName: string;
  busqueda: string;
  refreshProducts: boolean;
  retiroEnvio: number;
  automaticSlide: any;
  ocultarSideBarBusqueda: boolean = true;
  ocultarMenuProductos: boolean = false;
  ocultarMenuOfertas: boolean = false;
  ocultarMenuMarcas: boolean = false;
  ocultarMenuCompras: boolean = false;
  ocultarMenuSucursales: boolean = false;
  ocultarMenuAyuda: boolean = false;
  private menuActivoNombre: string = '';

  constructor(private serv: EcommerceGenericoService, private router: Router, private modal: ModalService, private httpClient: HttpClient) {
    this.desplegado = false;
    this.desplegadousa = false;
    this.whitelist = new Map();

    setTimeout(() => {
      this.resizeEvent();
    }, 1000);
  }

  initMaster() {
    this.whitelist.set('cerrarpedidoweb', true);
    this.whitelist.set('ordenpagoweb', true);
    this.whitelist.set('planesmediosweb', true);
    // this.whitelist.set('pedidoweb', true);
    //this.whitelist.set('itempedidoweb',false);
    //this.whitelist.set('renew',false);
    this.serv.initmaster.toPromise().then((respuesta) => {
      console.log("PASO", this.serv.ocultarMenuAyuda);

      this.ocultarMenuProductos = this.serv.ocultarMenuProductos;
      this.ocultarMenuOfertas = this.serv.ocultarMenuOfertas;
      this.ocultarMenuMarcas = this.serv.ocultarMenuMarcas;
      this.ocultarMenuCompras = this.serv.ocultarMenuCompras;
      this.ocultarMenuSucursales = this.serv.ocultarMenuSucursales;
      this.ocultarMenuAyuda = this.serv.ocultarMenuAyuda;
      this.rutas = [
        {
          nombre: 'Productos',
          ruta: 'busqueda',
          activo: false,
          oculto: this.ocultarMenuProductos
        },
        {
          nombre: 'Ofertas y Promociones',
          ruta: 'ofertas',
          activo: false,
          oculto: this.ocultarMenuOfertas
        },
        {
          nombre: 'Mis Compras',
          ruta: 'historial',
          activo: false,
          oculto: this.ocultarMenuCompras
        },
        {
          nombre: 'Marcas Destacadas',
          ruta: 'marcas',
          activo: false,
          oculto: this.ocultarMenuMarcas
        },
        {
          nombre: 'Sucursales',
          ruta: 'sucursales',
          activo: false,
          oculto: this.ocultarMenuSucursales
        },
        {
          nombre: 'Ayuda',
          ruta: 'ayudas',
          activo: false,
          oculto: this.ocultarMenuAyuda
        },
      ];

      // Después de inicializar las rutas, determinar el menú activo
      this.rutas.forEach(ruta => {
        if (ruta.ruta === this.router.url.substring(1)) {
          // ruta.activo = true;
          this.menuActivoNombre = ruta.nombre;
          console.log(ruta);
        }
      });

      return respuesta;
    }).catch((error) => {
      console.error("Promise rejected with error:", error);
    });
  }

  llamarSpinner() {
    this.modal.open('spinner-modal');
  }

  cerrarSpinner() {
    this.modal.close('spinner-modal');
  }

  private resizeEvent() {
    this.navigator = new PCNavigator(this.router, this);
  }

  opArtConModal() {
    if (this.serv.getCantidadTotal() == 0) {
      this.modal.open('articulo-pc-mmax-modal');

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      setTimeout(() => {
        this.modal.close('articulo-pc-mmax-modal');
      }, 3000);
    }
  }

  isloged() {
    if (this.serv.getUser()) {
      return true;
    }
    else {
      return false;
    }
  }

  abrirModalLogin() {
    this.modal.open('login-modal-1');
  }

  loginok() {
    this.modal.closeAll();
  }

  forceError(arg0: string) {
    this.router.navigate[arg0];
  }

  salirylimpiar(route: ActivatedRoute) {
    this.serv.limpiarysalir();
    this.router.navigateByUrl('/app', { relativeTo: route });
  }

  marcarCarroConRemplazo(event: CheckboxSubcategoria) {
    this.serv.getCarrito().forEach(k => {
      k.setRemplazo(Number.parseInt(event.id));
      k.getRemplazos().forEach(k => {
        if (k.modo === event.id) {
          k.activo = true;
        }
        else {
          k.activo = false;
        }
      });
    })
  }

  marcarFaltantesConRemplazo(event: CheckboxSubcategoria) {
    //console.log('this.serv.getCarrito()', this.serv.getCarrito(), event)
    this.serv.getCarrito().forEach(
      articuloCarro => {
        if (articuloCarro.getRemplazo() == -1) {
          articuloCarro.setRemplazo(Number.parseInt(event.id));
          articuloCarro.getRemplazos().forEach(
            remplazo => {
              if (remplazo.modo === event.id) {
                remplazo.activo = true;
              }
              else {
                remplazo.activo = false;
              }
            }
          );
        }
      }
    );
  }

  gotoURL(url: string, route: ActivatedRoute) {
    this.router.navigateByUrl(url, { relativeTo: route });
  }

  goLandPage() {
    this.rutas.forEach(k => {
      k.activo = false;
    });
  }

  abrirTurnos() {
    this.modal.open('turnos-mmax-modal-1')
  }

  setSelectorName(nombre) {
    this.selectorName = nombre;
  }

  returnSelectorName() {
    return this.selectorName;
  }


  activatethis(menuElegido: MenuMmaxInterface) {
    this.rutas.forEach(menu => {
      if (menu.nombre === menuElegido.nombre) {
        menu.activo = true;
        this.menuActivoNombre = menuElegido.nombre
        this.cerrarUI();
      }
      else {
        menu.activo = false;
      }
    });
  }

  articuloAmostrar(articulo: ArticulosGeneral) {
    this.articuloEnPopUp = articulo;
  }

  getArticuloEnmodal() {
    return this.articuloEnPopUp;
  }

  setRetiroEnvio(modo: number) {
    this.retiroEnvio = modo;
  }

  returnRetiroEnvio() {
    return this.retiroEnvio;
  }

  prayandpaid() {
    let usaTurno = true;
    this.serv.returnUsaTurnos().subscribe(data => {
      if (data == 'N') {
        usaTurno = false;
      }
    });

    let datosEnvio = this.serv.getDatosEnvio();
    if (usaTurno) {
      return this.serv.cerrarPedido(this.turnoselecionado.dia, this.turnoselecionado.horainicio, this.turnoselecionado.fecha, this.turnoselecionado.modo, datosEnvio?.ciudad, datosEnvio?.barrio, datosEnvio?.calle, datosEnvio?.numero)
    } else {
      console.log("Sistema configurado para no usar turnos");
      return this.serv.cerrarPedido(1, 900, '2023-05-20T00:00:00-03:00', this.returnRetiroEnvio(), datosEnvio?.ciudad, datosEnvio?.barrio, datosEnvio?.calle, datosEnvio?.numero)
    }
  }

  inciarbusqueda(event: string) {
    this.serv.iniciarbusqueda(event);
    this.busqueda = event;
    this.refreshProducts = false;
  }

  returnBusqueda() {
    return this.busqueda;
  }

  goToProducts(bool) {
    this.refreshProducts = bool;
  }

  returnRefreshProducts() {
    return this.refreshProducts;
  }

  setTurno(turno: TurnosRetiroEccomerce) {
    this.turnoselecionado = turno;
  }

  ultimoTurno() {
    return this.turnoselecionado;
  }

  cerrarUI() {
    this.desplegadocat = false;
    this.desplegado = false;
    this.desplegadousa = false;
    this.desplegadosearch = false;
  }

  desplegaryCerrar(arg0: boolean) {
    this.desplegadocat = arg0;
    this.desplegado = false;
    this.desplegadousa = false;
    this.desplegadosearch = false;
  }

  abrircerrarCart(arg0: boolean) {
    this.desplegadocat = false;
    this.desplegado = arg0;
    this.desplegadousa = false;
    this.desplegadosearch = false;
  }

  desplegaryCerrarusu(arg0: boolean) {
    this.desplegadocat = false;
    this.desplegado = false;
    this.desplegadousa = arg0;
    this.desplegadosearch = false;
  }

  abriryCerrrarSearch(arg0: boolean) {
    this.desplegadocat = false;
    this.desplegado = false;
    this.desplegadousa = false;
    this.desplegadosearch = arg0;
  }

  toggleSideBarBusqueda() {
    this.ocultarSideBarBusqueda = !this.ocultarSideBarBusqueda;
  }

  getOcultarSideBarBusqueda() {
    return this.ocultarSideBarBusqueda
  }

  getMenuActivoNombre() {
    return this.menuActivoNombre;
  }

  setMenuActivoNombre(menuActivoNombre: string) {
    this.menuActivoNombre = menuActivoNombre;
  }
}
