import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'modal-agregar-producto',
  templateUrl: './modal-agregar-producto.component.html',
  styleUrls: ['./modal-agregar-producto.component.scss']
})
export class ModalAgregarProductoComponent implements OnInit {

  constructor(private modal: ModalService) { }

  ngOnInit(): void {
  }

  aceptar() {
    this.modal.closeAll();
  }

}
