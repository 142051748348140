<div class="ayudaSection" *ngIf="modo==='normal'">
  <section>
    <div class="menus noselect">
      <div class="menu" *ngFor="let menu of menus" [@openClose]="menu.activa  ? 'open' : 'closed'"
        (click)="selecionarmenu(menu)" onclick="window.scrollTo(0,0)"> {{menu.nombre}} </div>
    </div>
  </section>
  <div *ngIf="menuselex.nombre==='Preguntas frecuentes'">
    <section>
      <div class="ayudas">
        <div class="subayudacat noselect">
          Compras
        </div>

        <div class="unacateg noselect">
          <div class="texdeunacat">
            Como hago una compra online? Ver tutorial
          </div>
        </div>

        <div class="unacateg noselect">
          <div class="texdeunacat">
            Dudas
          </div>
        </div>

        <div class="subayudacat noselect">
          Retiro
        </div>

        <div class="unacateg noselect">
          <div class="texdeunacat">
            Retiro por sucursal
          </div>
        </div>

        <div class="unacateg noselect">
          <div class="texdeunacat">
            Dudas
          </div>
        </div>
      </div>
    </section>
  </div>

  <div *ngIf="menuselex.nombre==='Contacto'">
    <section>
      <div class="formsup">
        <div class="titulosop">
          Asunto
        </div>
        <input type="text" class="inputSup" placeholder="Tengo problemas con mi compra">
        <div class="titulosop">
          Mail
        </div>
        <input type="text" class="inputSup" placeholder="cliente@gmail.com">
        <div class="titulosop">
          Mensaje
        </div>
        <div class="intext">
          <textarea name="msj" placeholder="Escribe aquí tu mensaje…" class="textgrand" rows="20"></textarea>
        </div>
        <div class="botonera noselect">
          <div class="botton" (click)="enviarPregunta()">
            Enviar
          </div>
        </div>
      </div>
    </section>
  </div>
  <mmax-helpfootbar></mmax-helpfootbar>

  <foo-mariano-max></foo-mariano-max>
  <!-- <div class="explorer">
        <mmax-helpfootbar [mode]="'flotante'"></mmax-helpfootbar>
    </div> -->
</div>
