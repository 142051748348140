import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'modal-monto-minimo',
  templateUrl: './modal-monto-minimo.component.html',
  styleUrls: ['./modal-monto-minimo.component.scss']
})

export class ModalMontoMinimoComponent implements OnInit {

  constructor(private genServ: EcommerceGenericoService, private modal: ModalService) { }

  ngOnInit(): void {
  }

  aceptar() {
    this.modal.close("modal-monto-minimo");
  }

  get montoMinimo() {
    return this.genServ.getMontoMinimo();
  }

}
