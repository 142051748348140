import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'modal-no-implemented',
  templateUrl: './modal-no-implemented.component.html',
  styleUrls: ['./modal-no-implemented.component.scss']
})

export class ModalNoImplementedComponent implements OnInit {

  constructor(private modal: ModalService) { }

  ngOnInit(): void {
  }

  aceptar(){
    this.modal.closeAll();
  }

}
