<div *ngIf="modo==='normal'">
  <section>
    <div class="container">
      <div class="titulo">
        Cuenta
      </div>
      <div class="fields">
        <div class="field">
          <div class="campo texto">
            Mail
          </div>
          <div class="valor texto">
            {{user.email}}
          </div>
        </div>
        <div class="field">
          <div class="campo texto">
            Contraseña
          </div>
          <input [disabled]="true" type="password" value="contraseña" class="valor texto fieldpassword">
        </div>
        <div class="botonesYAvisos" >
          <div class="acciones">
            <button class="btnContinuar"  (click)="resetPass()">
              Modificar Contraseña
            </button>
          </div>
        </div>
      </div>


      <div class="titulo">
        Datos Personales
      </div>
<!--       <div class="fields">

        <div class="field">
          <div class="campo texto">
            Nombre Completo
          </div>
          <div class="valor texto">
            {{user.nombre}}
          </div>
        </div>
        <div class="field">
          <div class="campo texto">
            DNI
          </div>
          <div class="valor texto">
            {{user.dni}}
          </div>
        </div>
        <div class="field">
          <div class="campo texto">
            Dirección
          </div>
          <div class="valor texto">
            {{user.direccion}}
          </div>
        </div>
        <div class="field">
          <div class="campo texto">
            Código Postal
          </div>
          <div class="valor texto">
            {{''}}
          </div>
        </div>
        <div class="field">
          <div class="campo texto">
            Localidad
          </div>
          <div class="valor texto">
            {{user.ciudad}}
          </div>
        </div>
      </div> -->
      <form [formGroup]="formularioDatosUsuario" (ngSubmit)="guardarCambios()" class="fields">
        <div class="field">
          <label for="nombre" class="campo texto">
            Nombre Completo
          </label>
          <div>
            <input type="text" [ngClass]="{inputHabilitado: habilitarCambiarDatos}" class="valor texto" id="nombre" name="nombre" formControlName="nombre" >
            <div *ngIf="!nombre?.valid && (nombre?.dirty ||nombre?.touched)">
              <div *ngIf="!nombre.hasError('maxLength')" style="color:red">
                El nombre debe ser menor a 20 caracteres
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <label for="dni" class="campo texto">
            DNI
          </label>
          <div>
            <input type="number" [ngClass]="{inputHabilitado: habilitarCambiarDatos}" class="valor texto" id="dni" name="dni" formControlName="dni">
            <div *ngIf="!dni?.valid && (dni?.dirty ||dni?.touched)">
              <div *ngIf="dni.value < 9999" style="color:red">
                Ingrese un DNI válido
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <label for="direccion" class="campo texto">
            Dirección
          </label>
          <div>
            <input type="text" [ngClass]="{inputHabilitado: habilitarCambiarDatos}" class="valor texto" id="direccion" name="direccion" formControlName="direccion" >

            <div *ngIf="!direccion?.valid && (direccion?.dirty ||direccion?.touched)">
              <div *ngIf="!direccion.hasError('maxLength')" style="color:red">
                La dirección debe ser menor a 100 caracteres
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="field">
          <label for="codigopostal" class="campo texto">
            Código Postal
          </label>
          <input type="number" [ngClass]="{inputHabilitado: habilitarCambiarDatos}" class="valor texto" id="codigopostal" name="codigopostal" formControlName="codigopostal"  >
        </div> -->
        <div class="field">
          <label for="telefonos" class="campo texto">
            Teléfonos
          </label>
          <input type="text" [ngClass]="{inputHabilitado: habilitarCambiarDatos}" class="valor texto" id="telefonos" name="telefonos" formControlName="telefonos"  >
        </div>
        <div class="field">
          <label for="ciudad"  class="campo texto">
            Ciudad Preferida de Envío
          </label>
          <!-- <input type="text" [ngClass]="{inputHabilitado: habilitarCambiarDatos}" class="valor texto" id="ciudad" name="ciudad" formControlName="ciudad"> -->
          <div *ngIf="!tieneCiudadElegida && !habilitarCambiarDatos">
            Modifique sus datos para agregar una ciudad por defecto para los envíos
          </div>
          <select *ngIf="tieneCiudadElegida || habilitarCambiarDatos" [ngClass]="{inputHabilitado: habilitarCambiarDatos}"  class="valor texto" id="ciudad" name="ciudad" formControlName="ciudad">
            <ng-container *ngFor="let ciudad of ciudadesHabilitadas">
              <option [value]="ciudad.ciudad">
                {{ciudad.nombre}}
              </option>
            </ng-container>

          </select>
        </div>
        <div class="botonesYAvisos">
          <div style="height: 3rem;">
            <spinner *ngIf="guardando" ></spinner>
            <div *ngIf="guardadoExitoso" class="guardadoExitoso">
              ¡Se guardaron los cambios exitosamente!
            </div>
            <div *ngIf="guardadoProblema" class="guardadoProblema">
              Hubo un problema al intentar guardar los cambios, por favor intente nuevamente
            </div>
          </div>
          <div class="acciones">
            <button *ngIf="!habilitarCambiarDatos" class="btnContinuar"  (click)="modificarDatos()">
              Modificar Datos
            </button>
            <button *ngIf="habilitarCambiarDatos" class="btnCancelar"  (click)="cancelarCambios()" [disabled]="guardando" >
              Cancelar Cambios
            </button>
            <button type="submit" *ngIf="habilitarCambiarDatos" class="btnContinuar" [disabled]="!formularioDatosUsuario.valid || dni.value < 9999 || guardando" >
              Guardar Cambios
            </button>
          </div>
        </div>
      </form>
    </div>
    </section>
    <section>
    </section>
  <!-- <mmax-helpfootbar [mode]="'flotante'"></mmax-helpfootbar> -->
  <modal-open-point id="send-reset-pass-saltearPedidoDeMail">
    <div normal class="mybody">
      <send-reset-pass [saltearPedidoDeMail]="true" ></send-reset-pass>
      <div class="cerrarR" (click)="cerrarModale('send-reset-pass-modal-1')">X</div>
    </div>
  </modal-open-point>

  <mmax-helpfootbar></mmax-helpfootbar>
  <foo-mariano-max></foo-mariano-max>
</div>
