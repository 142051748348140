import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateUserMmaxComponent } from '../app-shared/forms/create-user-mmax/create-user-mmax.component';
import { LoginFormMmaxComponent } from '../app-shared/forms/login-form-mmax/login-form-mmax.component';
import { ResetPassMmaxComponent } from '../app-shared/forms/reset-pass-mmax/reset-pass-mmax.component';
import { SucursalesmmaxComponent } from '../app-shared/forms/sucursalesmmax/sucursalesmmax.component';
import { MmaxofertasviewComponent } from '../app-shared/mmax-ofertasview/mmax-ofertasview.component'
import { FrecuentesMmaxviewComponent } from './frecuentes-mmaxview/frecuentes-mmaxview.component';
import { HelpBarMmaxComponent } from './help-bar-mmax/help-bar-mmax.component'
import { HistorialMMaxComprasComponent } from '../app-shared/historial-mmax-compras/historial-mmax-compras.component';
import { MisdatosmmaxComponent } from '../app-shared/misdatosmmax/misdatosmmax.component';
import { MmaxGeneralComponent } from './mmax-general/mmax-general.component';
import { MmaxhomeLandpageComponent } from './mmaxhome-landpage/mmaxhome-landpage.component'
import { AyudayPregummaxComponent } from '../app-shared/ayuday-pregummax/ayuday-pregummax.component'
import { MmaxCatalogoComponent } from '../app-shared/mmax-catalogo/mmax-catalogo.component';
import { MmaxTrackingPedidosComponent } from '../app-shared/mmax-tracking-pedidos/mmax-tracking-pedidos.component';
import { MmaxCheckoutCompraComponent } from '../app-shared/mmax-checkout-compra/mmax-checkout-compra.component';
import { MarcasDestmmaxComponent } from '../app-shared/marcas-destmmax/marcas-destmmax.component';
import { MmaxSearchResultsComponent } from '../app-shared/mmax-search-results/mmax-search-results.component';
import { ResumenComprammaxComponent } from '../app-shared/resumen-comprammax/resumen-comprammax.component';
import { MmaxCierreCarroComponent } from '../app-shared/mmax-cierre-carro/mmax-cierre-carro.component';
import { MmaxEndLineComponent } from '../app-shared/mmax-end-line/mmax-end-line.component';
import { NotLoginMmaxGuard } from '../app-shared/etc/not-login-mmax.guard'
import { MmaxEndLineCpComponent } from '../app-shared/mmax-end-line-cp/mmax-end-line-cp.component';
import { BeneficiosMmaxComponent } from '../app-shared/beneficios-mmax/beneficios-mmax.component';

const routes: Routes = [
  {
    path: '', component: MmaxGeneralComponent, children: [
      { path: 'resumendecompra', component: ResumenComprammaxComponent },
      { path: 'marcas', component: MarcasDestmmaxComponent },
      { path: 'sucursales', component: SucursalesmmaxComponent },
      { path: 'ayudas', component: AyudayPregummaxComponent },
      // { path: 'favoritos', component: FrecuentesMmaxviewComponent, canActivate: [NotLoginMmaxGuard] },
      { path: 'historial', component: HistorialMMaxComprasComponent, canActivate: [NotLoginMmaxGuard] },
      { path: 'ofertas', component: MmaxofertasviewComponent },
      { path: 'misdatos', component: MisdatosmmaxComponent, canActivate: [NotLoginMmaxGuard] },
      { path: 'catalogo', component: MmaxCatalogoComponent },
      { path: 'beneficios', component: BeneficiosMmaxComponent, canActivate: [NotLoginMmaxGuard] },
      { path: 'busqueda', component: MmaxSearchResultsComponent },
      { path: 'detallepedido', component: MmaxTrackingPedidosComponent, canActivate: [NotLoginMmaxGuard] },
      { path: '', component: MmaxhomeLandpageComponent }
    ]
  },
  {
    path: 'ayuda', component: HelpBarMmaxComponent, children: [
      { path: 'crearcuenta', component: CreateUserMmaxComponent },
      { path: 'resetpassword', component: ResetPassMmaxComponent },
      { path: 'login', component: LoginFormMmaxComponent },
      { path: '', component: AyudayPregummaxComponent }
    ]
  },
  {
    path: 'checkout', component: HelpBarMmaxComponent, children: [
      { path: '', component: MmaxCheckoutCompraComponent, canActivate: [NotLoginMmaxGuard] },
      { path: 'finalizar', component: MmaxCierreCarroComponent, canActivate: [NotLoginMmaxGuard] },
      { path: 'finalizado', component: MmaxEndLineComponent },
      { path: 'finalizadocp', component: MmaxEndLineCpComponent },
      { path: 'ayuda', component: AyudayPregummaxComponent },
    ]
  },
  { path: '**', redirectTo: '' }

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class MarianoRoutingModule { }