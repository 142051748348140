import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'beneficios-mmax',
  templateUrl: './beneficios-mmax.component.html',
  styleUrls: ['./beneficios-mmax.component.scss']
})

export class BeneficiosMmaxComponent implements OnInit {
  @Input('mode') modo: string;
  constructor(private router: ActivatedRoute, private serv: MarianomGeneral, private genServ : EcommerceGenericoService) {
    this.cargando = true;
    this.error = false;
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });

    this.genServ.getMovimientosMmax().subscribe(data => {
      this.movimientos = data;
      this.saldoActual = this.movimientos[0].saldo_real;

      if (this.movimientos.length > 10) {
        this.movimientos = this.movimientos.slice(0, 10)
      }

      for (let i = 0; i < this.movimientos.length; i++) {
        const element = this.movimientos[i];

        this.movimientos[i]['id'] = i;

        let fecha = element.fecha_y_ho.toString();
        fecha = fecha.split('T');
        let hora = fecha[1];
        fecha = fecha[0];
        fecha = fecha.split('-');
        fecha = fecha[2] + '-' + fecha[1] + '-' + fecha[0];
        hora = hora.split('-');
        hora = hora[0];

        let date = element.vencimient.toString();
        date = date.split('-');
        date = date[2] + '-' + date[1] + '-' + date[0];
        date = date + ' ' + hora;
        this.movimientos[i].vencimient = date;
      }

      this.cargando = false;
    }, error => {
      this.error = true;
      this.genServ.pendingFalse();
    })

  }

  cargando: boolean;
  movimientos: any;
  saldoActual: number;
  error: boolean;
}
