<div normal>
  <modal-default-mmaxapp>
    <div class="tarjetaconfirmar">
      <div class="titulo">
        Cerrar pedido
      </div>
      <div class="leyendaconfirmar">
        <div [innerHTML]="textoCerrarPedido"></div>
      </div>
      <div class="botonesCierreCarrito">
        <button class="btnCierreCarrito whibutton" (click)="cancelar()">
          Cancelar
        </button>
        <button class="btnCierreCarrito redbutton" (click)="cerrarPedido()">
          Aceptar
        </button>
      </div>
    </div>

  </modal-default-mmaxapp>
</div>
