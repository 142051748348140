import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { EcommerceGenericoService, SucursalEcommerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { CheckboxCategorias, CheckboxSubcategoria } from '../../etc/categoria-checkbox.interface';
import { MenuMmaxInterface } from '../../etc/mmax-menu.interface';
import { MarianomGeneral } from '../../marianom-general.service';

@Component({
  selector: 'sucursales-mmax',
  templateUrl: './sucursalesmmax.component.html',
  styleUrls: ['./sucursalesmmax.component.scss']
})

export class SucursalesmmaxComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() loader: string;

  oneSuc: boolean = false;
  sucursales: CheckboxCategorias;
  sucursalesPropias: SucursalEcommerce[];
  sucursalSelecionada: SucursalEcommerce;
  urlmap: string;
  usuario: Usuario;
  nombreSucursal: string;
  sucZero: boolean = false;
  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private router: ActivatedRoute, private modal: ModalService) {
    this.modo = this.modo || 'normal';
    this.nombreSucursal = "Sin Definir";
    this.loader = this.loader || 'none';
    this.genServ.getSucursalesProviders().subscribe({
      next: sucursales => {
        if(sucursales.length === 0){
          return
        }
        this.oneSuc = sucursales.length == 1;
        this.sucursalesPropias = sucursales;
        //console.log('getsucursalesproviders', this.sucursalesPropias)
        let categorias: CheckboxSubcategoria[] = [];
        sucursales.forEach(
          sucursal => {
            categorias.push({
              nombre: sucursal.nombre,
              activada: false,
              id: sucursal.sucursal.toString(),
            })
          }
        )
        this.sucursales = {
          categorias: categorias,
          nombre: 'Sucursales'
        };
        this.genServ.getUsuarioProvider().subscribe({
          next:usuarioActual => {
            if(usuarioActual === undefined){
              return
            }
            this.usuario = usuarioActual;
            let sucursales = this.genServ.getSucursales();
            //console.log('sucursalesmmax',this.sucursalesPropias,usuarioActual)
            let sucTrue = false;
            for (let i = 0; i < sucursales.length; i++) {
              const element = sucursales[i];
              if (element.sucursal == this.usuario.sucursal) {
                sucTrue = true;
              }
            }
            if (sucTrue == false) {
              this.sucZero = true;
              this.nombreSucursal = (" Sin Definir");
            }
            else {
              this.nombreSucursal = this.usuario.sucursalNombre || ' Sin Definir';
              if(sucursales){
                //console.log(sucursales);
                const sucursalDeUsuario : SucursalEcommerce = sucursales.find(
                  (sucursal : SucursalEcommerce) =>{
                    return sucursal.sucursal === this.usuario.sucursal
                  }
                )
                setTimeout(() => {
                  this.elegirSucursal(sucursalDeUsuario.sucursal.toString());
                }, 1000);
              }
            }
          }
        });
      }
    });



  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });
    this.URLmap();
  }

  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  get validScuru(): boolean {
    if (this.sucursalSelecionada == undefined) {
      return true;
    }
    else {
      return false;
    }
  }

  volvermobile() {
    this.serv.navigator.goLandPage(this.router);
  }

  irASuc() {
    this.serv.gotoURL('sucursales', this.router);
    this.modal.closeAll();
  }

  URLmap() {
    this.urlmap = this.genServ.getURLMap();
    setTimeout(() => {
      if (document.getElementById('map') != null) {
        document.getElementById('map').innerHTML = this.urlmap;
      }
    }, 50);
  };

  sucursalElegidaEnSelect(event: CheckboxSubcategoria){
    const idSucursal = event.id;
    this.elegirSucursal(idSucursal);
  }

  elegirSucursal(idSucursal: string) {
    //console.log('elegirSucursal',idSucursal, this.sucursalesPropias)
    this.sucursalesPropias.forEach(
      sucursalPropia => {
        if (sucursalPropia.sucursal.toString() === idSucursal) {
          this.sucursalSelecionada = sucursalPropia;
          if (document.getElementById('map') != null) {
            console.log('this.sucursalSelecionada.urlmap', this.sucursalSelecionada.urlmap)
            document.getElementById('map').innerHTML = this.sucursalSelecionada.urlmap;
          }
        }
      }
    )
    this.genServ.paginadoSuc();
  }

  async cambiarSucursal() {
    if (localStorage.getItem('currentUser') != null || localStorage.getItem('currentUser') != undefined || this.serv.isloged() == true) {
      if (this.sucursalSelecionada === undefined) {
        this.modal.open('modal-seleccione-suc');
      }
      else {
        this.loader = 'block';
        for (let i = 0; i < this.menus.length; i++) {
          const element = this.menus[i];
          element.activo = false;
        }
        //console.log(this.sucursalSelecionada);

        document.getElementById('map').innerHTML = this.sucursalSelecionada.urlmap;
        await this.genServ.cambiarSucursalV2(this.sucursalSelecionada.sucursal.toString());
        this.loader = 'none';
        this.serv.setMenuActivoNombre('landing')
        this.serv.navigator.goLandPage(this.router);
        this.modal.close('sucursal-modal-1');

      }
    } else {
      this.serv.abrirModalLogin();
    }
  }

}
