<modal-open-point id="vaciar-carro-modal-1">
  <div normal>
    <vaciar-carrito-modal-mmax></vaciar-carrito-modal-mmax>
  </div>
</modal-open-point>
<modal-open-point id="vaciar-carro-modal-2">
  <div normal>
    <vaciar-mobile-mmax-modal></vaciar-mobile-mmax-modal>
  </div>
</modal-open-point>
<modal-open-point id="modal-agregar-producto">
  <div normal>
    <modal-agregar-producto></modal-agregar-producto>
  </div>
</modal-open-point>
<modal-open-point id="modal-articulo">
  <div normal class="modal-art">
    <modal-articulo></modal-articulo>
  </div>
</modal-open-point>
<modal-open-point id="modal-max-art">
  <div normal>
    <modal-max-art></modal-max-art>
  </div>
</modal-open-point>

<div *ngIf="modo==='normal'">
  <div (click)="abriryCerrar()" class="carrito noselect">

    <div class="carritoim">
      <ng-container *ngIf="sincronizandoCarritoConBack">
        <spinner [height]="'40px'" [width]="'40px'" ></spinner>
      </ng-container>
      <ng-container *ngIf="!sincronizandoCarritoConBack">
        <svg-icon src="../../assets/icons/shopping-cart.svg" [svgStyle]="{'width':40,'height' : 40,'fill':'var(--first-ecommerce-color)'}"></svg-icon>
      </ng-container>
    </div>
    <div class="numerador">
      {{total}}
    </div>
    <div class="contadordin">
      {{ totales | currency}}
    </div>
    <div *ngIf='desplegado; else elseBlock' class="triangul"></div>

    <ng-template #elseBlock>
      <div class="inversetriangule"></div>
    </ng-template>
  </div>

  <div class="containerr">
    <div [@openClose]="desplegado ? 'open' : 'closed'" class="pestacarrito">
      <div class="info">
        <div class="indicador">
          Mi Carrito ({{total}} productos)
        </div>
        <div (click)="preguntarVaciar()" class="vaciar">
          <div class="icovaciar"></div>
          Vaciar
        </div>
      </div>
      <div class="productosslim">
        <productos-view [mode]="'slim'" *ngFor="let item of articulosconcantidad" [articulo]="item">
        </productos-view>
      </div>
      <div class="total">
        Total {{totales | currency}}
      </div>
      <div (click)="irAresumenPC()" onclick="window.scrollTo(0,0)" class="comprar">
        Comprar
      </div>
    </div>
  </div>

</div>

<modal-open-point id="modal-salir" class="modal-salir">
  <div normal class="mybody">
    <modal-salir></modal-salir>
  </div>
</modal-open-point>

<modal-open-point id="login-modal-1">
  <div normal class="mybody">
    <login-form-mmax></login-form-mmax>
    <div class="cerrarLogin" (click)="cerrarModale('login-modal-1')">X</div>
  </div>
</modal-open-point>

<modal-open-point id="spinner-modal">
  <div normal class="mybody">
    <div id="container-carga">
      <div id="carga"></div>
    </div>
  </div>
</modal-open-point>
