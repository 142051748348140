import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'remplazo-productos-mmax-modal',
  templateUrl: './remplazo-productos-mmax-modal.component.html',
  styleUrls: ['./remplazo-productos-mmax-modal.component.scss']
})

export class RemplazoProductosMmaxModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
