import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'boton-sumador',
  templateUrl: './boton-sumador.component.html',
  styleUrls: ['./boton-sumador.component.scss'],
})
export class BotonSumadorComponent implements OnInit {
  @Input() contador: number;
  @Input() fraccion: number;
  @Input() unidadMedida: string;
  @Input() modo: string;
  @Output() quantyEvent: EventEmitter<number>;

  constructor() {
    this.quantyEvent = new EventEmitter();
    this.modo = this.modo || 'normal';
  }

  ngOnInit(): void {}

  subircantidad() {
    let cantidadASumar = 1;
    if(this.fraccion){
      cantidadASumar = cantidadASumar * this.fraccion;
    }
    this.quantyEvent.emit(cantidadASumar);
  }

  onChange(evnt) {
    if (evnt > 0) {
      this.quantyEvent.emit(evnt - this.contador);
    } else {
      this.contador = this.contador;
    }
  }

  restarcantidad() {
    let restoCantidad = -1;
    if(this.fraccion){
      restoCantidad = restoCantidad * this.fraccion;
    }
    this.quantyEvent.emit(restoCantidad);
  }

  decimalCount(number) {
    const numberAsString = number.toString();
    if (numberAsString.includes('.')) {
      return '1.1-' + numberAsString.split('.')[1].length.toString();
    }
    return '1.0-0';
  }

  noPuntoComa(event) {
    var e = event || window.event;
    var key = e.keyCode || e.which;

    if (key === 110 || key === 190 || key === 188) {
      e.preventDefault();
    }
  }

  cantidad() {
    let cantidad = (<HTMLInputElement>document.getElementById('contador')).value;
    this.quantyEvent.emit(-this.contador);
    this.quantyEvent.emit(parseInt(cantidad));
    this.contador = parseInt(cantidad);
  }
}
