import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'reset-pass-mmax',
  templateUrl: './reset-pass-mmax.component.html',
  styleUrls: ['./reset-pass-mmax.component.scss']
})

export class ResetPassMmaxComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() loader: string;
  @Input() form: string;

  constructor(private formBuilder: FormBuilder, private serv: MarianomGeneral, private genServ : EcommerceGenericoService,private router: ActivatedRoute, private modal: ModalService) {
    this.imagen = '../../assets/misc/Illustración_CompraVIrutal.svg'
    this.modo = this.modo || 'normal';
    this.loader = this.loader || 'none';
    this.form = this.form || 'block';

  }

  salirMobile() {
    this.serv.goLandPage();
    this.serv.navigator.goLandPage(this.router);
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    })
    new Image().src = this.imagen;
    this.resetform = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      repeatpass: ['', [Validators.required, Validators.minLength(8)]],
      resetid: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.email]]
    });
  }

  async cambiarPass() {
    this.loader = 'flex';
    this.form = 'none';

    if (this.email == undefined) {
      this.email = this.formFields.email.value;
    }
    await this.genServ.pedirCambioPass(this.email).toPromise().then(() => {
      this.loader = 'none';
      this.form = 'block';
    }).catch(err => {
      this.genServ.pendingFalse();
      this.genServ.sendErrorMessage(err.error.mensaje);
      this.loader = 'none';
      this.form = 'block';
      this.modal.open('modal-alert-pass');
      this.errorForm = err.error.mensaje;
    });

  }

  async cambiarPassword() {

    if (this.email == undefined) {
      this.email = this.formFields.email.value;
    }
    await this.genServ.cambiarContraseña(this.email, this.formFields.password.value, this.formFields.resetid.value).toPromise().then(() => {
      this.modal.close('reset-pass-modal-1');
      this.modal.open('login-modal-1');

    }).catch(err => {
      this.genServ.pendingFalse();
      this.genServ.sendErrorMessage(err.error.mensaje);
      this.modal.open('modal-alert-pass');
      this.errorForm = err.error.mensaje;
      console.error(err['statusText']);
    });
  }

  get formFields() {
    this.email = this.serv.email;
    return this.resetform.controls;
  }

  get userData() {
    return this.genServ.obtenerdatosdeUsuario();
  }

  imagen: string;
  resetform: FormGroup;
  createForm: FormGroup;
  errorForm: string;
  email: string;

}
