<div normal>
  <modal-default-mmaxapp>
    <div class="tarjetaconfirmar">
      <div class="titulo">
        ¿Está seguro que desea cerrar la sesión?
      </div>
      <div class="botones">
        <button class="buttonpc whibutton" (click)="cancelar()">No</button>
        <button class="buttonpc redbutton spacerRed" (click)="salir()" onclick="window.scrollTo(0, 0)">Si</button>
      </div>
    </div>
  </modal-default-mmaxapp>
</div>
