<div *ngIf="modo==='crearcuenta'">
  <div class="informer">
    <div class="titule">
      Sucursales
    </div>
    <div class="descrip">
      Selecciona una de nuestras sucursales donde deseas retirar tu compra.
    </div>
  </div>
  <section>
    <div class="menus">
      <div class="mapa" id="map">
      </div>
      <div class="seleccion">
        <div class="selecionfocus">
          <div class="selectitulo">
            Selecciona tu sucursal.
          </div>
          <div class="selecdescrip">
            Recorda! Los pedidos se retiran por una de nuestras sucursales, elegí tu sucursal más cercana.
          </div>
          <div class="optionselect">
            <div class="medium">
              <div class="labelselec">
                Sucursales
              </div>
              <div class="selectorplace">
                <selector-generico [mode]="'sucursal'" [catego]="sucursales" [defaultSelect]="nombreSucursal" ></selector-generico>
              </div>
              <div class="account">
                Clickeando Crear Cuenta aceptas los términos y condiciones de nuestro servicio.
              </div>
              <div class="botoncrear">
                Crear Cuenta
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="modo==='normal'">
  <div class="container-carga" [ngStyle]="{'display': loader}">
    <div class="carga"></div>
  </div>
  <div class="informer">
    <div class="titule">
      Sucursales
    </div>
    <div class="descrip">
      Selecciona una de nuestras sucursales donde deseas retirar tu compra.
    </div>
  </div>
  <section>
    <div class="menus">
      <div class="seleccion">
        <div class="selecionfocus" *ngIf="this.sucZero == false; else NotSuc">
          <div class="selectitulo">
            Sucursal seleccionada: {{nombreSucursal}}
          </div>
          <div class="selecdescrip">
            Recorda! Los pedidos se retiran por una de nuestras sucursales, usted retira en la sucursal
            {{nombreSucursal}}
          </div>
          <div *ngIf="this.usuario && !oneSuc" class="optionselect" id="btnSuc">
            <div class="medium">
              <div class="labelselec">
                Selecciona tu sucursal.
              </div>
              <div class="selectorplace">
                <selector-generico (onSelect)="sucursalElegidaEnSelect($event)" [mode]="'sucursal'"
                  [catego]="sucursales" [defaultSelect]="nombreSucursal"></selector-generico>
              </div>

              <div class="account">

              </div>
              <div class="mapa" id="map">
              </div>
              <button (click)="cambiarSucursal()" [disabled]="validScuru" class="botoncrear2 noselect bton" disabled>
                Seleccionar Sucursal
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <mmax-helpfootbar></mmax-helpfootbar>

  <foo-mariano-max></foo-mariano-max>
</div>

<div *ngIf="modo==='modal-pc'">
  <section class="section-modal">
    <div class="menus menusModal">
      <div class="seleccion seleccionModal">
        <div class="selecionfocus" *ngIf="this.sucZero == false; else NotSuc">
          <div class="selectitulo selecTituloModal">
            {{nombreSucursal}}
          </div>
          <div class="selecdescrip selecDescripModal">
            <div>
              Si desea cambiar de sucursal presione aqui.
            </div>
            <button (click)="irASuc()" class="botoncrear2 botonCrear2Modal noselect bton">
              Cambiar sucursal
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div *ngIf="modo==='crearcuenta'">
  <div class="selecioncontaier fixwidthfull">
    <div class="textmobilecontainer">
      <div class="welcomemobile">
        Selecciona tu Sucursal
      </div>
      <div class="descmobilewelc">
        Recorda! Los pedidos se retiran por una de nuestras sucursales, elegí tu sucursal más cercana.
      </div>
    </div>
    <div class="labelselec">
      Sucursales
    </div>
    <div class="selectorplace">
      <selector-generico [mode]="'sucursal'" [catego]="sucursales" [defaultSelect]="nombreSucursal"></selector-generico>
    </div>
    <div class="account">
      Clickeando Crear Cuenta aceptas los términos y condiciones de nuestro servicio.
    </div>
    <div class="botoncrear fixwidth">
      Crear Cuenta
    </div>
  </div>
</div>
<div *ngIf="modo==='modal'">
  <div class="selecioncontaier fixwidthfull">
    <div class="exitmobilelogin">
      <div class="exMobile noselect">
        X
      </div>
    </div>
    <div class="textmobilecontainer">
      <div class="welcomemobile">
        Retirar en Sucursal {{sucursalSelecionada.sucursal}}
      </div>
      <div class="barrioSucursal">
        {{sucursalSelecionada.barrio}}
      </div>
      <div class="barrioSucursal">
        {{sucursalSelecionada.calle}} - {{sucursalSelecionada.barrio}}
      </div>
    </div>
    <div class="botoncrear fixwidth fixcolor">
      Retirar Aquí
    </div>
    <div class="botoncrear fixwidth">
      Crear Cuenta
    </div>
  </div>
</div>

<ng-template #NotSuc>
  <div class="selecionfocus">
    <div class="selectitulo">
      No tiene seleccionada ninguna sucursal!
    </div>
    <div class="selecdescrip">
      Recorda! Los pedidos se retiran por una de nuestras sucursales, por favor seleccione una sucursal.
    </div>
    <div class="medium">
      <div class="labelselec">
        Selecciona tu sucursal.
      </div>
      <div class="selectorplace">
        <selector-generico (onSelect)="sucursalElegidaEnSelect($event)" [mode]="'sucursal'" [catego]="sucursales" [defaultSelect]="nombreSucursal">
        </selector-generico>
      </div>
      <div class="account"></div>
      <div class="mapa" id="map"></div>
      <button (click)="cambiarSucursal()" [disabled]="validScuru" class="botoncrear2 noselect bton" disabled>
        Seleccionar Sucursal
      </button>
    </div>
  </div>
</ng-template>
