import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RemplazosMMax } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'mmax-input-selector',
  templateUrl: './mmax-input-selector.component.html',
  styleUrls: ['./mmax-input-selector.component.scss']
})

export class MmaxInputSelectorComponent implements OnInit {
  @Input() opciones: RemplazosMMax[];
  @Input() item: RemplazosMMax;
  @Output() onSelect: EventEmitter<any>;

  constructor() {
    this.onSelect = new EventEmitter();
  }

  ngOnInit(): void {

  }

  onchange(event: RemplazosMMax) {
    //console.log('onchange antes',event)
    this.opciones.forEach(
      opcion => {
        (opcion.idcss === event.idcss)?opcion.activo = !opcion.activo:opcion.activo = false;
      }
    )
    this.opciones = JSON.parse(JSON.stringify(this.opciones))
    this.onSelect.emit(this.opciones)
    //console.log('onchange despues', event)
  }

}
