<!-- usado en pagina de resultados de busqueda -->
<div *ngIf="modelo==='normal'" class="mycontainer" onload="anchoPag()">
  <div class="busquedayFiltros">
    <div class="uplabel">
      <div class="categoria">
        <div *ngFor="let cate of categoriasClickeables ; index as i" class="mnsjae">
          <div class="myborder" (click)="clickOnCate(cate.categoria,cate.activable)"
            *ngIf='(categoriasClickeables.length-1)!=i'>
            {{ cate.nombre }} ⮞ &nbsp;
          </div>
          <div class="myborder active" *ngIf='(categoriasClickeables.length-1)==i'>
            {{ cate.nombre }} &nbsp;
          </div>
        </div>
      </div>
    </div>
    <div class="uplabel">
      <div class="mnsjae">
        {{articulosFiltrados.length}} Resultados
        <span *ngIf="myExtrasResultado.textodebusqueda">
          en “{{myExtrasResultado.textodebusqueda}}”
        </span>
      </div>
    </div>
    <div class="filtros">
      <div class="ordenarpor">
        <div class="labelfiltro">
          Ordenar por:
        </div>
        <selector-generico *ngFor="let item of gaseosas" (onSelect)="seleccionadoFiltro($event)" [mode]="'selector'"
          [catego]="item" [defaultSelect]="filtroElegidoNombre"></selector-generico>
      </div>
      <div class="rangoprecio">
        <div class="labelfiltro">
          Precio:
        </div>
        <div class="rangoPrecioInputs">
          <input class="inputPrecio"
            [ngClass]="{'filtrandoPrecio': ngmin, 'ValorFiltroErroneo': ngmin && ngmin > ngmax}" [(ngModel)]="ngmin"
            id="precioMin" type="number" placeholder="Desde" min="0" (input)="filtrarArticulos()">
          <div class="preciosline">
            -
          </div>
          <input class="inputPrecio"
            [ngClass]="{'filtrandoPrecio': ngmax, 'ValorFiltroErroneo': ngmax && ngmax < ngmin}" [(ngModel)]="ngmax"
            id="precioMax" type="number" placeholder="Hasta" min="0" (input)="filtrarArticulos()">
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="articulosFiltrados.length>0; else sinArticulos" class="articulos" id="articulos">
    <ng-container *ngFor="let articulo of articulosFiltrados">
      <productos-view [articulo]="articulo"></productos-view>
    </ng-container>
  </div>

  <ng-template #sinArticulos>
    <div class="contanerselectors articulos">
      <div class="mnsjae" style="width:100%; text-align: center;">
        No se encontraron resultados para su búsqueda.
      </div>
    </div>
  </ng-template>

  <div class="contanerselectors" style="justify-content: center;">
    <paginador-mmax-app [paginas]="numeropaginas" (paginaElegida)="onPageSelect($event)"> </paginador-mmax-app>
  </div>
</div>

<div *ngIf="modelo==='postcheckout'" class="checkoutblock">
  <productos-view *ngFor="let item of articulos" [articulo]="item" [mode]="'postcheckout'"></productos-view>
</div>
<!-- esto se muestra en la landing page en la seccion Promociones del Mes  -->
<div *ngIf="modelo==='promos-land'" class="slimarticulos" id="slimarticulosPromos">
  <ng-container *ngFor="let articulo of articulos ; let i = index">
    <ng-container *ngFor="let promos of articulo.getPromos()">
      <promocion-card class="produc-view" [promocion]="promos"></promocion-card>
    </ng-container>
  </ng-container>
  <button class="control prev" (pointerdown)="onPreviousClickPr()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (pointerdown)="onNextClickPr()">
    <span class="arrow right"></span>
  </button>

</div>
<!-- esto se muestra en la landing page en seccion Ofertas del Mes -->
<div *ngIf="modelo==='ofertas-land'" class="slimarticulos" id="slimarticulosOfertas">
  <ng-container *ngFor="let item of articulos ; let i = index">
    <productos-view class="produc-view" [mode]="'ofertas-land'" [articulo]="item"></productos-view>
  </ng-container>
  <button class="control prev" id="prev" (pointerdown)="onPreviousClickOf()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" id="next" (pointerdown)="onNextClickOf()">
    <span class="arrow right"></span>
  </button>
</div>

<!-- carrito -->
<div *ngIf="modelo==='resumendetail'">

  <div class="mytabla header">
    <div class="prod center">
      Producto
    </div>
    <div class="preciu center">
      Precio Unitario
    </div>
    <div class="canti center">
      Cantidad
    </div>
    <div class="preciureal center">
      Precio Total
    </div>
    <div class="prodInfo center">
      Productos Info.
    </div>
    <div class="options center" id="options">
      <selector-generico id="selector" [mode]="'selector'" *ngFor="let item of reemplazos" [catego]="item"
        (onSelect)="categoriaSelec($event)"></selector-generico>
    </div>
  </div>
  <div class="tablacontainer">
    <div *ngFor="let item of articulos">
      <productos-view [mode]="'detalle'" [articulo]="item"></productos-view>
    </div>
  </div>

</div>
<!-- historials -->
<div *ngIf="modelo==='historialDetail'">

  <div class="mytabladetail header">
    <div class="prod center">
      Producto
    </div>
    <div class="preciu center">
      Precio Unitario
    </div>
    <div class="canti center">
      Cantidad
    </div>
    <div class="preciureal center">
      Precio Total
    </div>
    <div class="prodInfo center">
      Productos Info.
    </div>
    <!-- <div class="options center">

    </div> -->
  </div>
  <div class="tablacontainer">
    <productos-view [mode]="'detalletrakin'" *ngFor="let item of articulos" [articulo]="item"></productos-view>
  </div>

</div>

<div *ngIf="modelo==='oferta'" class="ofertas-capa">
  <div class="ofertas">
    <productos-view *ngFor="let item of articulos" [articulo]="item" [mode]="'ofertas'" class="promosView"
      style="margin-bottom: 1em;">
    </productos-view>
  </div>
  <div class="contanerselectors" style="justify-content: center;" *ngIf="numeropaginasOfertas>1">
    <paginador-mmax-app [paginas]="numeropaginasOfertas" (paginaElegida)="onPageSelectOferta($event)">
    </paginador-mmax-app>
  </div>
</div>

<div *ngIf="modelo==='promociones'" class="promos">
  <productos-view *ngFor="let promos of articulos" [articulo]="promos" [mode]="'promos'"
    class="promosView"></productos-view>
</div>
