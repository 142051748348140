import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';

import { ArticulosGeneral, EcommerceGenericoService, RemplazosMMax, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { CheckboxCategorias, CheckboxSubcategoria } from '../etc/categoria-checkbox.interface';
import { MarianomGeneral } from '../marianom-general.service';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';

@Component({
  selector: 'app-resumen-comprammax',
  templateUrl: './resumen-comprammax.component.html',
  styleUrls: ['./resumen-comprammax.component.scss']
})

export class ResumenComprammaxComponent implements OnInit {
  @Input('mode') modo: string;

  width : number;
  misreemplazos: RemplazosMMax[];
  arrowsrc: string;
  reemplazos: CheckboxCategorias[];
  montoMinimo: number;
  usuario: Usuario;
  articulosConCantidad : ArticulosGeneral[] = [];
  terminos_condiciones : string;

  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private router: ActivatedRoute, private modal: ModalService) {
    this.arrowsrc = '../../assets/icons/arrow-left-white.svg';
    this.reemplazos = [];
    this.montoMinimo = 0;
    this.terminos_condiciones = this.genServ.getTerminosCondiciones() || "";
    this.genServ.getUsuarioProvider().subscribe(data => {
      this.usuario = data;
    });
    this.genServ.getTerminoDeIniciarCarro().subscribe({
      next: terminoDeCargar =>{
        //console.log('terminoDeCargar')
        this.actualizarCarro();
      }
    });
    this.genServ.getObservableSeAgregaronItemsAlCarrito().subscribe({
      next: actualizarCarro =>{
        this.actualizarCarro();
      }
    })
  }

  ngOnInit(): void {
    this.router.data.subscribe({
      next:data => {
        this.modo = data['mode'] || this.modo || 'normal';
      }
    });
    this.genServ.getReemplazosProvider().subscribe({
      next: data => {
        this.misreemplazos = data;
        let subcat: CheckboxSubcategoria[] = [];
        this.misreemplazos.forEach(
          (reemplazo : RemplazosMMax) => {
            subcat.push({
              nombre: reemplazo.nombre,
              activada: false,
              id: reemplazo.modo
            });
          }
        );
        this.reemplazos.push({
          nombre: 'Reemplazo',
          categorias: subcat
        });
      },
    });
    this.montoMinimo = this.genServ.getMontoMinimo()
    this.articulosConCantidad = this.genServ.getCarrito().slice()
  }

  get totales() {
    return this.genServ.getTotal();
  }

  actualizarCarro(){
    this.articulosConCantidad = this.genServ.getCarrito().slice();
    if(this.articulosConCantidad.length === 0){

      const rutas : MenuMmaxInterface[] = this.serv.rutas.filter(
        ruta =>{
          return ruta.nombre === 'Productos'
        }
      )
      let rutaProducto : MenuMmaxInterface = rutas[0];
      this.serv.gotoURL("/" + rutaProducto.ruta, this.router);
      this.serv.activatethis(rutaProducto);
      //console.log('this.articulosConCantidad.length === 0')
    }
  }

  categoriaSelec(event: CheckboxSubcategoria) {
    this.serv.marcarCarroConRemplazo(event)
  }

  volverAtras() {
    this.serv.navigator.goLandPage(this.router);
  }

  preguntarVaciar() {
    this.modal.open('vaciar-carro-modal-3');
  }

  volverATras() {
    this.serv.navigator.goLandPage(this.router);
  }

  irAchekout() {
    this.serv.navigator.precierreCheckout(this.router);
  }


  goCheckoutPc() {
    if (localStorage.getItem('currentUser') === null || localStorage.getItem('currentUser') === undefined) {
      this.modal.open('login-modal-1');
      return
    }
    let total = this.genServ.getTotal();
    let sucursales = this.genServ.getSucursales();
    let seEncontroSucursal = false;
    for (let i = 0; i < sucursales.length; i++) {
      const element = sucursales[i];
      if (element.sucursal === this.usuario.sucursal) {
        seEncontroSucursal = true;
      }
    }
    if (!seEncontroSucursal) {
      this.modal.open('modal-seleccione-suc');
      return
    }
    if (total < this.montoMinimo || total === 0) {
      this.modal.open("modal-monto-minimo");
    }
    else {
      this.serv.navigator.precierreCheckout(this.router);
    }
  }

  respuestaHayCarritoOffline(respuestaUsuario : string){
    console.log('respuestaHayCarritoOffline',respuestaUsuario)
  }
}
