<div class="statescontainer">

    <ng-container *ngFor="let estado of estados ; let i = index">
        <div *ngIf="i!=estados.length-1" class="mystado">
            <div class="estado">
                <div class="ico">
                    <img *ngIf="estado.activado" class="imagefull" [src]="iconreference" alt="ok-step">
                </div>
                <div class="labelico">
                    {{estado.nombre}}
                </div>
            </div>
            <div class="justline"></div>
        </div>
        <div *ngIf="i==estados.length-1" class="mystado">
            <div class="estado">
                <div class="ico">
                    <img *ngIf="estado.activado" class="imagefull" [src]="iconreference" alt="ok-step">
                </div>
                <div class="labelico">
                    {{estado.nombre}}
                </div>
            </div>
        </div>
    </ng-container>




</div>