<div *ngIf='mode ==="normal"'>
  <div class="selectorbase">
    <div class="selectormain noselect" (click)="desplegar()">
      <div class="cara">
        {{catego.nombre}}
      </div>
      <div class="ico">
      </div>
    </div>
    <div [@openClose]="desplegado ? 'open' : 'closed'" class="extendible">
      <div class="predictions">
        <div *ngFor="let subcate of catego.categorias" class="prediccion">
          <label class="descrip" [for]="subcate.nombre"> {{subcate.nombre}}</label>
          <input type="checkbox" class="checkbox" [name]="subcate.nombre" [id]="subcate.nombre" ([ngModel])="subcate.activada">
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf='mode ==="sucursal"'>
  <div class="selectorbase">
    <div class="selectorsucursal noselect" (click)="desplegar()">
      <div class="carasucursal">
        {{selecionado}}
      </div>
      <div class="imaico">
        <img src="../../assets/icons/sucursal-chevron.svg" alt="">
      </div>
    </div>
    <div [@openClose]="desplegado ? 'open' : 'closed'" class="extendible extendiblesucursal">
      <div class="predictions">
        <div (click)="onclik(subcate)" *ngFor="let subcate of catego.categorias" class="sucursusula">
          <label class="sucususula " [for]="subcate.nombre">
             {{subcate.nombre}}
            </label>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf='mode ==="selector"'>
  <div class="selectorbase">
    <div class="nuevoSelect noselect" (click)="desplegar()">
      <div class="nuevoFont">
        {{selecionado}}
      </div>
      <div class="imaico">
        <img src="../../assets/icons/sucursal-chevron.svg" alt="">
      </div>
    </div>
    <div [@openClose]="desplegado ? 'open' : 'closed'" class="extendible extendibleselect">
      <div class="predictions">
        <div (click)="onclik(subcate)" *ngFor="let subcate of catego.categorias" class="sucursusula">
          <label class="selections " [for]="subcate.nombre">
            {{subcate.nombre}}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="mode ==='inputSel'">
  <div class="selectorbase">
    <div class="nuevoSelect noselect" (click)="desplegar()">
      <div class="nuevoFont">
        {{selecionado}}
      </div>
      <div class="imaico">
        <img src="../../assets/icons/sucursal-chevron.svg" alt="">
      </div>
    </div>
    <div [@openClose]="desplegado ? 'open' : 'closed'" class="extendible extendibleselect">
      <div class="predictions">
        <div (click)="onRemplazSelect(subcate)" *ngFor="let subcate of remplazos" class="sucursusula">
          <label class="selections " [for]="subcate.nombre">
            {{subcate.nombre}}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
