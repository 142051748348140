import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MarianomGeneral } from '../marianom-general.service';
import { EcommerceGenericoService, HistorialPedidos } from 'projects/ecommerce-generico/src/public-api';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'historial-mmax-compras',
  templateUrl: './historial-mmax-compras.component.html',
  styleUrls: ['./historial-mmax-compras.component.scss']
})

export class HistorialMMaxComprasComponent implements OnInit {
  @Input() modo: string;

  cargando: boolean = true;
  historial: HistorialPedidos[];
  hayPedidos : boolean = true;
  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService,private router: ActivatedRoute) {
    this.modo = this.modo || 'normal';
  }

  async ngOnInit() {
    if (localStorage.getItem('currentUser') == null) {
      this.serv.navigator.goLandPage(this.router);
    } else {
      this.cargarPedidos();
      this.router.data.subscribe(data => {
        this.modo = data['mode'] || this.modo || 'normal';
      })
    }
  }

  reload() {
    this.historial = [];
    this.cargando = true;
    this.cargarPedidos();
  }

  cargarPedidos(){
    this.genServ.getHistorialDePedidos().subscribe({
      next:respuestaPedidos =>{
        const pedidos = this.genServ.obtenerHistorialDePedidosCargados();
        this.historial = this.genServ.ordenarporFechaHistorial(pedidos);
        console.log(this.historial)
        this.cargando = false;
        this.hayPedidos = this.historial.length !== 0;
      }
    });
  }

  recompra(jistoriaRecompra: HistorialPedidos) {
    this.genServ.cargarEstePedidoDeHistorialEnCart(jistoriaRecompra);
    this.serv.navigator.irResumenCompra(this.router)
  }

  verDetalle(detalle: HistorialPedidos) {
    this.genServ.verPedido(detalle);
    this.serv.navigator.goDetallePedido(this.router);
  }
}
