import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { DiferenciaPromo, EcommerceGenericoService, MontoPromo, PorcentajePromo, PromocionesEccomerce } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'promocion-card',
  templateUrl: './promocion-card.component.html',
  styleUrls: ['./promocion-card.component.scss']
})
export class PromocionCardComponent implements OnInit {
  @Input() promocion: PromocionesEccomerce;


  tipoPromocion : string = '';
  cantidadPromocion : number = 0;
  constructor( private genServ : EcommerceGenericoService) {
    this.genServ.getObservableSeAgregaronItemsAlCarrito().subscribe({
      next:aviso=>{
        //console.log('promocard aviso nuevo art')
        this.cantidadPromocion = Math.floor(this.promocion.getArticulo1().getCantidad()/this.promocion.getCantidad1())
      }
    })
   }

  ngOnInit(): void {
    //console.log('promocion card',this.promocion)

    if(this.promocion instanceof PorcentajePromo){
      this.tipoPromocion = 'Porcentaje';
    }

    if(this.promocion instanceof MontoPromo){
      this.tipoPromocion = 'MontoPromo'
    }

    if(this.promocion instanceof DiferenciaPromo){
      this.tipoPromocion = 'DiferenciaPromo'
    }
    if(this.promocion.getArticulo1()){
      this.cantidadPromocion = Math.floor(this.promocion.getArticulo1().getCantidad()/this.promocion.getCantidad1())
    } else{
      this.cantidadPromocion = this.promocion.getCantidad1();
    }
    setTimeout(() => {
      if(this.promocion.getArticulo1()){
        this.cantidadPromocion = Math.floor(this.promocion.getArticulo1().getCantidad()/this.promocion.getCantidad1())
      } else{
        this.cantidadPromocion = this.promocion.getCantidad1();
      }
    }, 2000);
  }

  agregarACarrito( event : number){
    //console.log('promocion-card agregarACarrito' ,event)
    this.promocion.getArticulo1().setCantidad(this.promocion.getArticulo1().getCantidad() + (event * this.promocion.getCantidad1()))
    this.genServ.sincronizarCarritoFrontyBack();
    this.promocion.getArticulo1().operacionesExtras();
    this.cantidadPromocion = Math.floor(this.promocion.getArticulo1().getCantidad()/this.promocion.getCantidad1())
  }
}
