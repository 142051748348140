<div *ngIf="modo==='normal'">
  <section class="section">
    <div class="centro">
      <div class="formpart">
        <div class="lal">
          Bienvenido a {{nombreSitio}}
        </div>
        <div class="subtext">
          Inicia sesión para comenzar tu compra.
          <span class="registrogate" (click)="resetPass()" id="registroHab" [ngStyle]="{display: registroHab}">
            Olvidaste tu contraseña?
          </span>
        </div>
        <div class="formsection">
          <div class="formcontainer">
            <form [formGroup]="loginform" (ngSubmit)="submitForm()">
              <div *ngIf="loginform.errors && loginform.invalid">
                <div class="errorbuble">
                  {{errorgen}}
                </div>
              </div>
              <label class="labelform">
                <span class="labell">
                  Mail
                </span>
                <input class="inputform" type="email" [formControlName]="'username'" autocomplete="username" autoCapitalize="none"/>
                <div *ngIf="formFields.username.touched && formFields.username.invalid" class="tooltip">
                  <div class="tooltiptext" *ngIf="formFields.username.errors.required">
                    Este campo no puede estar vacío.
                  </div>
                </div>
              </label>
              <label class="labelform">
                <span class="labell">
                  Contraseña
                </span>
                <input class="inputform" type="password" [formControlName]="'password'"
                  autocomplete="current-password" autocapitalize="none" />
                <div *ngIf="formFields.password.touched && formFields.password.invalid" class="tooltip">
                  <div class="tooltiptext" *ngIf="formFields.password.errors.required">
                    Este campo no puede estar vacío.
                  </div>
                </div>
              </label>
              <button [disabled]="!loginform.valid" class="botning" type="submit">Iniciar sesión</button>

            </form>
          </div>
        </div>

        <div class="formsection">
          <div class="formcontainer">
            <div class="cccount" id="registroHab" [ngStyle]="{display:registroHab}">
              <div class="noaccount">
                No tenes cuenta aún?
              </div>
              <div (click)="createUser()" class="crearcuenta">
                Crear cuenta
              </div>
            </div>
            <div class="cccount" id="registroHab" [ngStyle]="{display:beneficiosTrue}">
              <div (click)="resetPass()" class="crearcuenta">
                Reestablecer Contraseña
              </div>
            </div>

          </div>

        </div>

      </div>
      <div class="dibugopart">
        <div class="imagecontainer">
          <img class="imgLogin" [src]="imagen">
        </div>

      </div>

    </div>
  </section>
</div>
<modal-open-point id="create-user-modal-1">

  <div normal class="mybody">
    <create-user-mmax></create-user-mmax>
    <div class="cerrarC" (click)="cerrarModale('create-user-modal-1')">X</div>
  </div>

</modal-open-point>
<modal-open-point id="reset-pass-modal-1">

  <div normal class="mybody">
    <reset-pass-mmax></reset-pass-mmax>
    <div class="cerrarR" (click)="cerrarModale('reset-pass-modal-1')">X</div>
  </div>

</modal-open-point>

<modal-open-point id="restore-pass-modal">
  <div normal class="mybody">
    <restore-pass-mmax></restore-pass-mmax>
    <div class="cerrarR" (click)="cerrarModale('restore-pass-modal')">X</div>
  </div>
</modal-open-point>

<modal-open-point id="send-reset-pass-modal-1">
  <div normal class="mybody">
    <send-reset-pass></send-reset-pass>
    <div class="cerrarR" (click)="cerrarModale('send-reset-pass-modal-1')">X</div>
  </div>
</modal-open-point>

<modal-open-point id="modal-alert-pass">
  <div normal>
    <modal-alert-pass></modal-alert-pass>
  </div>
</modal-open-point>

<modal-open-point id="modal-seleccione-suc">
  <div normal>
    <modal-seleccione-suc></modal-seleccione-suc>
  </div>
</modal-open-point>

<modal-open-point id="modal-pass-error">
  <div normal>
    <modal-pass-error></modal-pass-error>
  </div>
</modal-open-point>
