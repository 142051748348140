<div *ngIf="mode==='normal'">
    <div class="spacer">
        <div class="redframe">
            <div class="frame">
                <div class="textframe">
                    {{nombre}}
                </div>
            </div>
        </div>
    </div>
</div>