<!-- usado en productos cards -->
<div *ngIf="modo==='normal'">
  <div *ngIf="contador === 0; else elseBlock" (click)="subircantidad()" class="incantibtn">
    <div class="textmybtn">
      Agregar
    </div>
  </div>

  <ng-template #elseBlock>
    <div class="incantibtn">
      <div (click)="restarcantidad()" class="menius">
        <div class="icomasmas">
          -
        </div>
      </div>
      <div *ngIf="!fraccion" class="contado">
        <input type="number" class="contador" [ngModel]="contador" (keydown)="noPuntoComa($event)" (ngModelChange)="onChange($event)" autocomplete="off">
      </div>
      <div *ngIf="fraccion" class="contado">
        {{contador | number:decimalCount(fraccion)}} {{unidadMedida}}
      </div>
      <div (click)="subircantidad()" class="plusmin">
        <div class="icomenosmenos">
          +
        </div>
      </div>
    </div>
  </ng-template>
</div>
<!-- usado en modal carrito -->
<div *ngIf="modo==='detail'">
  <div class="incantibtn detaillbtn" [ngStyle]="{'width': (fraccion)?'180px':'130px'}" >
    <div (click)="restarcantidad()" class="menius">
      <div class="icomasmas">
        -
      </div>
    </div>
    <div *ngIf="!fraccion" class="contado">
      {{contador}}
    </div>
    <div *ngIf="fraccion" class="contado" >
      {{contador | number:decimalCount(fraccion)}} {{unidadMedida}}
    </div>
    <div (click)="subircantidad()" class="plusmin">
      <div class="icomenosmenos">
        +
      </div>
    </div>
  </div>
</div>

<div *ngIf="modo==='promos'">
  <div *ngIf="contador<1; else elseBlock" (click)="subircantidad()" class="incantibtn">
    <div class="textmybtn">
      Agregar
    </div>
  </div>

  <ng-template #elseBlock>
    <div class="incantibtn">
      <div class="textmybtn">
        En Carro.
      </div>
    </div>
  </ng-template>
</div>
