<div class="seccion">
    <div class="nombre">
        <div class="ico">

        </div>
        <div class="label">
            <div class="ttile">
                Productos Frecuente
            </div>
            <div class="prepaf">
                Estos son los productos que marcaste como frecuente y tener acceso rápido a ellos.
            </div>
        </div>


    </div>
</div>
<div class="margensection">
    <div class="selectores">
        <div class="tipo">
            <selector-generico [catego]="gaseosas[0]"></selector-generico>
        </div>
        <div class="marc">
            <selector-generico [catego]="gaseosas[1]"></selector-generico>
        </div>
        <div class="ordenpor">
            <div class="label">
                Ordenar por:
            </div>
            <selector-generico [catego]="gaseosas[2]"></selector-generico>
        </div>

    </div>
    <div class="containermainprod">
        <lista-productos-view [articulosIn]="mysucursalActiva"></lista-productos-view>
    </div>
</div>