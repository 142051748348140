import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoriasWebMenu, EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';
import { GenericoSlideAnim } from '../etc/generico-animation';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'categ-viewgeneric',
  templateUrl: './categ-viewgeneric.component.html',
  styleUrls: ['./categ-viewgeneric.component.scss'],
  animations: [GenericoSlideAnim]
})

export class CategViewgenericComponent implements OnInit {
  @Input() categoria: CategoriasWebMenu;
  @Input() color: string;
  @Input() mode: string;
  @Output() categoriasEvento: EventEmitter<CategoriasWebMenu>;

  desplegado: boolean;
  desplegado2: boolean;

  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private router: ActivatedRoute) {
    this.desplegado = false;
    this.desplegado2 = false;
    this.color = this.color || 'none';
    this.categoriasEvento = new EventEmitter();
  }

  ngOnInit(): void {
    if (this.serv.returnBusqueda() == undefined || this.serv.returnRefreshProducts() == true) {
      this.serv.inciarbusqueda('');
    }
    this.categoria.desplegado = false;
  }

  get desplegadoCat(): boolean {
    return this.serv.desplegadocat;
  }

  get categorias() {
    return this.genServ.getCategoriasWeb();
  }

  abrirCerrar() {
    this.serv.desplegaryCerrar(!this.desplegadoCat);
    console.log("abrirCerrar", this.desplegadoCat);

  }

  onDeplegar() {
    if (this.categoria.desplegado) {
      this.categoria.desplegado = !this.categoria.desplegado;
      //console.log('ondesplegar this.categoria.desplegado === true ',this.categoria)
      this.categoriasEvento.emit(JSON.parse(JSON.stringify(this.categoria)));
      this.desplegado = true;
    }
    else {
      for (let i = 0; i < this.categorias.length; i++) {
        const element = this.categorias[i];
        element.desplegado = false;
        element.subCategorias.forEach(k => {
          k.desplegado = false;
        })
      }
      this.categoria.desplegado = !this.categoria.desplegado;
      this.categoriasEvento.emit(this.categoria);
      this.desplegado = false;
    }
  }

  desplegarSubCat(sub: CategoriasWebMenu) {
    this.categoria.subCategorias.forEach(k => {
      if (sub != k) {
        k.desplegado = false;
      }
    })
    sub.desplegado = !sub.desplegado;
  }

  activartirdcat(tercercat: CategoriasWebMenu, segundacat: CategoriasWebMenu) {
    segundacat.subCategorias.forEach(element => {
      element.desplegado = false;
    });
    tercercat.desplegado = true;
  }

  irAcategorias(categoria: CategoriasWebMenu, categoriaPadre? : CategoriasWebMenu) {

    if (categoria.desplegado) {
      this.genServ.busquedaCategoria(categoria, this.categoria);
      this.serv.navigator.goSearch(this.router);
    }
    else if(categoriaPadre){
      this.genServ.busquedaCategoria(categoriaPadre, this.categoria);
      this.serv.navigator.goSearch(this.router);
    }
  }

  irMarcasMobile(marca: CategoriasWebMenu) {
    this.genServ.iniciarbusquedaPorCodigoMarca(marca, this.categoria);
    this.serv.navigator.goSearch(this.router);
  }

}
