<div *ngIf="mode==='normal'">
  <div class="contenerdor" [ngStyle]="{'background':color}">
    <div class="contenedorsub" [ngClass]="{'myline': categoria.desplegado}"
      (click)="onDeplegar(); irAcategorias(categoria)" onclick="window.scrollTo(0,0)">
      <div class=" comunnivel marker ">
        <img class="imageico" [src]="categoria.imagen">
      </div>
      <div class="comunnivel label">
        {{categoria.nombre}}
      </div>
      <button [ngClass]="{'clicked': categoria.desplegado}" type="button" class="comunnivel chevbrow btn">
      </button>
    </div>

    <div *ngFor="let subCategoria of categoria.subCategorias" [@openClose]="categoria.desplegado ? 'open' : 'closed'" class="subcategorias" >
      <div class="subcontenedor" (click)="desplegarSubCat(subCategoria);irAcategorias(subCategoria, categoria);abrirCerrar()" onclick="window.scrollTo(0,0)" [ngClass]="{'plawer': subCategoria.desplegado}">
        <div class="comunnivel plus" *ngIf='subCategoria.desplegado; else restblock'>
          -
        </div>
        <ng-template #restblock>
          <div class="comunnivel plus">
            +
          </div>
        </ng-template>
        <div class="comunnivel subcategoriadecat">
          {{subCategoria.nombre}}
        </div>
        <div class="comunnivel pronner">
        </div>
      </div>

      <div *ngFor="let subSubCategoria of subCategoria.subCategorias; index as i" [@openClose]="subCategoria.desplegado ? 'open' : 'closed'" class="subcategorias">
        <div *ngIf='(subCategoria.subCategorias.length -1)!=i' class="subcontenedor" (click)="activartirdcat(subSubCategoria,subCategoria);irAcategorias(subSubCategoria);abrirCerrar()" onclick="window.scrollTo(0,0)">
          <div class="comunnivel plus">
          </div>
          <div [ngClass]="{'brabelavel': subSubCategoria.desplegado}" class="comunnivel subcategoriadecat">
            {{subSubCategoria.nombre}}
          </div>
          <div class="comunnivel pronner">
          </div>
        </div>

        <div *ngIf='(subCategoria.subCategorias.length -1)==i' class="subcontenedor myline" (click)="activartirdcat(subSubCategoria,subCategoria);irAcategorias(subSubCategoria)" onclick="window.scrollTo(0,0)">
          <div class="comunnivel plus">
          </div>
          <div [ngClass]="{'brabelavel': subSubCategoria.desplegado}" class="comunnivel subcategoriadecat">
            {{subSubCategoria.nombre}}
          </div>
          <div class="comunnivel pronner">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
