import { SafeUrl } from "@angular/platform-browser";
import { OfertasEcommerce } from "../interfaces/ofertas-ecommerce.interface";
import { TagsEcommerce } from "../interfaces/tags-eccomerce.interface";
import { PromocionesEccomerce } from "./promociones-class";

export abstract class ArticulosGeneral {
    constructor(public marca: number, public codigo: number, public marcareal: number) {
    }

    getCodigo() {
        return this.codigo;
    }

    getMarca() {
        return this.marca;
    }

    abstract deOferta: OfertasEcommerce;
    abstract isdeoferta: boolean;
    abstract isdePromo: boolean;
    abstract misPromo: PromocionesEccomerce[];

    abstract setTag(tag: TagsEcommerce);
    abstract getTag(): TagsEcommerce[];
    abstract operacionesExtras();
    abstract getImgDownload(): boolean;
    abstract setImgDownload(valor: boolean);
    abstract setMyBlob(body: Blob)
    abstract getMyBlob(): Blob;
    abstract getDescripCion();
    abstract getFotoImagen();
    abstract getCantidad();
    abstract setModificacionPrecio(arg0: number);
    abstract getRemplazo();
    abstract getRemplazos();
    abstract setRemplazo(numero: number);
    abstract setFoto(arg0: SafeUrl);
    abstract getPrecio();
    abstract setdeOferta(ofertas: OfertasEcommerce)
    abstract isdeOferta(): boolean;
    abstract getOtrosPrecios(): string;
    abstract getPrecioPartido(): number;
    abstract getPrecioCalculado(): number;
    abstract getPrecioOferta(): number;
    abstract getPresentacion();
    abstract getNombreMarca();
    abstract getMarcaReal();
    abstract sumarCantPromo(arg0: number);
    abstract agregarCantidad(numero: number);
    abstract setCantidad(arg0: number);
    abstract getPrecioNormal();
    abstract getPrecioMayorista();
    abstract forzarAddPromo(v: number);
    abstract getTipoPromo();
    abstract getTipo();
    abstract setCantidadInicial(v: number);
    abstract getCantidadInicial();
    abstract getPrecioAmostrar();
    abstract getPromos();
    abstract setPromo(k: PromocionesEccomerce);
    abstract getPromosActivas(): string[];
    abstract getDescripcionAdicional();
    abstract getCantidadMaxima();
    abstract getCantidadMayorista();
    abstract getFraccion();
}
