import { OfertasEcommerce } from "../interfaces/ofertas-ecommerce.interface";
import { TagsEcommerce } from "../interfaces/tags-eccomerce.interface";
import { PromocionesEccomerce } from "./promociones-class";

export class PorcentajePromo extends PromocionesEccomerce {

    setTag(tag: TagsEcommerce) {
        throw new Error("Method not implemented.");
    }

    getTag(): TagsEcommerce[] {
        throw new Error("Method not implemented.");
    }

    getMarcaReal() {
        return '';
    }

    getCantidad() {
        return this.counter;
    }

    getCantidadMayorista() {
        return 0;
    }

    getRemplazos() {
        throw new Error("Method not implemented.");
    }

    isdeOferta(): boolean {
        return false;
    }

    setdeOferta(ofertas: OfertasEcommerce) {
        throw new Error("Method not implemented.");
    }

    getPromosActivas(): string[] {
        return [];
    }

    getDescripcionAdicional() {
        return ' ';
    }

    getCantidadMaxima() {
        return 0;
    }

    getDescontadoTotal(): number {
        return this.descontado
    }

    getDescontado(): number {
        return this.descontado / this.counter;
    }

    getPrecioNormal() {
        return 0
    }

    getPrecioMayorista() {
        return 0;
    }

    getPrecioAmostrar() {
        return this.getPrecioCalculado();
    }

    getPresentacion() {
        return 'presentacion';
    }

    setModificacionPrecio(arg0: number) {
        throw new Error("Method not implemented.");
    }

    preconPromo(precio: number, cantidad: number, modificacionActual: number) {
        let olleg = Math.round((cantidad * this.porcentaje) / 100) * precio;
        this.descontado += olleg;
        return -olleg;
    }

    getPrecioCalculado(): number {
        if (this.articuloenPromo() !== undefined) {

            if (this.articuloenPromo().getCantidad() != 0) {
                let precioArticulo;
                if (this.articulo1.isdeOferta() == true) {
                    precioArticulo = this.articuloenPromo().getPrecioOferta() - (this.articuloenPromo().getPrecioOferta() * this.porcentaje) / 100;
                } else {
                    precioArticulo = this.articuloenPromo().getPrecio() - (this.articuloenPromo().getPrecio() * this.porcentaje) / 100;
                }

                let montoPorcentaje = precioArticulo * this.articuloenPromo().getCantidad();

                return montoPorcentaje;
            } else {
                if (this.articulo1.isdeOferta() == true) {
                    return (this.articuloenPromo().getPrecioOferta() - (this.articuloenPromo().getPrecioOferta() * this.porcentaje) / 100) * this.cantidadenPromo();
                } else {
                    return (this.articuloenPromo().getPrecio() - (this.articuloenPromo().getPrecio() * this.porcentaje) / 100) * this.cantidadenPromo();
                }
            }

        }
        else {
            return 0;
        }
    }

    getPrecioOferta(): number {
        return this.getPrecioOferta();
    }

    operacionesExtras() {
        // console.log('se ejecuto PorcentajePromo');
        // console.log(this);
    }

    setMyBlob(body: Blob) {
        this.myblob = body;
    }

    getMyBlob(): Blob {
        return this.myblob;
    }

    setRemplazo(numero: number) {
        throw new Error("Method not implemented.");
    }

    getPrecioPartido(): number {
        throw new Error("Method not implemented.");
    }

    getOtrosPrecios(): string {
        throw new Error("Method not implemented.");
    }

    getRemplazo() {
        throw new Error("Method not implemented.");
    }

    getPrecio() {
        return this.getPrecioCalculado() / this.articuloenPromo().getCantidad();
    }

    agregarCantidad(numero: number) {
        if (this.esDoblePromo()) {
            this.articulo1.agregarCantidad(numero * this.cantidadenPromo())
            this.articulo2.agregarCantidad(numero * this.cantidadenPromo())
        }
        else {
            this.articuloenPromo().agregarCantidad(numero * this.cantidadenPromo())
            this.articuloenPromo().operacionesExtras();
        }
    }

    setCantidad(arg0: number) {
        console.log('seteo tanta cantidad ' + arg0);
    }

    hacerCalculos() {
        return 0;
    }

    getImgDownload(): boolean {
        return this.descargo;
    }

    setImgDownload(valor: boolean) {
        this.descargo = valor;
    }

    setPromo(k: PromocionesEccomerce) {
        throw new Error("Method not implemented.");
    }

    getFraccion() {
      return 0
    }

    aplicarPromocion(precio: number) {
      return precio * ( (100 - this.porcentaje) / 100)
    }

    deOferta: OfertasEcommerce;
    isdeoferta: boolean;
    isdePromo: boolean;
    misPromo: PromocionesEccomerce[];

    private myblob: Blob;
}
