<div *ngIf="modo==='normal'" class="containerSearch">
  <div class="containerCateg" [ngStyle]="{display: toggleSideBar ? 'none' : 'block'}" >
    <div class="categoriascontainer">
      <div class="menuses">
        <div class="loginMenu">
          <div (click)="openLogin()" onclick="window.scrollTo(0,0)" [ngStyle]="{'display': login}" class="prediccion">
            <div class="login">
              Iniciar Sesión
            </div>
          </div>
          <div *ngFor="let menu of menues" (click)="gotoURL(menu.url)" onclick="window.scrollTo(0,0)"
            [ngStyle]="{'display': myData}" class="prediccion">
            <div class="iconocont">
              <img [src]="menu.icourl" class="icomobile" [alt]="menu.nombre">
              <div class="descrip">
                {{menu.nombre}}
              </div>
            </div>
          </div>
          <div class="logout" (click)="cerrarSesion()" *ngIf="isLogued == true">
            <div class="login">
              Cerrar Sesión
            </div>
          </div>
        </div>

        <div *ngFor="let menus of menus" (click)="menuclick(menus)" onclick="window.scrollTo(0,0)" class="menu">
          <img class="imgMenus" src="../../assets/icons/check (1).svg" alt="IMG">
          <p class="txtMenus">
            {{menus.nombre || 'No Disponible'}}
          </p>
        </div>

      </div>

      <div class="categMenus">
        <categ-viewgeneric *ngFor="let catego of categorias" [categoria]="catego" [mode]="modo" (categoriasEvento)="reiniciarFiltroCategoria($event)">
        </categ-viewgeneric>
      </div>
    </div>
  </div>

  <div class="container">
    <lista-productos-view [articulosIn]="resultadosDeLaBusqueda">

    </lista-productos-view>
  </div>
</div>
<mmax-helpfootbar></mmax-helpfootbar>

<foo-mariano-max></foo-mariano-max>
