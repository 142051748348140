import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { EcommerceGenericoService, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ciudad } from 'projects/ecommerce-generico/src/lib/interfaces/ciudades-envío.interface';

@Component({
  selector: 'mis-datos-mmax',
  templateUrl: './misdatosmmax.component.html',
  styleUrls: ['./misdatosmmax.component.scss']
})

export class MisdatosmmaxComponent implements OnInit {
  @Input('mode') modo: string;

  whatsappsrc: string;
  correosrc: string;
  user: Usuario;
  registroHab: string;
  beneficios: string;
  formularioDatosUsuario : FormGroup;
  habilitarCambiarDatos : boolean = false;
  guardadoExitoso : boolean = false;
  guardadoProblema : boolean = false;
  guardando : boolean = false;
  ciudadesHabilitadas : Ciudad[] = [];
  tieneCiudadElegida : boolean = false;
  constructor(private genServ : EcommerceGenericoService, private router: ActivatedRoute, private modal: ModalService, private formBuilder : FormBuilder) {
    this.whatsappsrc = '../../assets/icons/whatsapp-logo.svg';
    this.correosrc = '../../assets/icons/mail.svg';
    this.genServ.getUsuarioProvider().subscribe({
      next: data => {
        this.user = data;
        this.formularioDatosUsuario = formBuilder.group({
          nombre: [{
            value: this.user.nombre,
            disabled: !this.habilitarCambiarDatos},
            Validators.maxLength(20)
          ],
          dni: [{
            value: this.user.dni,
            disabled: !this.habilitarCambiarDatos},
            Validators.min(9999)
          ],
          direccion: [{
            value: this.user.direccion,
            disabled: !this.habilitarCambiarDatos},
            Validators.maxLength(100)
          ],
/*           codigopostal: [{
            value: this.user.codigopostal || '',
            disabled: !this.habilitarCambiarDatos}
          ], */
          telefonos: [{
            value: this.user.telefonos,
            disabled: !this.habilitarCambiarDatos},
          ],
          ciudad: [{
            value: this.user.ciudad,
            disabled: !this.habilitarCambiarDatos}
          ],
        })
      }
    });
    this.genServ.getRegistroHabilitado().subscribe(data => {
      this.registroHab = data;
    });
    this.genServ.returnBeneficios().subscribe(data => {
      this.beneficios = data;
    })
  }

  ngOnInit(): void {
    this.router.data.subscribe({
      next:data => {
        this.modo = data['mode'] || this.modo || 'normal';
      }
    });
    this.ciudadesHabilitadas = this.genServ.ciudadesHabilitadasParaEnvio.slice()
    this.ciudadesHabilitadas.forEach(
      ciudadHabilitada =>{
        if(ciudadHabilitada.ciudad === (this.genServ.getUser().ciudad)){
          this.tieneCiudadElegida = true;
        }
      }
    )

  }

  get nombre(){
    return this.formularioDatosUsuario.get('nombre')
  }

  get dni(){
    return this.formularioDatosUsuario.get('dni')
  }

  get direccion(){
    return this.formularioDatosUsuario.get('direccion')
  }


  get telefonos(){
    return this.formularioDatosUsuario.get('telefonos')
  }

  /*   get codigopostal(){
    return this.formularioDatosUsuario.get('codigopostal')
  } */

  get ciudad(){
    return this.formularioDatosUsuario.get('ciudad')
  }

  modificarDatos() {
    this.formularioDatosUsuario.get('nombre').enable()
    this.formularioDatosUsuario.get('dni').enable()
    this.formularioDatosUsuario.get('direccion').enable()
    //this.formularioDatosUsuario.get('codigopostal').enable()
    this.formularioDatosUsuario.get('ciudad').enable()
    this.formularioDatosUsuario.get('telefonos').enable()
    this.habilitarCambiarDatos = true;
    this.guardadoExitoso = false;
    this.guardadoProblema = false;
    //this.modal.open('formulario-mis-datos')
  }

  cancelarCambios(){
    this.formularioDatosUsuario.reset()
    this.formularioDatosUsuario.patchValue({
      nombre: this.user.nombre || '',
      dni: this.user.dni || '',
      direccion: this.user.direccion || '',
      telefonos: this.user.telefonos || '',
      ciudad: this.user.ciudad || ''
    });
    this.formularioDatosUsuario.get('nombre').disable()
    this.formularioDatosUsuario.get('dni').disable()
    this.formularioDatosUsuario.get('direccion').disable()
    //this.formularioDatosUsuario.get('codigopostal').disable()
    this.formularioDatosUsuario.get('telefonos').disable()
    this.formularioDatosUsuario.get('ciudad').disable()
    this.habilitarCambiarDatos = false;
  }

  guardarCambios(){
    this.guardando = true;
    console.log('guardar cambios usuario', this.formularioDatosUsuario.value);
    this.genServ.modificarDatosUsuario(this.formularioDatosUsuario.value).subscribe({
      next: respuestaExitosa =>{
        console.log('guardado Exitoso');
        this.habilitarCambiarDatos = false;
        this.guardadoExitoso = true;
        let nuevosDatosUsuario : Usuario = JSON.parse(JSON.stringify(this.genServ.getUser()));
        nuevosDatosUsuario.ciudad = parseInt(this.formularioDatosUsuario.get('ciudad').value);
        this.genServ.setUser(nuevosDatosUsuario);
        this.tieneCiudadElegida = true;
        setTimeout(() => {
          this.guardadoExitoso = false;
        }, 15000);
      },
      error: respuestaError =>{
        console.log('problema en guardado');
        this.guardadoProblema = true;
        setTimeout(() => {
          this.guardadoProblema = false;
        }, 15000);
      },
      complete: ()=>{
        this.guardando = false;
      }
    })

  }

  resetPass() {
    if (this.registroHab === 'S') {
      this.modal.open('send-reset-pass-saltearPedidoDeMail');
    }
    if (this.beneficios === 'S') {
      this.modal.open('restore-pass-modal');
    }
  }

}
