<div normal>
  <modal-default-mmaxapp>
    <div class="tarjetavaciar">
      <div class="titulo">
        Carrito de compras abierto detectado
      </div>
      <div class="leyendavaciar">
        Se detectó un carrito de compras abierto en una sesión anterior ¿Desea descartar el carrito previamente abierto o combinarlo con el carrito actual?
      </div>
      <div class="botonesCombinarCarrito">
        <button class="btnCombinarCarrito redbutton" (click)="descartarCarritoWeb()">
          Descartar carrito previo
        </button>
        <button class="btnCombinarCarrito redbutton" (click)="combinarCarritos()">
          Combinar ambos carritos
        </button>
      </div>
    </div>
  </modal-default-mmaxapp>
</div>
