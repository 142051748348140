import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'vaciar-mobile-mmax-modal',
  templateUrl: './vaciar-mobile-mmax-modal.component.html',
  styleUrls: ['./vaciar-mobile-mmax-modal.component.scss']
})

export class VaciarMobileMmaxModalComponent implements OnInit {

  constructor(private genServ: EcommerceGenericoService, private modal: ModalService) { }

  ngOnInit(): void {
  }

  vaciar() {
    this.modal.closeAll();
    this.genServ.resetEccomerce();
  }

  cancelar() {
    this.modal.closeAll();
  }
}
