import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { EcommerceGenericoService, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { GenericoSlideAnim } from '../etc/generico-animation';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';
import { MarianomGeneral } from '../marianom-general.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscribable } from 'rxjs';

@Component({
  selector: 'mmax-usuario-budget',
  templateUrl: './usuario-budget-mmax.component.html',
  styleUrls: ['./usuario-budget-mmax.component.scss'],
  animations: [
    GenericoSlideAnim
  ]
})

export class UsuarioBudgetMmaxComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() loader: string;
  @Input() login: string;

  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService,private modal: ModalService, private route: ActivatedRoute) {
    this.modo = this.modo || 'normal';
    this._destroyed$ = new Subject();

    this.usericon = {
      nombre: 'Método de Pago',
      url: 'metodosdpago',
      icourl: '../../assets/icons/misdatos.svg'
    }
    this.mobilhome = {
      nombre: 'home',
      url: '',
      urlmobile: '/app-mobile/home',
      icourl: '../../assets/icons/home.svg'
    }

    this.menus = [
      {
        nombre: 'Sucursales',
        url: '/sucursales',
        urlmobile: '/app-mobile/sucursal-change',
        icourl: '../../assets/icons/store.svg'
      },
      {
        nombre: 'Mis Datos',
        url: '/misdatos',
        urlmobile: '/app-mobile/app/account',
        icourl: '../../assets/icons/misdatos.svg'
      },
      {
        nombre: 'Método de Pago',
        // url: '/metodosdpago',
        icourl: '../../assets/icons/credit-card-outline-white.svg',
        function: 'notImplemented'
      },
      {
        nombre: 'Carrito de Compras',
        url: '/resumendecompra',
        urlmobile: '/app-mobile/cart',
        icourl: '../../assets/icons/cart-arrow-down-white.svg'
      },
      {
        nombre: 'Ayuda',
        url: '/ayudas',
        urlmobile: '/app-mobile/app/help',
        icourl: '../../assets/icons/help-circle-outline-white.svg'
      },
      {
        nombre: 'Salir',
        // url: '',
        urlmobile: 'app-mobile/app',
        icourl: '../../assets/icons/logout-variant-white.svg',
        function: 'salir'
      }
    ];

    this.genServ.returnBeneficios().pipe(takeUntil(this._destroyed$)).subscribe(data => {
      if (data === "S") {
        this.menus.push(
          {
            nombre: 'Beneficios',
            url: '/beneficios',
            icourl: '../../assets/icons/home.svg'
            // function: 'openBeneficios'
          })
      }
    });

    this.loader = this.loader || 'none';
    this.login = this.login || 'none';
    this.genServ.getUsuarioProvider().subscribe(data => {
      this.login = 'flex';
      this.loader = 'none';
      this.usuario = data;
    });
  }

  ngOnInit(): void {
    this.loader = 'block';

    if (this.loader === 'block') {
      this.login = 'none';
    }
  }

  salir() {
    this.modal.open('modal-salir')
  }

  notImplemented() {
    this.modal.open('modal-no-implemented')
  }

  openBeneficios() {
    window.open("https://beneficios.mmax.com.ar/", '_blank');
  }

  indexWindow(url) {
    if (url != undefined) {
      window.scrollTo(0, 0);
    }
  }

  gotoURL(url: string, nombre: string) {
    if (url === "/resumendecompra") {
      if (this.total === 0) {
        this.modal.open('modal-agregar-producto');
      } else {
        for (let i = 0; i < this.topMenus.length; i++) {
          const element = this.topMenus[i];
          element.activo = false;
        }

        this.serv.gotoURL(url, this.route);
      }
    }
    else {
      for (let i = 0; i < this.topMenus.length; i++) {
        const element = this.topMenus[i];

        if (nombre === element.nombre) {
          this.serv.activatethis(element);
        } else {
          element.activo = false;
        }
      }

      if (url != undefined) {
        this.serv.gotoURL(url, this.route);
      }
    }

  }

  closebudget() {
    this.serv.desplegaryCerrarusu(!this.desplegado);
  }

  ejecutar(funcion) {
    if (funcion != undefined) {
      this[funcion]();
    }

  }

  volverAtras() {
    this.serv.navigator.goLandPage(this.route);
  }

  volverAtrasMobile() {
    this.serv.navigator.goLandPage(this.route);
  }

  startLoginMobile() {
    this.serv.gotoURL('login', this.route);
  }

  loginStart() {
    this.modal.open('login-modal-1');
  }

  get nombreSucursal() {
    let sucursales = this.genServ.getSucursales();
    let sucTrue = false;
    for (let i = 0; i < sucursales.length; i++) {
      const element = sucursales[i];
      if (element.sucursal == this.usuario.sucursal) {

        sucTrue = true;
      }
    }
    if (sucTrue == false) {
      return (" Sin Definir");
    }
    else {
      return this.usuario.sucursalNombre || ' Sin Definir'
    }
  }

  get desplegado() {
    return this.serv.desplegadousa;
  }

  get topMenus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  get total() {
    return this.genServ.getCantidadTotal();
  }

  menus: UsuariosBudgetMenu[];
  mobilhome: UsuariosBudgetMenu;
  usericon: UsuariosBudgetMenu;
  usuario: Usuario;
  _destroyed$: Subject<any>;
}

export interface UsuariosBudgetMenu {
  nombre: string,
  url?: string,
  urlmobile?: string,
  urltablet?: string,
  icourl?: string,
  function?: string,
}
