<!-- no es promo combo -->
<div *ngIf="!promocion.getregla2()" class="ofertacard">
  <div class="contenidoofert">
    <div style="display: flex; width: 100%;">
      <div class="fondoLogo" >
        <div class="oferlogo">
          {{ promocion.getAbreviaturaPromocion() || promocion.getNombrePromocion() }}
        </div>
      </div>
      <div class="ofertext">
        {{ promocion.getNombrePromocion() }}
      </div>
    </div>
    <div class="imagepr">
      <img class="img-pr" *ngIf="promocion.getFotoImagen()" [src]="promocion.getFotoImagen()" loading="lazy"
        [alt]="promocion.getNombrePromocion()">
    </div>
    <div *ngIf="promocion.getArticulo1()" class="descriofer">
      <div class="descprod">
        {{ promocion.getArticulo1().getDescripCion() || '' }}
      </div>
      <div class="cantidadofer">
        X {{ promocion.getCantidad1()  }} un
      </div>
      <div [ngSwitch]="tipoPromocion" class="precie">
        <div *ngSwitchCase="'Porcentaje'">
          {{ promocion.getPorcentaje()  }} % de descuento
          <div class="precioOriginalTachado">
            {{ promocion.getArticulo1().getPrecio() * promocion.getCantidad1() | currency }}
          </div>
          <div>
            {{ promocion.aplicarPromocion(promocion.getArticulo1().getPrecio() * promocion.getCantidad1()) | currency}}
          </div>
        </div>
        <div *ngSwitchCase="'MontoPromo'">
          Todo por {{ promocion.getMontoPromocion() | currency }}
          <div class="precioOriginalTachado">
            {{ promocion.getArticulo1().getPrecio() * promocion.getCantidad1() | currency }}
          </div>
          <div>
            {{ promocion.aplicarPromocion(promocion.getArticulo1().getPrecio() * promocion.getCantidad1()) | currency}}
          </div>
        </div>
        <div *ngSwitchCase="'DiferenciaPromo'">
          Descuento de {{ promocion.getMontoPromocion() |currency }}
          <div class="precioOriginalTachado">
            {{ promocion.getArticulo1().getPrecio() * promocion.getCantidad1() | currency }}
          </div>
          <div>
            {{ promocion.aplicarPromocion(promocion.getArticulo1().getPrecio() * promocion.getCantidad1()) | currency}}
          </div>
        </div>
        <div *ngSwitchDefault>
          Promo
        </div>
      </div>
      <div class="btnarena">
        <boton-sumador style="margin: auto;" [fraccion]="0" [unidadMedida]="''"(quantyEvent)="agregarACarrito($event)" [contador]="cantidadPromocion">
        </boton-sumador>
      </div>
  </div>
</div>
<!-- es promo combo -->
<div *ngIf="promocion.getregla2()" class="ofertacard">
  <div class="contenidoofert">
    <div class="fondoLogo" >
      <div class="oferlogo">
        {{ promocion.getAbreviaturaPromocion() || promocion.getNombrePromocion() }}
      </div>
    </div>
    <div class="ofertext">
      {{ promocion.getNombrePromocion() }}
    </div>
    <div class="imagepr">
      <img class="img-pr" *ngIf="promocion.getFotoImagen()" [src]="promocion.getFotoImagen()" loading="lazy"
        [alt]="promocion.getNombrePromocion()">
    </div>
    <div class="descriofer">
      <div class="descprod">
      </div>
      <div class="cantidadofer">
      </div>
      <div class="precie">
        {{ promocion.getMontoPromocion() | currency}} {{ promocion.getPorcentaje() | currency }}
      </div>
      <div class="btnarena">
<!--         <boton-sumador [fraccion]="articulo.getFraccion()" [unidadMedida]="articulo.getUnidadMedida()"(quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()">
        </boton-sumador> -->
      </div>
  </div>
</div>


