import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { ArticulosGeneral, EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../../app-shared/marianom-general.service';

@Component({
  selector: 'mmax-general',
  templateUrl: './mmax-general.component.html',
  styleUrls: ['./mmax-general.component.scss']
})
export class MmaxGeneralComponent {

  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, location: LocationStrategy) {
    location.onPopState(() => {
      if (window.location.hash === '#/app-loading') {
        window.location.hash = "#/app"
        window.location.reload();
      }
    });
  }

  ngOnInit(): void {
    this.disableBack();
  }

  disableBack() {
    window.onhashchange = function () {
      if (window.location.hash === "#/checkout/finalizar" || window.location.hash === "#/checkout/finalizado") {
        window.location.hash = "#/";
      }
    }
  }

  get ArticuloAmostrar(): ArticulosGeneral {
    return this.serv.getArticuloEnmodal();
  }

  closeExtras() {
    //console.log('closeextras')
    this.serv.cerrarUI();
  }



  get presentacion() {
    if (this.ArticuloAmostrar != undefined) {
      return this.ArticuloAmostrar.getDescripCion();
    }
    else {
      return ''
    }

  }

  get categorias() {
    return this.genServ.getCategoriasWeb();
  }

  get imagesrc() {
    if (this.ArticuloAmostrar != undefined) {
      return this.ArticuloAmostrar.getFotoImagen();
    }
    else {
      return ''
    }
  }

  get cantidad(): number {

    if (this.ArticuloAmostrar != undefined) {
      return this.ArticuloAmostrar.getCantidad();
    }
    else {
      return 0
    }
  }

}
