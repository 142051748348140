import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EcommerceGenericoService, SucursalEcommerce, TurnosRetiroEccomerce, Usuario } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';
import { PedidosMmaxEccomerce } from 'projects/ecommerce-generico/src/public-api';
import { ModalService } from 'projects/app-shared/src/public-api';
import { DatosEnvio } from 'projects/ecommerce-generico/src/lib/interfaces/envios.interface';

@Component({
  selector: 'app-mmax-checkout-compra',
  templateUrl: './mmax-checkout-compra.component.html',
  styleUrls: ['./mmax-checkout-compra.component.scss']
})

export class MmaxCheckoutCompraComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() envioHab: string;

  observacion: string;
  turnoselecionado: TurnosRetiroEccomerce;
  user: Usuario;
  sucusarl: SucursalEcommerce;
  desplegado: boolean;
  envioHabilitado: string;
  envio : DatosEnvio;
  pagoDiferido: string;
  isOn1: boolean;
  isOn2: boolean;
  modoEnvio : number;
  usaTurnos: string;
  horasMinimasTurno: string;
  sucTrue: boolean;
  displayEnvio : boolean = false;
  displayRetiro : boolean = false;
  displayEnvioExpress : boolean = false;
  modoEnvioAnterior: number;
  terminosyCondiciones:string;
  observacionEnvio:string;
  envioExpress:boolean = false;

  constructor(private router: ActivatedRoute, private serv: MarianomGeneral, private modal: ModalService, private genServ : EcommerceGenericoService, private cdr: ChangeDetectorRef) {
    this.modo = this.modo || 'normal';
    this.envioHab = this.envioHab || 'none';
    this.desplegado = false;
    this.sucTrue = true;
    this.modoEnvioAnterior = -1;
    this.terminosyCondiciones = genServ.getTerminosCondiciones();
    this.envioExpress = genServ.getEnvioExpress();
    console.log("Terminos y comunicaciones", this.terminosyCondiciones);

    this.observacionEnvio = genServ.getObservacionesEnvios();
    this.genServ.getUsuarioProvider().subscribe(data => {
      this.user = data;
    });
    this.genServ.getActualSucursal().subscribe(sucs => {
      this.genServ.getUsuarioProvider().subscribe(data => {
        if (data.sucursal && sucs.sucursal) {
          this.sucusarl = sucs;
        } else {
          this.sucTrue = false;
        }
      })
    });
    this.genServ.getEnvioHabilitado().subscribe(data => {
      this.envioHabilitado = data
      if (this.envioHabilitado === "S") {
        this.envioHab = 'flex';
      }
      else {
        this.envioHab = 'none';
      }
    });
    this.genServ.getPagoDiferido().subscribe(data => {
      this.pagoDiferido = data
    });
    this.genServ.returnUsaTurnos().subscribe(data => {
      this.usaTurnos = data;
    });
    this.genServ.returnHorasMinimasTurno().subscribe(data => {
      this.horasMinimasTurno = data;
    })
  }

  ngOnInit(): void {
    this.observacion = undefined;
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';
    });

  }

  get articulosconcantidad() {
    return this.genServ.getCarrito();
  }

  get totales() {
    return this.genServ.getTotal();
  }

  get total() {
    return this.genServ.getCantidadTotal();
  }

  setRetiroEnvio(modo: number) {
    if (this.modoEnvioAnterior !== modo) {
      this.modoEnvioAnterior = modo;
      this.displayEnvio = false;
      this.displayEnvioExpress = false;
      this.displayRetiro = false;

      setTimeout(() => {
        this.modoEnvio = modo;
        this.serv.setRetiroEnvio(modo);

        if (modo === 1) {
          this.displayEnvio = true;
        } else if (modo === 2) {
          this.displayEnvioExpress = true;
        } else {
          this.displayRetiro = true;
        }
      }, 0);
    }
  }

  turnoselect(turnoselect) {
    this.turnoselecionado = turnoselect;
    this.serv.setTurno(this.turnoselecionado);
  }

  setupExclusiveCheckboxes(checkbox1Id: string, checkbox2Id: string, checkbox3Id: string) {
    const checkbox1 = document.getElementById(checkbox1Id) as HTMLInputElement;
    const checkbox2 = document.getElementById(checkbox2Id) as HTMLInputElement;
    const checkbox3 = document.getElementById(checkbox3Id) as HTMLInputElement;

    checkbox1.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement;
      if (this.isOn1) {
        checkbox1.checked = true;
      }
      if (target.checked) {
        checkbox2.checked = false;
        checkbox3.checked = false;
        this.isOn2 = false;
        this.isOn1 = true;
      }
    });

    checkbox2.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement;
      if (this.isOn2) {
        checkbox2.checked = true;
      }
      if (target.checked) {
        checkbox1.checked = false;
        checkbox3.checked = false;
        this.isOn1 = false;
        this.isOn2 = true;
      }
    });

    checkbox3.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement;
      if (!this.isOn1 && !this.isOn2) {
        checkbox3.checked = true;
      }
      if (target.checked) {
        checkbox1.checked = false;
        checkbox2.checked = false;
        this.isOn1 = false;
        this.isOn2 = false;
      }
    });

  }

  seleccionoEnvio () {
    this.displayRetiro = false;
    this.displayEnvio = true;
    this.displayEnvioExpress = false;
    this.genServ.setDatosEnvio(undefined);
    this.setupExclusiveCheckboxes('displayEnvio', 'displayRetiro', 'displayEnvioExpress');
    this.modoEnvio = 1;
    this.cdr.detectChanges();
  }

  seleccionoEnvioExpress () {
    this.displayRetiro = false;
    this.displayEnvio = false;
    this.displayEnvioExpress = true;
    this.genServ.setDatosEnvio(undefined);
    this.setupExclusiveCheckboxes('displayEnvio', 'displayRetiro', 'displayEnvioExpress');
    this.modoEnvio = 2;
    this.cdr.detectChanges();
  }

  seleccionoRetiro() {
    this.displayEnvio = false;
    this.displayRetiro = true;
    this.displayEnvioExpress = false;
    this.setupExclusiveCheckboxes('displayEnvio', 'displayRetiro', 'displayEnvioExpress');
    this.genServ.setDatosEnvio(undefined);
  }

  abrirTurnos() {
    this.serv.abrirTurnos()
  }

  seEligioDatosEnvio( datosEnvio : DatosEnvio){
    this.envio = datosEnvio;
    this.genServ.setDatosEnvio(datosEnvio);
  }

  observaCion() {
    this.observacion = (<HTMLInputElement>document.getElementById("obs")).value;
    this.genServ.setObservacion(this.observacion);
  }

  cerrarPedidoMobile() {
    this.observaCion();
    this.serv.setTurno(this.turnoselecionado);
    this.serv.navigator.cierreCheckout(this.router)
  }

  iraLand() {
    this.genServ.setDatosEnvio(undefined);
    this.serv.navigator.irResumenCompra(this.router)
    this.serv.cerrarUI();
  }

  modalCheckout() {
    this.modal.open('confirmar-checkout-modal-1');
  }

  irASuc() {
    this.serv.gotoURL('sucursales', this.router)
  }
}
