<div normal>
    <modal-default-mmaxapp>
            <div class="tarjetaconfirmar">
                <div class="titulo">
                        Como hacer una Compra Online
                </div>
                <div class="leyendaconfirmar">
                        {{compraOnline}}
                </div>
                <div class="botones">
                        <button class="buttonpc whibutton" (click)="aceptar()">Aceptar</button>
                </div>
            </div>
    </modal-default-mmaxapp>
</div>