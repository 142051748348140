<div normal>
  <modal-default-mmaxapp>
    <div class="tarjetaconfirmar">
      <div class="cerrar" (click)="closeModal()">
        X
      </div>
      <div class="anotherbox" *ngIf="articulo !== undefined">
        <div class="boxphone">
          <div class="ofertareal" *ngIf='articulo.isdeOferta()'>
            <div class="labeloferta">
              Oferta.
            </div>
          </div>
          <div class="ofertareal" *ngIf='articulo.misPromo.length !== 0'>
            <div class="labeloferta">
              {{articulo.getPromos()[0].getAbreviaturaPromocion() || articulo.getPromos()[0].getNombrePromocion()  }}
            </div>
          </div>
          <div class="imag">
            <img *ngIf="articulo.getImgDownload()" class="imasrc" id="imagsrc" [src]="articulo.getFotoImagen()"
              loading="lazy" [alt]="articulo.getNombreMarca()">
          </div>
          <div class="anotherandanother">
            <div class="nombreMarca" >
              {{ articulo.getNombreMarca() }}
            </div>
            <div class="mindesc">
              {{ articulo.getDescripCion() }}
            </div>
            <div class="tittle">
              {{ articulo.getPresentacion() }}
            </div>
            <div class="slimorden" *ngIf='articulo.getCantidadMayorista() > 0'>
              <div class="oferta-may">
                <div>Llevando {{articulo.getCantidadMayorista()}} o más </div>
                <div style="font-weight: bold; font-size: 30px">${{articulo.getPrecioMayorista()}}</div>
                <div> c/u</div>
              </div>
            </div>
            <!-- <span class="tachado" *ngIf='articulo.misPromo.length !== 0'>{{articulo.getPrecio() | currency}}</span> -->
            <span class="tachado"
              *ngIf='articulo.isdeOferta() || articulo.getCantidadMayorista() > 0 && articulo.getCantidad() >= articulo.getCantidadMayorista()'>{{articulo.getPrecio()
              | currency}}</span>
            <div class="precio">
              <div class="preciogrande" *ngIf='articulo.isdeOferta() && articulo.misPromo.length !== 0'>
                {{articulo.getPrecioOferta() | currency}}
              </div>
              <div class="preciogrande" *ngIf='articulo.isdeOferta() && articulo.misPromo.length === 0'>
                {{articulo.getPrecioOferta() | currency}}
              </div>
              <div class="preciogrande" *ngIf='articulo.isdeOferta() === false && articulo.misPromo.length !== 0'>
                {{articulo.getPrecioAmostrar() | currency}}
              </div>
              <div class="preciogrande"
                *ngIf='articulo.isdeOferta() === false && articulo.misPromo.length === 0 && articulo.getCantidadMayorista() > 0 && articulo.getCantidad() >= articulo.getCantidadMayorista()'>
                {{articulo.getPrecioMayorista() | currency}}
              </div>
              <div class="preciogrande"
                *ngIf='articulo.isdeOferta() === false && articulo.misPromo.length === 0 && articulo.getCantidad() < articulo.getCantidadMayorista() || articulo.isdeOferta() === false && articulo.misPromo.length === 0 && articulo.getCantidadMayorista() == 0'>
                {{articulo.getPrecio() | currency}}
              </div>
              <div class="porcantidad">
                x un
              </div>
            </div>
            <div class="preciochico" *ngIf="articulo.getPrecioPartido() != -1">
              {{articulo.getPrecioPartido() | currency}} {{articulo.getOtrosPrecios()}}
            </div>
          </div>
        </div>
        <boton-sumador class="botonSumador" [fraccion]="articulo.getFraccion()" [unidadMedida]="articulo.getUnidadMedida()"
          (quantyEvent)="sumaevent($event)" [contador]="articulo.getCantidad()">
        </boton-sumador>

      </div>
    </div>
  </modal-default-mmaxapp>
</div>
