<div normal>
        <modal-default-mmaxapp>
                <div class="tarjetaconfirmar">
                        <div class="titulo">
                                Límite de Producto!
                        </div>
                        <div class="leyendaconfirmar">
                                Se alcanzó la cantidad máxima de este producto en esta compra.
                        </div>
                        <div class="botones">
                                <button class="buttonpc whibutton" (click)="aceptar()">Aceptar</button>
                        </div>
                </div>
        </modal-default-mmaxapp>
</div>