<div class="container">

    <div class="atras">
        <img class="imageico" (click)="atraspagina()" onclick="window.scrollTo(0,0)" [src]="arrowleft">
    </div>
    <div class="numeros">
        <div *ngFor="let pagina of mispaginas" [ngClass]="pagina.activa ? '':'numerofadded'" class="numero noselect"
            (click)="activarPagina(pagina)" onclick="window.scrollTo(0,0)">{{pagina.nombre}}</div>
    </div>
    <div class="atras">
        <img class="imageico" (click)="adelantarpagina()" onclick="window.scrollTo(0,0)" [src]="arrowright">
    </div>
</div>