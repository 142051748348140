<div class="tarjetabackground">
  <div class="tarjeta-body">

    <ng-content>

    </ng-content>

  </div>
  <div class="background" (click)="close()"></div>
</div>
