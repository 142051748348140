
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GenericoSlideAnim } from '../etc/generico-animation';
import { CheckboxCategorias, CheckboxSubcategoria } from '../etc/categoria-checkbox.interface'
import { RemplazosMMax } from 'projects/ecommerce-generico/src/public-api';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'selector-generico',
  templateUrl: './myselector-generico.component.html',
  styleUrls: ['./myselector-generico.component.scss'],
  animations: [GenericoSlideAnim]
})

export class MyselectorGenericoComponent implements OnInit, OnChanges {
  @Input() catego: CheckboxCategorias;
  @Input() remplazos: RemplazosMMax[];
  @Input() seleRem: number;
  @Input() mode: string;
  @Input() defaultSelect : string;
  @Output() onSelect: EventEmitter<CheckboxSubcategoria>;

  desplegado: boolean
  nombre: string;
  constructor(private serv: MarianomGeneral) {
    this.mode = this.mode || 'normal'
    this.desplegado = this.desplegado || false;
    this.catego = this.catego || { nombre: '', categorias: [] };
    this.onSelect = new EventEmitter();
  }

  ngOnInit(): void {
    if (this.defaultSelect != undefined) {
      console.log('selector generico',this.defaultSelect)
      this.nombre = this.defaultSelect;
      //this.serv.getSelectorName(undefined);
    } else {
      this.nombre = this.catego.nombre
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let mival = changes?.catego?.currentValue;
    if (mival != undefined) {
      this.selecionar(mival);
    }
  }

  private selecionar(index: number) {
    if (this.remplazos != undefined) {
      this.remplazos.forEach(
        reemplazo => {
          console.log(reemplazo);
          if (reemplazo.modo.toString() === index.toString()) {
            this.desplegado = false;
            this.seleRem = index;
          }
        }
      )
    }
  }

  onclik(subcate: CheckboxSubcategoria) {
    this.desplegado = false;
    this.nombre = subcate.nombre;
    this.serv.setSelectorName(this.nombre);
    this.onSelect.emit(subcate);
  }

  onRemplazSelect(event: RemplazosMMax) {
    this.selecionar(Number.parseInt(event.modo));
  }

  desplegar() {
    this.desplegado = !this.desplegado;
  }

  get selecionado() {
    return this.nombre;
  }

}
