import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mmax-catalogo',
  templateUrl: './mmax-catalogo.component.html',
  styleUrls: ['./mmax-catalogo.component.scss']
})
export class MmaxCatalogoComponent implements OnInit {
  @Input('mode') modo: string;

  constructor(private router: ActivatedRoute) {
    this.modo = this.modo || 'normal';
    this.icocatalogo = '../../assets/icons/book-open-page-variant.svg';
    this.slide1 = '../../assets/misc/Catálogo1.png';
    this.slide2 = '../../assets/misc/Catálogo2.png';
    this.slide3 = '../../assets/misc/cajanavidad.png';
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    });
    new Image().src = this.icocatalogo;

  }
  
  slide1: string;
  slide2: string;
  icocatalogo: string;
  slide3: string
}
