import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../marianom-general.service';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'modal-articulo',
  templateUrl: './modal-articulo.component.html',
  styleUrls: ['./modal-articulo.component.scss']
})

export class ModalArticuloComponent implements OnInit {

  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private modal: ModalService) {
    this.articuloOpened;
  }

  ngOnInit(): void {
  }

  get articulo() {
    this.articuloOpened = this.genServ.returnArtOpened();
    return this.articuloOpened;
  }

  sumaevent(numero: number) {
    if (numero > 0) {

      if (this.articulo.getCantidad() == 0) {
        if (!this.serv.isloged()) {
          this.serv.abrirModalLogin();
        }
        else {
          this.articulo.agregarCantidad(numero);
          this.serv.articuloAmostrar(this.articulo);
        }
      }
      else {

        if (this.articulo.getCantidadMaxima() > this.articulo.getCantidad() || numero < 0 || this.articulo.getCantidadMaxima() == 0 || this.articulo.getCantidadMaxima() > numero && numero != 1) {

          if (this.articulo.getCantidadMaxima() == 0 || this.articulo.getCantidadMaxima() > numero) {
            this.articulo.agregarCantidad(numero);
            this.serv.articuloAmostrar(this.articulo);

          } else {
            this.modal.open('modal-max-art');
          }

        } else {
          this.modal.open('modal-max-art');
        }
      }
    }
    else {

      if (this.articulo.getCantidadMaxima() > this.articulo.getCantidad() || numero < 0 || this.articulo.getCantidadMaxima() == 0 || this.articulo.getCantidadMaxima() > numero && numero != 1) {

        if ((this.articulo.getCantidad() + numero) >= 0 || this.articulo.getCantidadMaxima() > numero) {
          this.articulo.agregarCantidad(numero);
        } else {
          this.modal.open('modal-max-art');
        }

      } else {
        this.modal.open('modal-max-art');
      }

    }
    this.genServ.sincronizarCarritoFrontyBack();
    this.articulo.operacionesExtras();

  }

  closeModal() {
    this.modal.closeAll();
  }

  articuloOpened: any;

}
