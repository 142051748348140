import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { MarianomGeneral } from '../../app-shared/marianom-general.service';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'app-help-bar-mmax',
  templateUrl: './help-bar-mmax.component.html',
  styleUrls: ['./help-bar-mmax.component.scss']
})
export class HelpBarMmaxComponent implements OnInit {

  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService,private router: ActivatedRoute, private modal: ModalService) {
    this.ruta = window.location.hash;
  }

  ngOnInit(): void { }
  irAyuda() {
    this.ruta = window.location.hash;

    if (this.ruta === '#/checkout/finalizar') {
      this.modal.open('modal-completar-pago');
    } else {
      this.serv.gotoURL('checkout/ayuda', this.router);
    }
  }
  volver() {
    this.serv.navigator.goLandPage(this.router);
    this.serv.setMenuActivoNombre('landing');

  }

  get logosrc() {
    return this.genServ.getLogo();
  }

  iraLand() {
    this.ruta = window.location.hash;
    if (this.ruta === '#/checkout/finalizar') {
      this.modal.open('modal-completar-pago');

    } else {
      this.serv.navigator.goLandPage(this.router);
      this.serv.setMenuActivoNombre('landing');

      this.serv.cerrarUI();
    }
  }


  ruta: string;
}
