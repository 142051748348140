<div *ngIf="modo==='normal'">
  <section>
    <div class="contendortablas">

      <div class="tablas">
        <div class="tabla noselect">
          <div style="height:20px" class="header">
            Institucional
          </div>
          <div *ngIf="data_Empresa">
            <div class="sub" (click)="goToInfo('modal-data-empresa')">
              Nuestra Empresa
            </div>
          </div>
          <div *ngIf="work_With_As">
            <div class="sub" (click)="goToInfo('modal-work-withAs')">
              Trabajá con nosotros
            </div>
          </div>
          <div *ngIf="info_Prov">
            <div class="sub" (click)="goToInfo('modal-info-prov')">
              Proveedores
            </div>
          </div>

          <div *ngIf="terminos_condiciones" class="sub" >
            <a [href]="terminos_condiciones" class="sub" target="_blank">Términos y Condiciones</a>
          </div>
          <div *ngIf="informacion_envio" class="sub">
            <a [href]="informacion_envio" class="sub" target="_blank">Información de Envíos</a>
          </div>
          <div *ngIf="politica_devoluciones" class="sub">
            <a [href]="politica_devoluciones" class="sub" target="_blank">Políticas de devoluciones</a>
          </div>

        </div>
      </div>
    </div>
    <div class="socials">
      <div class="header centrarTituloEnMobile">
        Necesitas Ayuda?
        <div>
          {{telefonoAtencionAlCliente}}
        </div>
      </div>
      <!-- <div class="header centrarTituloEnMobile">
        Escribinos
      </div> -->
      <div class="socialIcons">
        <div class="icono" *ngFor="let red of redes">
          <a [href]="red.link" target="_blank">
            <img class="iconoImagen" [width]="40" [height]="40" [src]="red.urlIcono" [alt]="red.nombre">
          </a>
        </div>
      </div>
    </div>
  </section>
</div>

<modal-open-point id="modal-data-empresa">
  <div normal class="bubletext">
      <modal-data-empresa></modal-data-empresa>
  </div>
</modal-open-point>

<modal-open-point id="modal-info-prov">
  <div normal class="bubletext">
      <modal-info-prov></modal-info-prov>
  </div>
</modal-open-point>

<modal-open-point id="modal-work-withAs">
  <div normal class="bubletext">
      <modal-work-withAs></modal-work-withAs>
  </div>
</modal-open-point>
