import { ActivatedRoute } from "@angular/router";

export abstract class MyNavigator{
  abstract goDetallePedido(router: ActivatedRoute);
  abstract primerArticulo();
  abstract notLogin();
  abstract goHistorial(router: ActivatedRoute);
  abstract endline(router: ActivatedRoute);

  abstract cierreCheckout(router: ActivatedRoute);
  abstract precierreCheckout(router: ActivatedRoute);
  abstract goResetPass(router: ActivatedRoute);
  
  abstract goCrearAccount(router: ActivatedRoute);

  abstract goSearch(router: ActivatedRoute);

  abstract goLandPage(route: ActivatedRoute);

  abstract irResumenCompra(route: ActivatedRoute);

}