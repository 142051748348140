<div *ngIf="modo==='normal'">
    <div *ngIf="!cargando; else elseBlock">
        <section>
            <div class="container">

                <div class="titulo" style="font-size: 40px; margin-bottom: 3em;">
                    Saldo Actual: ${{saldoActual}}
                </div>

                <div class="titulo">
                    Movimientos
                </div>

                <table class="fields">
                    <thead>
                        <tr class="field-title">
                            <th class="campo texto">Tarjeta</th>
                            <th class="campo texto">Movimiento</th>
                            <th class="campo texto">Concepto</th>
                            <th class="campo texto">Monto</th>
                            <th class="campo texto">Fecha</th>
                        </tr>
                    </thead>

                    <tr *ngFor="let mov of movimientos" class="field">
                        <td class="valor texto">{{mov.tarjeta}}</td>
                        <td class="valor texto">{{mov.movimiento}}</td>
                        <td class="valor texto">{{mov.concepto}}</td>
                        <td class="valor texto">{{mov.monto}}</td>
                        <td class="valor texto">{{mov.vencimient}}</td>
                    </tr>
                </table>

            </div>
        </section>
    </div>

    <mmax-helpfootbar></mmax-helpfootbar>

    <foo-mariano-max></foo-mariano-max>
</div>

<ng-template #elseBlock>
    <div *ngIf="!error; else errorBlock">
        <section>
            <div class="container">

                <div class="titulo" style="font-size: 40px; margin-bottom: 3em;">
                    Saldo Actual: Cargando Saldo...
                </div>

                <div class="titulo">
                    Movimientos
                </div>
                <div class="fields"
                    style="font-size: 25px; margin: 5em auto; width: 50%; border-bottom: 1px solid gray">

                    Cargando Movimientos...

                </div>
            </div>
        </section>
    </div>


</ng-template>

<ng-template #errorBlock>
    <section>
        <div class="container">

            <div class="titulo" style="font-size: 40px; margin-bottom: 3em;">
                La consulta de saldos fallo!
            </div>

            <div class="titulo">
                Error al obtener movimientos
            </div>
            <div class="fields" style="font-size: 25px; margin: 5em auto; width: 50%; border-bottom: 1px solid gray">

                Por favor reinicie la pagina o intente más tarde...
                Si el error persiste comuniquese con el proveedor.

            </div>
        </div>
    </section>
</ng-template>