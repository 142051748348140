<div *ngIf="modo==='normal'">
  <div class="seccion">
    <div class="nombre">
      <div class="label">
        <div class="ttile">
          Historial
        </div>
        <div class="prepaf">
          Estas son todas las compras que has realizado en nuestra plataforma online.
        </div>
      </div>
      <button class="btn-reload" (click)="reload()">
        Actualizar
      </button>
    </div>
  </div>
  <div class="histlist">
    <div *ngIf="cargando" class="container-carga">
      <spinner></spinner>
    </div>
    <div *ngIf="!hayPedidos" class="avisoContainer">
      <div class="avisoBackground">
        <div class="avisoTexto">
          No posee Pedidos en su Historial
        </div>
      </div>
    </div>
    <historial-generic-wiew *ngFor="let historia of historial" (onRepat)="recompra($event)"
      (onClick)="verDetalle($event)" [jistoria]="historia"></historial-generic-wiew>
  </div>
  <div *ngIf="!cargando">
    <mmax-helpfootbar></mmax-helpfootbar>
    <foo-mariano-max></foo-mariano-max>
  </div>
</div>
