import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoriasWebMenu, EcommerceGenericoService, MarcasEcommerce } from 'projects/ecommerce-generico/src/public-api';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'app-marcas-destmmax',
  templateUrl: './marcas-destmmax.component.html',
  styleUrls: ['./marcas-destmmax.component.scss']
})

export class MarcasDestmmaxComponent implements OnInit {
  @Input('mode') modo: string;
  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private router: ActivatedRoute) {
    this.modo = this.modo || 'normal';
  }

  ngOnInit(): void {
    this.router.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    })
  }

  irAmarcas(marca: CategoriasWebMenu) {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.genServ.busquedaPorMarca(marca);
    this.serv.navigator.goSearch(this.router);
  }

  irMarcasMobile(marca: MarcasEcommerce) {
    this.serv.navigator.goSearch(this.router);
  }

  get marcasD(): MarcasEcommerce[] {
    let marcas = this.genServ.getMarcasDestacadas();
    let marcasWeb = [];
    for (let i = 0; i < marcas.length; i++) {
      const element = marcas[i];

      if (element.destacada === "S") {
        marcasWeb.push(element);
      }
    }

    return marcasWeb;
  }

  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  get marcas(): MarcasEcommerce[] {
    let marcas = this.genServ.getMarcasDestacadas();
    let marcasWeb = [];
    if (marcas.length >= 6) {
      for (let i = 0; i < 6; i++) {
        const element = marcas[i];

        if (element.destacada === "S") {
          marcasWeb.push(element);
        }
      }
    }

    return marcasWeb;
  }

}
