import {trigger,state,style,animate,transition} from '@angular/animations';

export const GenericoSlideAnim = 
        trigger('openClose', [
            // ...
            state('open', style({
            display: 'inherit',
            })),
            state('closed', style({
            display: 'none',
            })),
        ]);