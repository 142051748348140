import { Component, OnInit, Output } from '@angular/core';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'hay-carrito-offline',
  templateUrl: './hay-carrito-offline.component.html',
  styleUrls: ['./hay-carrito-offline.component.scss']
})
export class HayCarritoOfflineComponent implements OnInit {
  constructor(private genServ : EcommerceGenericoService) {
  }

  ngOnInit(): void {
  }

  descartarCarritoWeb(){
    this.genServ.enviarCarritoOfflineRespuesta('DESCARTAR CARRITO WEB')
  }

  combinarCarritos(){
    this.genServ.enviarCarritoOfflineRespuesta('COMBINAR CARRITOS')
  }
}
