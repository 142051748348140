import { ActivatedRoute, Router } from '@angular/router';
import { MarianomGeneral } from '../marianom-general.service';
import { MyNavigator } from './navegador-class';

export class PCNavigator extends MyNavigator {

    constructor(private router: Router, private serv: MarianomGeneral) {
        super();
    }
    goDetallePedido(router: ActivatedRoute) {
        this.router.navigateByUrl('detallepedido', { relativeTo: router });
    }
    primerArticulo() {

        this.serv.opArtConModal();
    }
    precierreCheckout(router: ActivatedRoute) {
        this.router.navigateByUrl('checkout', { relativeTo: router });
    }
    notLogin() {
        this.router.navigateByUrl('');
        this.serv.abrirModalLogin()
    }
    goHistorial(router: ActivatedRoute) {
        this.router.navigateByUrl('historial', { relativeTo: router });
    }
    endline(router: ActivatedRoute) {
        this.router.navigateByUrl('checkout/finalizado', { relativeTo: router });
    }
    cierreCheckout(router: ActivatedRoute) {
        this.router.navigateByUrl('checkout/finalizar', { relativeTo: router });

    }

    goResetPass(router: ActivatedRoute) {
        this.router.navigateByUrl('ayuda/resetpassword', { relativeTo: router });
    }
    goCrearAccount(router: ActivatedRoute) {
        this.router.navigateByUrl('ayuda/crearcuenta', { relativeTo: router });
    }
    goSearch(router: ActivatedRoute) {
        this.router.navigateByUrl('busqueda', { relativeTo: router });
    }

    goLandPage(router: ActivatedRoute) {
        this.router.navigateByUrl('', { relativeTo: router });
    }

    irResumenCompra(router: ActivatedRoute) {
        this.router.navigateByUrl('resumendecompra', { relativeTo: router });
    }

}