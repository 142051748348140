import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';

import { ArticulosGeneral, EcommerceGenericoService, HistorialPedidos, ItemsMMaxResumen } from 'projects/ecommerce-generico/src/public-api';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'historial-generic-wiew',
  templateUrl: './historial-generic-wiew.component.html',
  styleUrls: ['./historial-generic-wiew.component.scss']
})

export class HistorialGenericWiewComponent implements OnInit {
  @Input() jistoria: HistorialPedidos;
  @Input() displayPagoDiferido: string;
  @Output() onClick: EventEmitter<HistorialPedidos>;
  @Output() onRepat: EventEmitter<HistorialPedidos>;

  pagoDiferido: string;
  articulosDePedido : ArticulosGeneral[] = [];
  costoFlete: number;
  constructor(private serv: MarianomGeneral, private router: ActivatedRoute, private modal: ModalService, private genServ : EcommerceGenericoService) {
    this.onClick = new EventEmitter();
    this.onRepat = new EventEmitter();
    this.genServ.getPagoDiferido().subscribe(data => {
      this.pagoDiferido = data
    });
    this.displayPagoDiferido = this.displayPagoDiferido || "none";
  }

  ngOnInit(): void {
    if (this.pagoDiferido === "S" && this.jistoria.estado === "Cerrado") {
      this.displayPagoDiferido = "flex";
    }
    if (this.pagoDiferido === "N" && this.jistoria.estado === "Cerrado") {
      this.displayPagoDiferido = "flex";
    }
    if (this.pagoDiferido === "E") {
      this.displayPagoDiferido = "none";
    }
    this.articulosDePedido = this.jistoria.itemsreales.filter(
      itemsHistorialPedido =>{
        if(itemsHistorialPedido.marca !== parseInt(this.genServ.marcaFlete) || itemsHistorialPedido.codigo !== parseInt(this.genServ.codigoFlete)){
          return true
        }
        else {
          this.costoFlete = itemsHistorialPedido.getPrecio();
          return false;
        }
      }
    )
  }
  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  verDetalle() {
    this.onClick.emit(this.jistoria);
  }

  pagar() {
    this.genServ.resetEccomerce();
    this.genServ.cargarEstePedidoDeHistorialEnCart(this.jistoria);
    this.genServ.guardarpedido(this.jistoria);
    this.serv.navigator.precierreCheckout(this.router);
    setTimeout(() => {
      this.serv.navigator.cierreCheckout(this.router);

    }, 50);
  }

  repetirCompra() {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.onRepat.emit(this.jistoria);
  }
}
