<div *ngIf="modo==='normal'">
  <!-- <div id="container-carga">
        <div id="carga"></div>
    </div> -->
  <section>
    <div class="centro">
      <div class="formpart">
        <div class="lal">
          Restablecer Contraseña
        </div>

        <div class="subtext">
          Te enviaremos un mail para realizar el cambio de contraseña.
        </div>
        <div class="link" (click)="goToReset()">
          Si ya tenes un codigo hace click acá.
        </div>
        <div class="formsection" [ngStyle]="{'display': form}">
          <div class="formcontainer">
            <form [formGroup]="resetform">
              <label class="labelform">
                <span class="labell">
                  Mail
                </span>
                <input class="inputform" autocomplete="email" type="email" [formControlName]="'email'" />
                <div *ngIf="formFields.email.touched && formFields.email.invalid" class="tooltip">
                  <div class="tooltiptext" *ngIf="formFields.email.errors.required">
                    e-Mail no puede estar vacío
                  </div>
                  <div class="tooltiptext" *ngIf="formFields.email.errors.email">
                    e-Mail inválido
                  </div>
                </div>
              </label>
              <button [disabled]="!resetform.valid" class="botning" type="submit" (click)="cambiarPass()">
                Siguiente
              </button>
            </form>
          </div>
        </div>
        <div class="container-carga" [ngStyle]="{'display': loader}">
          <spinner></spinner>
        </div>
      </div>
      <div class="dibugopart">
        <div class="imagecontainer">
          <img [src]="imagen">
        </div>
      </div>
    </div>
  </section>
</div>
