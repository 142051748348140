
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import * as moment from 'moment';
import * as hash from 'hash.js';
import jwtDecode from "jwt-decode";
import { BehaviorSubject, forkJoin, Observable, of, Subscriber, Subscription, timer, empty, Subject } from 'rxjs';
import { concatAll, first, map, subscribeOn, tap, switchMap, elementAt, catchError } from 'rxjs/operators';
import { SucursalEcommerce } from './interfaces/sucursales-eccomerce.interface'
import { ArticulosEcommerce } from './interfaces/articulos-ecommerce.interface';
import { Usuario } from './interfaces/usuario.interface';
import { PromocionesEccomerce } from './class/promociones-class';
import { CategoriasWebMenu, MarcasEcommerce, BackUptArticulo } from '../public-api';
import { ArticulosGeneral } from './class/articulos-general.interface';
import { PorcentajePromo } from './class/porcentaje-promo.class';
import { MontoPromo } from './class/monto-calculado-promo';
import { DiferenciaPromo } from './class/diferencia-promo.class';
import { RemplazosMMax } from './interfaces/remplazos-ecommerce.interface';
import { ItemsMMaxResumen, PedidosMmaxEccomerce } from './interfaces/pedidos-eccomerce.interface';
import { ResultadosDeBusquedaInterface } from './interfaces/resultado-busqueda.interface';
import { ArticuloPeticionEnviar, HistorialPedidos } from './interfaces/historial-ecommerce-mmax';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { OfertasEcommerce } from './interfaces/ofertas-ecommerce.interface';
import { TagsEcommerce } from './interfaces/tags-eccomerce.interface';
import { ModalService } from 'projects/app-shared/src/public-api';
import { Cloudinary, CloudinaryImage } from '@cloudinary/url-gen';
import { Ciudad } from './interfaces/ciudades-envío.interface';
import { TOKEN_CHECK_INTERVAL_TIME, USER_INACTIVITY_MAX_TIME } from './constants';
import { Underlay } from '@cloudinary/url-gen/actions';
import { DatosEnvio } from './interfaces/envios.interface';
import { CheckboxCategorias, CheckboxSubcategoria } from '../../../../projects/mariano-max/src/app-shared/etc/categoria-checkbox.interface';
import { ClienteWeb } from './interfaces/clienteweb.interface';
import { RedSocial } from './interfaces/redSocial.interface';
@Injectable()

export class EcommerceGenericoService {
  imgn: any;
  img: CloudinaryImage
  private lastObs: string;
  private sucursalactiva: SucursalEcommerce;
  private sucursalactivatemp: BehaviorSubject<SucursalEcommerce>;
  private sucursal_defecto: string;
  private articulosSucursalDefecto: ArticulosGeneral[];

  private sucursales: SucursalEcommerce[];
  private sucursalestemp: BehaviorSubject<SucursalEcommerce[]>;
  private urlmap: string;
  ciudades: BehaviorSubject<any[]>;

  private environment: { endpoint: string, endpointversion };
  private environmenturl: URL;

  private user: Usuario;
  private userProvider: BehaviorSubject<Usuario>;
  private lastSearch: BehaviorSubject<ArticulosGeneral[]>;
  private minisearch: BehaviorSubject<ArticulosGeneral[]>
  private paginadorProvider: BehaviorSubject<number>
  private paginadorProviderOfertas: BehaviorSubject<number>
  private promocionesEcommerce: PromocionesEccomerce[];
  private contador: number;
  private tareas: Observable<void>[];
  private intervals: number[];
  private idInterval: number;
  private categoriasWeb: CategoriasWebMenu[];
  private marcasDestacadas: MarcasEcommerce[];
  private carritoObservables: Observable<void>[];
  private idIntervalForCart: number;
  private reset: boolean;

  private resultadosDebusqueda: ArticulosGeneral[];
  private numBusqueda: number;
  private paginaActual: number;
  private paginaActualOfertas: number;
  private anchoPagina: number;
  private fueBusquedaEscrita: boolean;

  private remplasosProvider: BehaviorSubject<RemplazosMMax[]>;
  private remplazos: RemplazosMMax[];

  private mypedido: PedidosMmaxEccomerce;
  private mapaPedidos: Map<number, { marca: number, codigo: number, descripcion: string }[]>;
  private resultadoDeBusquedaSubject: BehaviorSubject<ResultadosDeBusquedaInterface>

  private montoMinimo: number;
  private pagoDiferido: BehaviorSubject<string>;
  private envioHabilitado: BehaviorSubject<string>;
  private registroHabilitado: BehaviorSubject<string>;

  private detalleElegido: HistorialPedidos;
  private historiaPedidos: HistorialPedidos[];
  private historialtemp: BehaviorSubject<HistorialPedidos[]>;
  private imagenModal: string;

  private ofertasEnlazadas: OfertasEcommerce[];
  private ofertasTemp: BehaviorSubject<OfertasEcommerce[]>;
  private ofertas: ArticulosGeneral[];

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  private tags: TagsEcommerce[];
  private tagsRelacion: any[];
  private tagsActivos: Map<number, ArticulosGeneral[]>;

  private logoSvg: SafeUrl;
  private iconLogo: string;
  private nombreSitio: string;
  private colores: any;
  private oldSort: any;
  private busqueda: string;
  private filterOn: boolean;
  private paginaEmitter: EventEmitter<number>;
  private misPaginas: any[];
  private paginaElegida: any;
  private articuloAMostrarEnModalDeDetallesDeArticulo: ArticulosGeneral;
  private articulos2: ArticulosGeneral[];
  private beneficios: BehaviorSubject<string>;
  private usaTurnos: BehaviorSubject<string>;
  private horasMinimasTurno: BehaviorSubject<string>;
  private carrouselSlide: BehaviorSubject<PromocionesEccomerce[]>;
  private publicKeyMP: string;
  private urlCheckoutMP: string;
  private datosEnvio: any;
  codigoFlete: string;
  marcaFlete: string;
  private slidesArray: any[];
  private slidesArrayObservable: BehaviorSubject<any[]>;
  private redes: RedSocial[] = [];
  private pendingPedidos: boolean;
  private pendingTurnos: boolean;
  private pendingLogin: boolean;
  private pendingBeneficios: boolean;
  private pendingRestore: boolean;
  private pendingChangePass: boolean;
  private errorMessage: string;
  private mailAtencionAlCliente : string;
  private seRealizoUnaBusquedaSubject: BehaviorSubject<any>;
  private seRealizoUnaBusquedaObservable: Observable<any>;
  resultadosDebusquedaOriginales;
  private telefonoAtencionAlCliente: string;
  private dataEmpresa: string;
  private workWithAs: string;
  private infoProv:string;
  private retiroSucursal: string;
  private infoCompra: string;
  private infoMP: string;
  private envioExpress: boolean;
  private observacionesEnvio: string;
  private textoCerrarPedido: string;
  private terminos_condiciones: string;
  private informacion_envio: string;
  private politica_devoluciones: string;
  últimaBusquedaPorDescripcion: string;
  terminoDeIniciarCarro : Subject<any> = new Subject();
  sePermiteAgregarArticuloACarritoSinLogin: boolean = false;
  private seAgregaronArtículosAlCarrito : Subject<string> = new Subject();
  ciudadesHabilitadasParaEnvio : Ciudad[] = [];
  private articulosSucursalCargados : Subject<string> = new Subject();
  costoUltimoFlete : number = 0;
  codigoTimeoutInactividad : number= -1;
  private webWorker : Worker = new Worker('../../../mariano-max/src/app/ecommerce-generico.worker', {type : 'module'}) //usado para asegurar que el detector de inactividad corra aún fuera de foco en los navegadores
  private backUpCarritoOffline : BackUptArticulo[] = [];
  private hayCarritoOfflineRespuestaSubject : Subject<string> = new Subject();
  private sincronizandoCarritoConBack : boolean = false;
  ocultarSideBarBusqueda : boolean = true;
  ocultarMenuProductos: boolean =false;
  ocultarMenuOfertas: boolean =false;
  ocultarMenuMarcas: boolean =false;
  ocultarMenuCompras: boolean =false;
  ocultarMenuSucursales: boolean =false;
  ocultarMenuAyuda: boolean =false;
  verTutoriales: boolean =false;
  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer, private modal: ModalService) {
    this.remplazos = [];
    this.sucursales = [];
    this.articulosSucursalDefecto = [];
    this.promocionesEcommerce = [];
    this.sucursalactivatemp = new BehaviorSubject(undefined);
    this.environment = undefined;
    this.environmenturl = undefined;
    this.user = undefined;
    this.userProvider = new BehaviorSubject(undefined);
    this.sucursalestemp = new BehaviorSubject([]);
    this.lastSearch = new BehaviorSubject([]);
    this.minisearch = new BehaviorSubject([]);
    this.paginadorProvider = new BehaviorSubject(1);
    this.paginadorProviderOfertas = new BehaviorSubject(1);
    this.remplasosProvider = new BehaviorSubject([]);
    this.resultadoDeBusquedaSubject = new BehaviorSubject(undefined);
    this.seRealizoUnaBusquedaSubject = new BehaviorSubject(undefined);
    this.seRealizoUnaBusquedaObservable = this.seRealizoUnaBusquedaSubject.asObservable();
    this.contador = 0;
    this.nombreSitio = "";
    this.colores = [];
    this.tareas = [];
    this.ofertas = [];
    this.ofertasEnlazadas = [];
    this.ofertasTemp = new BehaviorSubject([]);
    this.idInterval = window.setInterval( //interval cada cuanto se revisa el token del usuario
      () => {
        //console.log('idInterval')
        this.syncronizer();
      }, TOKEN_CHECK_INTERVAL_TIME);
    this.idIntervalForCart = window.setInterval(
      () => {
        this.cartSyncronizer()
      }, 1000);
    this.intervals = [this.idInterval, this.idIntervalForCart];
    this.categoriasWeb = [];
    this.marcasDestacadas = [];
    this.carritoObservables = [];
    this.resultadosDebusqueda = [];
    this.historiaPedidos = [];
    this.tags = [];
    this.tagsActivos = new Map();
    this.historialtemp = new BehaviorSubject(this.historiaPedidos);
    this.fueBusquedaEscrita = true;
    this.oldSort = [];
    this.busqueda = "";
    this.filterOn = false;
    this.articulos2 = [];
    this.carrouselSlide = new BehaviorSubject([]);
    this.registroHabilitado = new BehaviorSubject(undefined);
    this.beneficios = new BehaviorSubject(undefined);
    this.publicKeyMP = undefined;
    this.urlCheckoutMP = "";
    this.pagoDiferido = new BehaviorSubject(undefined);
    this.envioHabilitado = new BehaviorSubject(undefined);
    this.ciudades = new BehaviorSubject([]);
    this.usaTurnos = new BehaviorSubject(undefined);
    this.horasMinimasTurno = new BehaviorSubject(undefined);
    this.slidesArray = [];
    this.slidesArrayObservable = new BehaviorSubject([]);
    this.pendingPedidos = false;
    this.pendingTurnos = false;
    this.pendingLogin = false;
    this.pendingBeneficios = false;
    this.pendingRestore = false;
    this.pendingChangePass = false;
    this.comenzarListenerDeActividadDeOtrasTabs();
  }

  initmaster = new Observable<void>(sus => {
    this.getJSON().subscribe({
      next:data => {
        //console.log('initmaster data',data)
        this.environment = { endpoint: data['endpoint'], endpointversion: data['endpointversion'] };
        this.environmenturl = new URL(data['endpointversion'], data['endpoint']);
        localStorage.setItem('environment', this.environmenturl.toString())
        let urlLogo = data['logo_empresa'];
        this.iconLogo = data['icono_empresa'];

        this.descargImgaen(urlLogo).subscribe(data => {
          this.logoSvg = data;
        });

        this.nombreSitio = data['nombre_empresa']
        this.sucursal_defecto = data['sucursal_defecto'];
        this.colores = data['colores'];
        this.publicKeyMP = data['public_key_checkout'];
        this.urlCheckoutMP = data['url_checkout_mp'];
        this.dataEmpresa = data['dataEmpresa'];
        this.workWithAs = data['workWithAs'];
        this.envioExpress = data['envioExpress'];

        this.observacionesEnvio = data['observacion_envio'];
        this.textoCerrarPedido = data['texto_cerrar_pedido'];
        this.terminos_condiciones= data['terminos_condiciones'];
        this.informacion_envio= data['informacion_envio'];
        this.politica_devoluciones= data['politica_devoluciones'];

        this.infoProv = data['infoProv'];
        this.retiroSucursal = data['retiroSucursal'];
        this.infoCompra = data['infoCompra'];
        this.infoMP = data['infoMP'];

        for(let red in data['redes']){
          //console.log('red',red)
          const redEnConfig = data['redes'][red];
          const nuevared : RedSocial = {
            nombre : redEnConfig['redSocialNombre'],
            link : redEnConfig['redSocialLink'],
            urlIcono: redEnConfig['redSocialUrlIcono']
          };
          //console.log(nuevared)
          this.redes.push(nuevared);
        }

        var rootElement = document.documentElement;
        var primerColor = this.colores.primerColor;
        var segundoColor = this.colores.segundoColor;
        var tercerColor = this.colores.tercerColor;
        var textoColor = this.colores.textoColor;
        var pieColor = this.colores.pieColor;
        var pieColorTexto = this.colores.pieColorTexto;
        var sombraColor = this.colores.sombraColor;

        rootElement.style.setProperty("--primerColor", primerColor);
        rootElement.style.setProperty("--segundoColor", segundoColor);
        rootElement.style.setProperty("--tercerColor", tercerColor);
        rootElement.style.setProperty("--textoColor", textoColor);
        rootElement.style.setProperty("--foo-ecommerce-color", pieColor);
        rootElement.style.setProperty("--foo-text-ecommerce-color", pieColorTexto);
        rootElement.style.setProperty("--sombraColor", sombraColor);

        this.setmailAtencionAlCliente(data['mailAtencionAlCliente']);
        this.settelefonoAtencionAlCliente(data['telefonoAtencionAlCliente']);

        this.ocultarMenuProductos = data['ocultarMenuProductos'];
        this.ocultarMenuOfertas = data['ocultarMenuOfertas'];
        this.ocultarMenuMarcas = data['ocultarMenuMarcas'];
        this.ocultarMenuCompras = data['ocultarMenuCompras'];
        this.ocultarMenuSucursales = data['ocultarMenuSucursales'];
        this.ocultarMenuAyuda = data['ocultarMenuAyuda'];
        this.verTutoriales = data['VerTutoriales'];

        forkJoin([
          this.bajarSucursales(),
          this.configuracionEcommerce(),
          // this.bajarArticulosPorSucursal(this.sucursal_defecto.toString()).toPromise(),
          // this.promocioneswebs(this.sucursal_defecto.toString()).toPromise(),
          // this.ofertaswebec(this.sucursal_defecto.toString()).toPromise(),
          this.barjarMarcas(),
          this.barjarCategoriasdeArticulos(),
          this.barjarCategorias(),
          // this.tagswebGet(),
          // this.tagsarticulosweb(),
          // this.inciarUsuario(),
        ]).subscribe(() => {
          this.tareasFinales().then(() => {
            sus.next();
            sus.complete();
          }).catch((err) => {
            sus.next();
            sus.complete();
          });

        }, err => this.tirarError(err, sus));
      },
    });
  });

  async syncronizer() {
    this.contador++;
    forkJoin(this.tareas).pipe(concatAll()).subscribe(() => {

    }, (err) => {
      throw err;
    }, () => { });
  }

  async cartSyncronizer() {
    if (this.carritoObservables.length > 0) {
      this.carritoObservables[0].subscribe(() => {

      }, (err) => {
        throw err;
      }, () => {
        this.carritoObservables = [];
      });
    }

  }

  getSucursalPorNumero(sucursal: number) {
    let nombre = ''
    this.sucursales.forEach(k => {
      if (sucursal == k.sucursal) {
        nombre = k.nombre;
      }
    })
    return nombre;
  }

  addtask(taks: Observable<void>) {
    this.tareas.push(taks);
  }

  ordenarporFechaHistorial(data: HistorialPedidos[]): HistorialPedidos[] {
    let arrayHistorial = data.sort(
      (a: HistorialPedidos, b: HistorialPedidos): number => {
        return b.iddocumento - a.iddocumento;
      }
    );

    if (arrayHistorial.length > 30) {
      arrayHistorial = arrayHistorial.slice(0, 30);
    }
    return arrayHistorial;
  }

  mayorAmenorPrecio = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    let precioArticuloA = a.getPrecio();
    let precioArticuloB = b.getPrecio();
    if(a.isdeOferta()){
      precioArticuloA = a.getPrecioOferta();
    }

    if(b.isdeOferta()){
      precioArticuloB = b.getPrecioOferta();
    }

    return (precioArticuloA - precioArticuloB)*-1;
  }

  menorAmayorPrecio = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    let precioArticuloA = a.getPrecio();
    let precioArticuloB = b.getPrecio();
    if(a.isdeOferta()){
      precioArticuloA = a.getPrecioOferta();
    }

    if(b.isdeOferta()){
      precioArticuloB = b.getPrecioOferta();
    }

    return (precioArticuloA - precioArticuloB);
  }

  ordenAlfabetico = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    if (a.getDescripCion() > b.getDescripCion()) {
      return -1;
    }
    if (a.getDescripCion() < b.getDescripCion()) {
      return 1;
    }
    return 0;
  }

  ordenAlfabeticoInvertido = (a: ArticulosGeneral, b: ArticulosGeneral): number => {
    if (a.getDescripCion() > b.getDescripCion()) {
      return 1;
    }
    if (a.getDescripCion() < b.getDescripCion()) {
      return -1;
    }
    return 0;
  }

  preOrdenAlfabetico = (a, b): number => {
    if (a.descripcion > b.descripcion) {
      return 1;
    }
    if (a.descripcion < b.descripcion) {
      return -1;
    }
    return 0;
  }

  ordenarArticulosPor(id: string, filtro : CheckboxSubcategoria) {
    if (id === '0') {
      this.resultadosDebusqueda.sort(this.menorAmayorPrecio);
      this.paginado();
      this.filterOn = true;

      this.lastSearch.next(this.lastSearch.value);
    }
    if (id === '1') {
      this.resultadosDebusqueda.sort(this.mayorAmenorPrecio);
      this.paginado();
      this.filterOn = true;

      this.lastSearch.next(this.lastSearch.value);
    }
    if (id === 'a') {
      this.resultadosDebusqueda.sort(this.ordenAlfabeticoInvertido);
      this.paginado();
      this.filterOn = true;

      this.lastSearch.next(this.lastSearch.value);

    }
    if (id === 'z') {
      this.resultadosDebusqueda.sort(this.ordenAlfabetico);
      this.paginado();
      this.filterOn = true;

      this.lastSearch.next(this.lastSearch.value);
    }
  }

  hasFilter() {
    return this.filterOn;
  }

  getBusqueda(busqueda) {
    this.busqueda = busqueda;
  }

  getNewToken(): Observable<any> {
    return this.httpClient.post(this.environment.endpoint + '/jwt/renew', {}, { headers: { "Authorization": this.user.token } });
  }

  /**
   *Elimina al usuario del localstorage, del servicio y reinicia eCommerce
   */
  limpiarysalir() : void {
    this.user = undefined;
    this.userProvider.next(undefined);
    localStorage.removeItem('currentUser');
    this.resetEccomerce();
  }

  /**
   * Revisa si faltan 5 minutos para que expire del token del usuario logueado
   * @param token token del usuario
   */
  isUserTokenValid(token) : boolean {
    var decoded = jwtDecode(token);
    var expirationDate = new Date(decoded['exp'] * 1000);
    var daste = moment().local(true).add(5, 'minutes').unix();
    var midate = new Date(daste * 1000);
    //console.log('getValidationToken()', decoded, new Date(decoded['exp'] *1000), midate)
    return !(expirationDate <= midate);
  }

  cerrarPromos(): Observable<any> {
    let promocionesaGuardar: BackUptArticulo[] = [];
    console.log('cerrarPromos promocionesEcommerce', this.promocionesEcommerce)
    this.promocionesEcommerce.forEach(k => {
      if (k.counter > 0) {
        promocionesaGuardar.push(
          {
            marca: k.getMarca(),
            codigo: k.getCodigo(),
            cantidad: k.articuloenPromo().getCantidad(),
            precio: k.getPrecio(),
            monto: k.getPrecioCalculado(),
            reemplazo: 0,
            descripcion: '',
            fraccion: 0
          }
        );

      }
    })
    if (promocionesaGuardar.length > 0) {
      console.log('cerrarPromos promocionesaGuardar', promocionesaGuardar)
      return this.postGenericoSeguro('itempedidoweb', { articulos: promocionesaGuardar });
    }
    else {
      return of(0);
    }

  }

  deletePromosCloud(): Observable<any> {
    let promocionesaGuardar: BackUptArticulo[] = [];
    //console.log('promocionesEcommerce', this.promocionesEcommerce)
    this.promocionesEcommerce.forEach(k => {
      promocionesaGuardar.push(
        {
          marca: k.getMarca(),
          codigo: k.getCodigo(),
          cantidad: 0,
          precio: 0,
          monto: 0,
          reemplazo: 0,
          descripcion: '',
          fraccion: 0
        }
      );
    })
    if (promocionesaGuardar.length > 0) {
      return this.postGenericoSeguro('itempedidoweb', { articulos: promocionesaGuardar });
    }
    else {
      return of(0);
    }
  }


  /**
   * elimina las promos y setea la cantidad de los articulos en 0
   */
  resetEccomerce() : void{
    this.sucursalactiva.articulos.forEach(
      articulo => {
        this.eliminarPromos(articulo);
        articulo.setCantidad(0);
      }
    );
    this.sincronizarCarritoFrontyBack();
    this.costoUltimoFlete = 0;
    this.seAgregaronArtículosAlCarrito.next('actualizar carrito')
    //this.sucursalactiva = null;
  }

  letresetJson() : Observable<any> {
    let params = new HttpParams();
    return this.getGenericoSeguro(params, 'resetjson');
  }

  guardarpedido(pedido: PedidosMmaxEccomerce) : void {
    //console.log('guardarpedido pedido', pedido)
    this.mypedido = pedido;
    this.mypedido.medios.forEach(medios => {
      medios.id = this.generarid(5);
      medios.selecionado = false;
    })
  }

  verUltimoPedido() : PedidosMmaxEccomerce {
    return this.mypedido;
  }

  setearArticuloParaModal(articuloAMostrarEnModal : ArticulosGeneral) : void{
    this.articuloAMostrarEnModalDeDetallesDeArticulo = articuloAMostrarEnModal;
  }

  returnArtOpened() {
    return this.articuloAMostrarEnModalDeDetallesDeArticulo;
  }

  returnRedes() {
    return this.redes;
  }

  devolverMediosPagos() {
    return this.mypedido.medios;
  }

  obtenerPlanesdemedios(mediopago: number) {
    let params = new HttpParams();
    params = params.append('documento', this.mypedido.iddocumento.toString());
    params = params.append('mediopago', mediopago.toString());
    return this.getGenericoSeguro(params, 'planesmediosweb');
  }

  cerrarFinaleccomerce(mediopago: number, plan: number) {
    return this.postGenericoSeguro('ordenpagoweb', {
      documento: this.mypedido.iddocumento,
      mediopago: mediopago,
      plan: plan,
    });
  }

  getUser() {
    return this.user;
  }
  setUser(newUserData : Usuario){
    this.user = newUserData;
  }

  /**
   * Revisa si el tocken del usuario sigue siendo válido, si no lo es lo renueva
   */
  tokencheck = new Observable<void>(
    response => {
      if (this.user != undefined) {
        //console.log('tokencheck')
        if (this.isUserTokenValid(this.user.token) == false) {
          this.getNewToken().subscribe(data => {
            localStorage.removeItem('currentUser')
            this.user.token = data.token;

            this.userProvider.next(this.user);
            localStorage.setItem('currentUser', JSON.stringify({ username: this.user.username, token: data.token }));
          })
        }
      }
      response.complete();
    }
  );

  /**
  * reinicia el contador de inactividad, si el usuario no realiza ninguna peticion en USER_INACTIVITY_MAX_TIME ms se desloguea
  */
  restartInactivityCheck(){
    let datosWorker = {
      tiempo : USER_INACTIVITY_MAX_TIME
    }
    this.webWorker.postMessage(JSON.stringify(datosWorker))
  }

  comenzarListenerDeActividadDeOtrasTabs(){
    this.webWorker.addEventListener('message',
    avisoDesdeWebworker =>{
      this.limpiarysalir();
      }
    )
    window.addEventListener("storage",
     (storageEvent : StorageEvent)  =>{
        const keyAfectada = storageEvent.key;
        if(keyAfectada !== 'horaUltimaPeticion'){
        }
        if(keyAfectada === 'SeActualizoCarritoEnOtraTab'){
          this.iniciarCarrito().subscribe();
        }
      }
    )
  }

  actualizarCarritoDeOtrasTabs(){
    let fecha = new Date()
    localStorage.setItem('SeActualizoCarritoEnOtraTab',fecha.toString());
  }

  getSucursalesProviders() {
    return this.sucursalestemp.asObservable();
  }

  getJSON(): Observable<any> {
    return this.httpClient.get('../../cfg/app-configs.json');
  }

  pendingFalse() {
    this.pendingLogin = false;
    this.pendingBeneficios = false;
    this.pendingPedidos = false;
    this.pendingTurnos = false;
    this.pendingRestore = false;
    this.pendingChangePass = false;
  }

  loginStart(username: any, password: any) {
    let nonce = Date.now();
    let firstHash = hash.sha256().update(username + ' ' + password).digest('hex');
    let secondHash = hash.sha256().update(nonce + ' ' + firstHash).digest('hex');
    if (!this.pendingLogin) {
      this.pendingLogin = true
      return this.loginPost('/jwt/login', { usuario: username, hash: secondHash, nonce: nonce.toString() }).pipe(
        tap((data) => {
          this.pendingLogin = false;
          localStorage.setItem('currentUser', JSON.stringify({ username: username, token: data['token'] }));
          this.user = { username: username, token: data['token'], sucursal: -1 };
          this.inciarUsuario().toPromise();
          this.restoEcomerceInit();
        })
      )
    }
  }

  async inicarMercadoPago() {
    let url = new URL(this.urlCheckoutMP)
    let data = await this.httpClient.post(url.toString(), {
      description: 'COMPRA MMAX',
      price: this.getTotal() + this.datosEnvio ? this.datosEnvio.costo : 0
    }).toPromise().catch(e => {
      console.error(e);
    });
    return data;
  }

  async cambiarSucursalV2(idSucursal: string) {
    await this.postGenericoSeguro('cambiarsucursal', {
      sucursal: idSucursal.toString(),
    }).toPromise();

    let encontroSucursal = false;
    for (let sucursal of this.sucursales) {
      if (sucursal.sucursal.toString() === idSucursal.toString()) {
        this.sucursalactiva = sucursal;
        this.user.sucursal = sucursal.sucursal;
        this.user.sucursalNombre = ' ' + sucursal.nombre;
        encontroSucursal = true;
        break;
      }
    }
    if (encontroSucursal) {
      this.sucursal_defecto = idSucursal;
      this.restoEcomerceInit()
    }
    else {
      console.error('No se encontro la sucursal, y no tiene datos la misma');
    }
  }

  /**
   *
   * @param idSucursal
   */
  private async restoEcomerceInit() {
    //await this.bajarArticulosPorSucursal(idSucursal).toPromise();
    this.reset = true;
    await this.tareasFinales();
  }

  activarSucursal(id: string) {
    return new Observable<void>(subs => {
      for (let sucursal of this.sucursales) {
        if (sucursal.sucursal.toString() === id) {
          this.sucursalactiva = sucursal;
          break;
        }
      }
      this.bajarArticulosPorSucursal(id).subscribe(data => {
        console.log('bajarArticulosPorSucursal', data)
        this.iniciarsucursal(data);
        subs.next();
        this.guardarSucursalUsuario(id).subscribe(data => {
          console.log(data);
          subs.complete();
        })
      })
    })
  }

  guardarSucursalUsuario(sucursalID: string) {
    return this.putGenericoSeguro('clienteweb', {
      nombre: this.user.nombre || '',
      direccion: this.user.direccion || '',
      telefonos: this.user.telefonos || '',
      dni: this.user.dni || 0,
      fechaNacimiento: this.user.fechaNacimiento || '',
      sucursal: sucursalID,
    });
  }

  modificarDatosUsuario( nuevosDatosUsuario){
    const datosAEnviar = {
      nombre: nuevosDatosUsuario.nombre || '',
      direccion: nuevosDatosUsuario.direccion || '',
      telefonos: nuevosDatosUsuario.telefonos || '',
      dni: nuevosDatosUsuario.dni || 0,
      ciudad: nuevosDatosUsuario.ciudad || 0,
      //fechaNacimiento: this.user.fechaNacimiento || '',
      //sucursal: sucursalID,
    }
    return this.putGenericoSeguro('clienteweb', datosAEnviar).pipe(tap({
      next:respuesta =>{
        console.log('respuesta putgenerico clienteweb', respuesta)
        this.user.nombre = datosAEnviar.nombre;
        this.user.direccion = datosAEnviar.direccion;
        this.user.telefonos = datosAEnviar.telefonos;
        this.user.dni = datosAEnviar.dni;
        this.user.ciudad = datosAEnviar.ciudad;
      }
    }));
  }

  crearUsuario(username: any, dni: any, email: any, password: any, sucursal: any): Observable<any> {
    return this.postGenerico('clienteweb', {
      nombre: username,
      direccion: "",
      telefonos: "",
      email: email,
      ciudad: "",
      dni: dni,
      fechaNacimiento: "1899-31-12",
      sucursal: sucursal,
      password: password,
    });
  }

  pedirCambioPass(email: any): Observable<any> {
    if (!this.pendingRestore) {
      this.pendingRestore = true;
      return this.loginPost('/jwt/restore', { email: email }).pipe(
        tap((data) => {
          this.pendingRestore = false;
        })
      );
    }
  }

  cambiarContraseña(username: any, password: any, resetId: any) {
    let nonce = Date.now();
    let firstHash = hash.sha256().update(username + ' ' + password).digest('hex');

    if (!this.pendingChangePass) {
      this.pendingChangePass = true;
      return this.loginPost('/jwt/restore', { usuario: username, hash: firstHash, nonce: nonce, resetid: resetId.toString() }).pipe(
        tap((data) => {
          this.pendingChangePass = false;
        })
      );
    }
  }

  restorePassMmax(username: string, password: string, resetId) {
    let nonce = Date.now();
    let firstHash = hash.sha256().update(username.toLowerCase() + ' ' + password).digest('hex');

    return this.loginPost('/jwt/restore', { usuario: username, hash: firstHash, nonce: nonce, resetid: resetId.toString() }).pipe(
      tap((data) => {
        console.log("Restore MMAX:", data);
      })
    );
  }

  sendErrorMessage(err) {
    this.errorMessage = err;
  }

  getErrorMessage() {
    return this.errorMessage;
  }

  getLogo() {
    return this.logoSvg;
  }

  getNombreSitio() {
    return this.nombreSitio;
  }

  getPublicKeyMP() {
    return this.publicKeyMP;
  }

  tirarError = ((err, sus: Subscriber<void>) => {
    sus.error(err);
    this.modal.open('modal-error');
  });

  cerrarPedido(dia: number, horainicio: number, fecha: string, modo: number, ciudad?: string, barrio?: string, calle?: string, numero?: number) {
    return this.postGenericoSeguro('cerrarpedidoweb', {
      dia: dia,
      horainicio: horainicio,
      fecha: fecha,
      modo: modo,
      observacion: this.lastObs,
      ciudad: ciudad,
      barrio: barrio,
      calle: calle,
      numero: numero,
    });
  }

  cancelarPedidoWeb() {
    return this.postGenericoSeguro('cancelarpedidoweb', {}).subscribe((data) => {
      // console.log(data);
    });
  }

  setObservacion(observacion: string) {
    this.lastObs = observacion || '';
  }

  verPedido(detalle: HistorialPedidos) {
    this.detalleElegido = detalle;
    localStorage.setItem('detalle', JSON.stringify(this.detalleElegido))
  }

  getDetalleElegido() {
    if (this.detalleElegido == undefined) {

      return localStorage.getItem('detalle')
    } else {
      return this.detalleElegido;
    }
  }

  /**
   * Se cargan los articulos de la sucursal defecto en la sucursal activa, si hay carrito sin login habilitado, guarda el carrito en memoria
   * @param articulos los articulos a cargar en la sucursal activa
   */
  private iniciarsucursal(articulos) {
    //console.log('iniciarsucursal(data)', data)
    this.backUpCarritoOffline = [];
    if(this.sucursalactiva.articulos){
      this.sucursalactiva.articulos.forEach(
        (articulo : ArticulosEcommerce) =>{
          if(articulo.getCantidad() !== 0){
            const articuloResumido : BackUptArticulo = {
              descripcion : articulo.getDescripCion(),
              marca : articulo.getMarca(),
              codigo : articulo.getCodigo(),
              cantidad : articulo.getCantidad(),
              precio : articulo.getPrecio(),
              monto : 0,
              reemplazo : articulo.getRemplazo(),
              fraccion : articulo.getFraccion()
            }
            this.backUpCarritoOffline.push(articuloResumido);
          }
        }
      )
    }
    //console.log('carrito offline backup',this.backUpCarritoOffline)
    this.sucursalactiva.articulos = articulos;
    this.sucursalactivatemp.next(this.sucursalactiva);
    this.articulosSucursalCargados.next('listo')
  }

  /**
   * Al cargar app se revisa si hay un usuario en el localstorage y que el token no haya expirado, despues se buscan los datos del cliente
   * @returns
   */
  inciarUsuario() {
    //console.log('iniciar usuario');
    return new Observable<void>(obs => {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      //console.log('this.user', this.user)
      //this.restartInactivityCheck();
      if (this.user === null) {
        //console.log('this.user == null', this.user)
        this.userProvider.next(undefined);
        obs.next();
        obs.complete();
        return;
      }
      if (!this.isUserTokenValid(this.user.token)) {
        this.userProvider.next(undefined);
        localStorage.removeItem('currentUser');
        obs.next();
        obs.complete();
        return;
      }

      this.obtenerdatosdeUsuario().subscribe({
        next:respuesta => {
          const clientWeb : ClienteWeb = respuesta['registros'][0];
          //console.log('obtenerdatosdeUsuario respuesta',clientWeb, data['registros'] )
          this.actualizarUsuario(clientWeb);
          this.tareas.push(this.tokencheck);
          obs.next();
          obs.complete();
        },
        error: err => {
          this.userProvider.next(undefined);
          //console.log('obtenerdatosdeUsuario error',err)
          localStorage.removeItem('currentUser');
          obs.next();
          obs.complete();
        },
      });

    });

  }

  ofertaswebec(sucursal) {
    let httparams = new HttpParams();
    httparams = httparams.append('sucursal', sucursal);
    return this.getGenerico(httparams, 'ofertaswebec').pipe(tap(data => {
      this.ofertasEnlazadas = data['registros'] as OfertasEcommerce[];
    }));
  }

  getOfertAStemp() {
    return this.ofertasTemp.asObservable();
  }

  tagswebGet() {
    let httparams = new HttpParams();

    return this.getGenerico(httparams, 'tagsweb').pipe(tap(data => {
      let mydata = data['registros'];
      if (mydata != undefined && Array.isArray(mydata)) {
        mydata.forEach(k => {
          this.tags.push({
            nombre: k['nombre'],
            articulos: [],
            tag: k['tag']
          });
        })
      }
    }))
  };

  tagsarticulosweb() {
    let httparams = new HttpParams();
    return this.getGenerico(httparams, 'tagsarticulosweb').pipe(tap(data => {
      this.tagsRelacion = data['registros'];
    }));
  }

  turnosdisponibles() {
    let params = new HttpParams();
    if (!this.pendingTurnos) {
      return this.getGenericoSeguro(params, 'turnosdisponibles').pipe(map(data => {
        this.pendingTurnos = false;
        return data['registros'];
      }));
    }
  }

  async tareasFinales() {
    try {
      await this.promocioneswebs(this.sucursalactiva.sucursal.toString()).toPromise();
    } catch (error) {
      console.log('tareasFinales promocioneswebs',error);
    }
    try {
      await this.inciarUsuario().toPromise();
    } catch (error) {
      console.log('tareasFinales inciarUsuario',error);
    }
    try {
      await this.bajarArticulosPorSucursal(this.sucursalactiva.sucursal.toString()).toPromise();
    } catch (error) {
      console.log('tareasFinales bajarArticulosPorSucursal',error);
    }
    try {
      await this.ofertaswebec(this.sucursalactiva.sucursal.toString()).toPromise();
    } catch (error) {
      console.log('tareasFinales ofertaswebec',error);
    }

    if (this.tagsRelacion != undefined && Array.isArray(this.tagsRelacion)) {
      //console.log('tareas finales tags', this.tags)
      this.tags.forEach(k => {
        for (let tag of this.tagsRelacion) {
          if (k.tag == tag.tag) {
            k.articulos.push(tag);
          }
          else {
            break;
          }
        }
      });
    }

    let articulosDeSucursalActiva: ArticulosEcommerce[] = [];
    let registro: ArticulosEcommerce;
    let imgenurl;
    let cloudinaryURL;
    let cloudinaryCloud;
    let cloudinaryID = '';
    //console.log('tareasFinales this.articulosSucursalDefecto',this.articulosSucursalDefecto )
    this.articulosSucursalDefecto.forEach(
      articulo => {
        //console.log('tareasFinales this.articulosSucursalDefecto.forEach',k)
        let remplasoss: RemplazosMMax[] = []
        //console.log('reemplazos', this.remplazos)
        this.remplazos.forEach(articulo => {
          let remplaceme = Object.assign({}, articulo)
          remplaceme.idcss = this.generarid(4);
          remplaceme.activo = false;
          remplasoss.push(remplaceme);
        });

        cloudinaryURL = articulo['foto'];
        imgenurl = new URL(articulo['foto'], this.environmenturl).toString();

        if (this.environment.endpoint + this.environment.endpointversion == imgenurl) {
          imgenurl = undefined;
        } else {
          let imgSplit = imgenurl.split("/");
          let imgTransformation = "q_auto:good/c_fit";
          imgenurl = imgSplit[0] + "/" + imgSplit[1] + "/" + imgSplit[2] + "/" + imgSplit[3] + "/" + imgSplit[4] + "/" + imgSplit[5] + "/" + imgTransformation + "/" + imgSplit[6] + "/" + imgSplit[7];
        }

        if (this.environment.endpoint + this.environment.endpointversion == cloudinaryURL) {
          cloudinaryURL = undefined;
        }

        let cloudSplit = cloudinaryURL.split("/");
        cloudinaryCloud = cloudSplit[3];
        cloudinaryID = cloudSplit[cloudSplit.length - 1];

        if(cloudinaryCloud != ''){
          const cld = new Cloudinary({cloud: {cloudName: cloudinaryCloud}});
          if (cloudinaryID != '') {
            this.img = cld.image(cloudinaryID);
            cloudinaryID = '';
          }
        }
        //console.log('tareasFinales (registro) antes')

        registro = new ArticulosEcommerce(
          articulo['marca'],
          articulo['codigo'],
          articulo['precio'],
          articulo['precionormal'],
          articulo['cmax'],
          articulo['da'],
          articulo['descripcion'],
          articulo['unidadesxb'] | 0,
          articulo['presentacion'],
          articulo['pesoxb'] | 0,
          articulo['pesable'],
          articulo['pesableporunidad'],
          articulo['nombremarca'],
          articulo['marcareal'],
          articulo['cantidad'],
          Array.isArray(articulo['coeficiente'])?1:articulo['coeficiente'],
          articulo['preciopor'],
          Array.isArray(articulo['unidaddmedida'])?'':articulo['unidaddmedida'].trim(),
          articulo['cantidadmayorista'],
          articulo['preciomayorista'],
          Array.isArray(articulo['etiquetamedida'])?'':articulo['etiquetamedida'].trim(),
          imgenurl,
          -1,
          remplasoss || [],
          null,
          null,
          articulo['fraccion'],
          articulo['ppfraccion']
        )
        //console.log('tareasFinales (registro)',registro)

        this.tags.forEach(
          tag => {
            for (let articulo of tag.articulos) {
              if (articulo.codigo == registro.getCodigo() && articulo.marca == registro.getMarca()) {
                registro.setTag({ tag: tag.tag });

              }
            }
          }
        );

        if (registro.getDescripCion()[0] === "*") {
          let newDesc = registro.getDescripCion().slice(1);
          registro.descripcion = newDesc;
        }
        //console.log('tareasFinales registros.push(registro)',registro)
        articulosDeSucursalActiva.push(registro)
      }
    );
    //console.log('tareasFinales this.iniciarsucursal(registros)',registros)
    this.iniciarsucursal(articulosDeSucursalActiva);
    let fecha = new Date();
    this.ofertas = [];
    for (let ofertas of this.ofertasEnlazadas) {
      this.sucursalactiva.articulos.forEach(
        articulo => {
          if (articulo.getCodigo() == ofertas.codigo && articulo.getMarca() == ofertas.marca) {
            articulo.setdeOferta(ofertas);
            this.ofertas.push(articulo);
          }
        }
      );
    }
    //console.log('tareas finales ofetas', this.ofertas)
    this.ofertas = this.ofertas.filter((item, index) => {
      return this.ofertas.indexOf(item) === index;
    });

    let newOfertas1 = [];
    for (let i = 0; i < this.ofertas.length; i++) {
      const element = this.ofertas[i];
      if (element.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {
        newOfertas1.push(element);
      }
    }
    this.ofertas = newOfertas1;
    this.paginadoOfertas();
    this.asignarPromociones();
    if (this.user != null) {
      let sucursales = this.getSucursales();
      let sucTrue = false;
      for (let i = 0; i < sucursales.length; i++) {
        const element = sucursales[i];
        if (element.sucursal == this.user.sucursal) {
          sucTrue = true;
        }
      }
      // console.log(sucTrue);
      if (sucursales.length > 1) {
        if (sucTrue == false) {
          if (localStorage.getItem('currentUser') != null || localStorage.getItem('currentUser') != undefined) {
            this.modal.open('sucursal-modal-1');
          }
        }
      }
      await this.iniciarCarrito().toPromise();
      // this.cleanUnpaidOrders().toPromise();
    }
  }

  /**
   * Actualiza los datos del usuario en servicio en base a la peticion clienteWeb
   * @param clienteWeb respuesta de /clienteweb
   */
  actualizarUsuario(clienteWeb : ClienteWeb) {
    this.user.ciudad = clienteWeb.ciudad;
    this.user.clienteol = clienteWeb.clienteol
    this.user.direccion = clienteWeb.direccion.trim();
    this.user.dni = clienteWeb.dni;
    this.user.email = clienteWeb.email.trim();
    this.user.empresa = clienteWeb.empresa;
    this.user.fechaNacimiento = clienteWeb.fechanacimiento;
    this.user.nombre = clienteWeb.nombre.trim();
    this.user.sucursal = clienteWeb.sucursal;
    this.user.telefonos = clienteWeb.telefonos.trim();
    if (this.user.sucursal) {
      this.sucursales.forEach(
        sucursal => {
          if (this.user.sucursal == sucursal.sucursal) {
            this.sucursalactiva = sucursal;
            this.user.sucursalNombre = ' ' + sucursal.nombre;
            this.sucursal_defecto = this.user.sucursal.toString();
          }
        }
      )
    }

    this.userProvider.next(this.user);
  }

  obtenerdatosdeUsuario() {
    let params = new HttpParams();
    //console.log('obtenerdatosdeUsuario clienteweb')
    return this.getGenericoSeguro(params, 'clienteweb');

  }

  obtenerdatosdeUsuarioV2() {
    let params = new HttpParams();
    return this.getGenerico(params, 'clienteweb');

  }

  barjarMarcas() {
    let params = new HttpParams();
    let cloudinaryURL;
    let cloudinaryCloud;
    let cloudinaryID = '';

    return this.getGenerico(params, 'marcasweb').pipe(tap((data) => {
      data['registros'].forEach(element => {
        let imgenurl = new URL(element['imagen'], this.environmenturl).toString();

        cloudinaryURL = element['imagen'];

        if (this.environment.endpoint + this.environment.endpointversion == imgenurl) {
          imgenurl = undefined;
        } else {
          let imgSplit = imgenurl.split("/");
          let imgTransformation = "q_auto:good/c_fit";
          imgenurl = imgSplit[0] + "/" + imgSplit[1] + "/" + imgSplit[2] + "/" + imgSplit[3] + "/" + imgSplit[4] + "/" + imgSplit[5] + "/" + imgTransformation + "/" + imgSplit[6] + "/" + imgSplit[7];
        }

        if (this.environment.endpoint + this.environment.endpointversion == cloudinaryURL) {
          cloudinaryURL = undefined;
        }

        let cloudSplit = cloudinaryURL.split("/");
        cloudinaryCloud = cloudSplit[3];
        cloudinaryID = cloudSplit[cloudSplit.length - 1];

        if (cloudinaryCloud != '') {
          const cld = new Cloudinary({
            cloud: {
              cloudName: cloudinaryCloud
            }
          });

          if (cloudinaryID != '') {
            this.img = cld.image(cloudinaryID);
            cloudinaryID = '';
          }
        }

        this.marcasDestacadas.push({
          articulos: element['articulos'],
          destacada: element['destacada'],
          imagen: imgenurl,
          marca: element['marca'],
          nombre: element['nombre'],
          urlexterna: element['urlexterna'],
        })
      });
    }));
  }

  getMarcasDestacadas() {
    let marcas: MarcasEcommerce[] = [];
    this.marcasDestacadas.forEach(k => {
      if (k.destacada == "S") {
        marcas.push(k);
      }
    })
    return marcas;
  }

  getOfertas() {
    return this.ofertas;
  }

  getNumPagOfertas() {
    return this.ofertas.length / 30;
  }

  paginadoOfertas() {
    let paginado: ArticulosGeneral[] = [];
    let paginas = this.ofertas.length / 30;
    this.paginadorProviderOfertas.next(paginas);
    if (paginas > 1) {
      for (let index = 0; index < 30; index++) {
        paginado.push(this.ofertas[index]);
      }
      this.paginaActualOfertas = 0;
    }
    else {
      paginado = this.ofertas;
      this.paginaActualOfertas = 0;
    }
  }

  barjarCategorias() {
    let params = new HttpParams();
    let cloudinaryURL;
    let cloudinaryCloud;
    let cloudinaryID = '';

    return this.getGenerico(params, 'categoriasweb').pipe(tap(data => {
      data['registros'].forEach(element => {
        let imgenurl = new URL(element['imagen'], this.environmenturl).toString();
        cloudinaryURL = element['imagen'];

        if (this.environment.endpoint + this.environment.endpointversion == imgenurl) {
          imgenurl = undefined;
        } else {
          let imgSplit = imgenurl.split("/");
          let imgTransformation = "q_auto:good/c_fit";
          imgenurl = imgSplit[0] + "/" + imgSplit[1] + "/" + imgSplit[2] + "/" + imgSplit[3] + "/" + imgSplit[4] + "/" + imgSplit[5] + "/" + imgTransformation + "/" + imgSplit[6] + "/" + imgSplit[7];
        }

        if (this.environment.endpoint + this.environment.endpointversion == cloudinaryURL) {
          cloudinaryURL = undefined;
        }

        let cloudSplit = cloudinaryURL.split("/");
        cloudinaryCloud = cloudSplit[3];
        cloudinaryID = cloudSplit[cloudSplit.length - 1];

        if (cloudinaryCloud != '') {
          const cld = new Cloudinary({
            cloud: {
              cloudName: cloudinaryCloud
            }
          });

          if (cloudinaryID != '') {
            this.img = cld.image(cloudinaryID);
            cloudinaryID = '';
          }
        }

        this.categoriasWeb.push({
          articulos: element['articulos'],
          categoria: element['categoria'],
          imagen: imgenurl,
          nombre: element['nombre'],
          orden: element['orden'],
          padre: element['padre'],
          subCategorias: [],
          desplegado: false,
        });
      });
      this.ordenarCategorias();
    }));
  }

  getExtraResultadosProvider() {
    return this.resultadoDeBusquedaSubject.asObservable();
  }

  getMovimientosMmax() {
    let params = new HttpParams();

    if (!this.pendingBeneficios) {
      this.pendingBeneficios = true;
      return this.getGenericoSeguro(params, 'ConsultaSaldo').pipe(map(data => {
        this.pendingBeneficios = false
        return data['registros'];
      }));
    }
  }

  barjarCategoriasdeArticulos() {
    let params = new HttpParams();
    let sucursalActiva;
    return this.getGenerico(params, 'categoriasarticulosweb').pipe(tap(data => {

      let newDataArt = [];
      for (let i = 0; i < data['registros'].length; i++) {
        const element = data['registros'][i];
        if (!this.containsObject(element, newDataArt)) {
          newDataArt.push(element);
        }
      }
      this.mapaPedidos = new Map();
      let midata: { marca: number, codigo: number, categoria: number, descripcion: string }[] = data['registros'] as any[];
      midata.forEach(k => {

        this.getActualSucursal().subscribe(data => {
          sucursalActiva = data;

          if (sucursalActiva != undefined) {
            sucursalActiva.articulos.forEach(l => {
              if (l.getMarca() == k.marca && l.getCodigo() == k.codigo) {
                if (this.mapaPedidos.has(k.categoria)) {
                  let arra = this.mapaPedidos.get(k.categoria);
                  let art = {
                    marca: k.marca,
                    codigo: k.codigo,
                    descripcion: l.getDescripCion()
                  }

                  if (!this.containsObject(art, arra)) {
                    arra.push(art);
                  }

                  arra.sort(this.preOrdenAlfabetico);
                  this.mapaPedidos.set(k.categoria, arra);
                }
                else {
                  this.mapaPedidos.set(k.categoria, [{ marca: k.marca, codigo: k.codigo, descripcion: l.getDescripCion() }]);
                }
              }
            })
          }
        })

      })
    }))


  }

  containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].marca == obj.marca && list[i].codigo == obj.codigo) {

        return true;
      }
    }

    return false;
  }

  private async ordenarCategorias() {
    let categorias: CategoriasWebMenu[] = [];
    this.categoriasWeb.forEach(
      (categoria : CategoriasWebMenu) => {
        if (categoria.padre == 0) {
          this.categoriasWeb.forEach(subcategoria => {
            if (categoria.categoria == subcategoria.padre) {
              this.categoriasWeb.forEach(tiercategoriatres => {
                if (subcategoria.categoria == tiercategoriatres.padre) {
                  subcategoria.subCategorias.push(tiercategoriatres);
                }
              })
              categoria.subCategorias.push(subcategoria);
            }
          })
          categorias.push(categoria);
        }

      }
    );
    this.categoriasWeb = categorias;
  }

  getCategoriasWeb() {
    let categorias: CategoriasWebMenu[] = [];
    this.categoriasWeb.forEach(k => {
      if (k.subCategorias.length != 0) {
        k.subCategorias.sort(this.ordenCategorias)
      }
      categorias.push(k);
    })
    categorias.sort(this.ordenCategorias);
    return categorias;
  }

  ordenCategorias = (a: CategoriasWebMenu, b: CategoriasWebMenu): number => {
    if (a.orden > b.orden) {
      return 1;
    }
    if (a.orden < b.orden) {
      return -1;
    }
    return 0;
  }

  bajarSucursales() {
    let params = new HttpParams();
    var fundentroOtraFun = () => {
      let sucursal = this.sucursales[0];
      if (sucursal) {
        console.log('SE ESTABLECIO UNA SUCURSAL DEFECTO' + ' ' + sucursal.nombre);
        this.sucursal_defecto = sucursal.sucursal.toString();
        this.sucursalactiva = sucursal;
        this.sucursalactiva.articulos = [];
        this.articulosSucursalDefecto = [];
      }
    }
    return this.getGenerico(params, 'sucursalesweb').pipe(tap(data => {
      let suc = data['registros'] as any[];
      //console.log('this.getGenerico(params, sucursalesweb)' ,suc)

      if (suc == undefined || suc.length < 0) {
        alert('Sin Sucursales');
      }

      let sucu: SucursalEcommerce;
      suc.forEach(sucursal => {
        sucu = sucursal as SucursalEcommerce;
        //console.log(sucu)
        sucu.abreviatura = sucu.abreviatura.trim();
        sucu.barrio = sucu.barrio.trim();
        sucu.calle = sucu.calle.trim();
        sucu.localidad = sucu.localidad.trim();
        sucu.nombre = sucu.nombre.trim();
        sucu.urlmap = sucu.urlmap.trim();
        sucu.articulos = [];
        //console.log('sucu', sucu)
        this.sucursales.push(sucu);
        if (sucu.sucursal.toString() === this.sucursal_defecto.toString()) {
          this.sucursalactiva = sucu;
          this.sucursalactiva.articulos = [];
        }

      })
      if (this.sucursalactiva) {
        if (!this.sucursalactiva.articulos) {
          fundentroOtraFun()
        }
      }
      else {
        fundentroOtraFun()
      }

      this.sucursalestemp.next(this.sucursales);
    }))
  }

  configuracionEcommerce() {
    let params = new HttpParams();
    return this.getGenerico(params, 'configuracionecommerce').pipe(tap(
      respuestaconfiguracionecommerce => {
        //console.log("Configuraciones Data: ", data);
        this.sucursal_defecto = respuestaconfiguracionecommerce['sucursal'];

        let rempla = respuestaconfiguracionecommerce['reemplazos'] as RemplazosMMax[];
        if (rempla != undefined) {
          this.remplazos = rempla;
          this.remplasosProvider.next(this.remplazos);
        }

        this.montoMinimo = respuestaconfiguracionecommerce['montoMinimo'];
        this.pagoDiferido.next(respuestaconfiguracionecommerce['pagoDiferido']);
        this.envioHabilitado.next(respuestaconfiguracionecommerce['envioHabilitado']);
        this.registroHabilitado.next(respuestaconfiguracionecommerce['registroHabilitado']);
        this.beneficios.next(respuestaconfiguracionecommerce['beneficios']);
        this.usaTurnos.next(respuestaconfiguracionecommerce['usaTurnos']);
        this.horasMinimasTurno.next(respuestaconfiguracionecommerce['horasMinimas']);
        this.codigoFlete = (respuestaconfiguracionecommerce['codigoFlete']);
        this.marcaFlete = (respuestaconfiguracionecommerce['marcaFlete']);
        this.sePermiteAgregarArticuloACarritoSinLogin = (respuestaconfiguracionecommerce['carroSinLogin'] === 'S')
      }
    ));
  }

  getURLMap() {
    let sucact = this.getSucursalActiva();
    if (sucact) {
      this.urlmap = sucact.urlmap;
      return this.urlmap;
    }
    else {
      return '';
    }
  }

  getCiudades() {
    let params = new HttpParams();
    if(this.getTotal() > 0){
      params = params.append('sucursal', this.getSucursalActiva().sucursal.toString());
      params = params.append('monto', this.getTotal().toString());
      params = params.append('unidades', this.getCantidadTotal().toString())
    }

    return this.getGenericoSeguro(params, 'ecomciudades').pipe(tap(data => {
      this.ciudades.next(data['registros']);
    }));
  }

  /**
   * Setea los datos para determinar si hay que agregar flete y generar el articulo de flete en el carro
   * @param datosDelEnvio datos a guardar
   */
  setDatosEnvio(datosDelEnvio : DatosEnvio) {
    this.datosEnvio = datosDelEnvio;
    if(datosDelEnvio){
      this.costoUltimoFlete = this.datosEnvio.costo
    }
  }

  getDatosEnvio() {
    return this.datosEnvio;
  }

  agregarItemEnvio(){
    let carrito = this.getCarrito();
    if(this.datosEnvio) {
      let articuloEnvio : ArticuloPeticionEnviar[] = [];

      articuloEnvio.push(
        {
          marca: parseInt(this.marcaFlete),
          codigo: parseInt(this.codigoFlete),
          cantidad: 1,
          precio: this.datosEnvio.costo,
          monto: this.datosEnvio.costo,
          reemplazo: 0,
        } as ArticuloPeticionEnviar
      );

      this.postGenericoSeguro('itempedidoweb', { articulos: articuloEnvio }).subscribe({
        next:data => {
          this.seAgregaronArtículosAlCarrito.next('nuevosItems')
          this.actualizarCarritoDeOtrasTabs();
          // console.log('agrega Item Envio', carrito)
        },
        error: error =>{
          // console.log('Error agrega Item Envio', error)
          this.sincronizandoCarritoConBack = false;
        }
      })
    }
    // else {
      // console.log('Sin envio')
      // carrito = carrito.filter(
      //   articuloCarro =>{
      //     return articuloCarro.getMarca() !== parseInt(this.marcaFlete) && articuloCarro.getCodigo() !== parseInt(this.codigoFlete)
      //   }
      // )
    // }
  }

  cleanUnpaidOrders() {
    let params = new HttpParams();
    return this.getGenericoSeguro(params, 'vaciarCarrito').pipe(tap(data => {
      // console.log(data);
    }));
  }

  getReemplazosProvider() {
    return this.remplasosProvider.asObservable();
  }

  getMontoMinimo() {
    return this.montoMinimo;
  }

  getPagoDiferido() {
    return this.pagoDiferido.asObservable();
  }

  getEnvioHabilitado() {
    return this.envioHabilitado.asObservable();
  }

  getRegistroHabilitado() {
    return this.registroHabilitado.asObservable();
  }

  getSucursalDefecto() {
    return this.sucursal_defecto;
  }

  returnBeneficios() {
    return this.beneficios.asObservable();
  }

  returnUsaTurnos() {
    return this.usaTurnos.asObservable();
  }

  returnHorasMinimasTurno() {
    return this.horasMinimasTurno.asObservable();
  }

  bajarImagen(articulo: ArticulosGeneral) {
    let params = new HttpParams();
    return this.httpClient.get(articulo.getFotoImagen(), {
      params, observe: 'response', responseType: 'blob'
    }).pipe(tap(data => {
      articulo.setMyBlob(data.body);
      articulo.setFoto(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(articulo.getMyBlob())));
    }), catchError(this.errorHandler));
  }

  setImagenModal(img) {
    this.imagenModal = img;
  }

  getImagenPeticion() {
    let params = new HttpParams();
    let pathImg = this.imagenModal.split('/')[4];
    return this.httpClient.get(this.environmenturl.toString() + 'imagenes/' + pathImg, {
      headers: { "Authorization": this.user.token }, params, observe: 'response', responseType: 'blob'
    }).pipe(tap(data => {
    }), catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return new Observable(err => {
      if (error.message) {
        // console.log(error.message);
      }

    });
  }

  descargImgaen(imagensrc: string,) {
    let params = new HttpParams();
    return this.httpClient.get(imagensrc, {
      params, observe: 'response', responseType: 'blob'
    }).pipe(map((data) => {
      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.body))
    }));

  }

  bajarArticulosPorSucursal(sucursal: string) {
    let params = new HttpParams();
    params = params.append('sucursal', sucursal);

    return this.getGenerico(params, 'articulosweb').pipe(tap(data => {
      //console.log('this.articulosSucursalDefecto antes', this.articulosSucursalDefecto)
      this.articulosSucursalDefecto = data['registros'];
      //console.log('this.articulosSucursalDefecto despues', this.articulosSucursalDefecto)
    }));
  }

  articulosWeb2(sucursal: string, art: string, rango: string, categoria: number, ofertas: string) {
    let params = new HttpParams();
    params = params.append('sucursal', sucursal);
    params = params.append('s', art);
    params = params.append('rangoregistros', rango);
    params = params.append('categoria', categoria.toString());
    params = params.append('ofertas', ofertas);

    return this.getGenerico(params, 'articulosweb2').pipe(tap(data => {
      let registro: ArticulosEcommerce;

      data['registros'].forEach(k => {
        let remplasoss: RemplazosMMax[] = []

        this.remplazos.forEach(k => {
          let remplaceme = Object.assign({}, k)
          remplaceme.idcss = this.generarid(4);
          remplaceme.activo = false;
          remplasoss.push(remplaceme);
        });
        let imgenurl = new URL(k['foto'], this.environmenturl).toString();
        registro = new ArticulosEcommerce
          (
            k['marca'],
            k['codigo'],
            k['precio'],
            k['precionormal'],
            k['cmax'],
            k['da'],
            k['descripcion'],
            k['unidadesxb'] | 0,
            k['presentacion'],
            k['pesoxb'] | 0,
            k['pesable'],
            k['pesableporunidad'],
            k['nombremarca'],
            k['marcareal'],
            k['cantidad'],
            k['coeficiente'],
            k['preciopor'],
            k['unidaddmedida'].trim(),
            k['cantidadmayorista'],
            k['preciomayorista'],
            k['etiquetamedida'].trim(),
            imgenurl,
            -1,
            remplasoss || [],
          )

        this.tags.forEach(k => {
          for (let articulo of k.articulos) {
            if (articulo.codigo == registro.getCodigo() && articulo.marca == registro.getMarca()) {
              registro.setTag({ tag: k.tag });

            }
          }
        });

        if (registro.getDescripCion() != undefined) {
          if (registro.getDescripCion()[0] === "*") {
            let newDesc = registro.getDescripCion().slice(1);
            registro.descripcion = newDesc;
          }
        }
        this.articulos2.push(registro);
      });
    }));
  }

  getCarrito() {
    let carrito: ArticulosGeneral[] = [];
    this.sucursalactiva?.articulos.forEach(articulo => {
/*       if(k.isdePromo){
        console.log('articulo promo',k)
      } */
      if (articulo.getCantidad() > 0) {
        carrito.push(articulo);
      }
    })
    if(this.datosEnvio) {
      let fleteArt = new ArticulosEcommerce(
        parseInt(this.marcaFlete),
        parseInt(this.codigoFlete),
        this.datosEnvio.costo,
        this.datosEnvio.costo,
        0,
        'Flete',
        'ENVIO A ' + this.datosEnvio.nombreCiudad, 1,
        'Codigo Postal: ' + this.datosEnvio.cp, 0, 0, 0,
        'FLETES',
        parseInt(this.marcaFlete),
        1000,
        1000,
        this.datosEnvio.costo,
        '',
        0,
        this.datosEnvio.costo,
        'Flete',
        undefined,
        2,
         [{ nombre: 'No Reemplazar', modo: '2', idcss: 'c9ft', activo: true }]
      );
      fleteArt.cantiadadpedido = 1;
      if (!this.containsObject(fleteArt, carrito)) {
        carrito.push(fleteArt);
      }
    }
    else {
      //console.log('NO FLETE')
      carrito = carrito.filter(
        articuloCarro =>{
          return articuloCarro.getMarca() !== parseInt(this.marcaFlete) && articuloCarro.getCodigo() !== parseInt(this.codigoFlete)
        }
      )
    }
    //console.log("carrito",carrito)
    return carrito;
  }

  getTotal() {
    let total = 0;
    this.getCarrito().forEach(
      articuloCarro => {
        //console.log(articuloCarro, this.datosEnvio)
        if (articuloCarro.getPrecioCalculado() != 0) {
          if (articuloCarro.misPromo.length != 0) {
            if (articuloCarro.getCantidad() % articuloCarro.misPromo[0].cantidadenPromo() === 0) {
              total += articuloCarro.misPromo[0].getPrecioCalculado();
              //console.log('articulocarro a no fraccion')
            }
            else {
              total += articuloCarro.getPrecioCalculado();
              //console.log('articulocarro b no fraccion')
            }
          }
          else {
            total += articuloCarro.getPrecioCalculado();
          }
        }
      }
    )

    return total;
  }
  /**
   * Devuelve la cantidad total de unidades en el carrito, si es fraccionado calcula cuantas fracciones del articulo hay (redondeo hacia arriba)
   * @returns cantidad total de items
   */
  getCantidadTotal() : number {
    let cantidadTotal : number = 0;
    this.getCarrito().forEach(
      articulo => {
        let cantidadArticulo : number = 0;
        cantidadArticulo = articulo.getCantidad();
        if(articulo.getFraccion()){
          cantidadArticulo = Math.ceil(articulo.getCantidad() / articulo.getFraccion())
          //console.log('getcantidadtotal',articulo.getCantidad(), articulo.getFraccion(), Math.floor(articulo.getCantidad() / articulo.getFraccion()), Math.ceil(articulo.getCantidad() / articulo.getFraccion()) )
        }
        cantidadTotal += cantidadArticulo;
      }
    );
    return cantidadTotal;
  }

  promocioneswebs(sucursal) {
    let params = new HttpParams();
    params = params.append('sucursal', sucursal)
    return this.getGenerico(params, 'promocioneswebec').pipe(tap({
      next:respuestapromocioneswebec => {
        //console.log('promocioneswebs', respuestapromocioneswebec)

        let cloudinaryURL;
        let cloudinaryCloud;
        let cloudinaryID = '';
        this.promocionesEcommerce = [];
        this.slidesArray = [];

        respuestapromocioneswebec['registros'].forEach(
          promocion => {
            let imgenurl = new URL(promocion['imagen'], this.environmenturl).toString();
            cloudinaryURL = promocion['imagen'];

            if (this.environment.endpoint + this.environment.endpointversion == imgenurl) {
              imgenurl = undefined;
            } else {
              let imgSplit = imgenurl.split("/");
              let imgTransformation = "q_auto:good/c_fit";
              imgenurl = imgSplit[0] + "/" + imgSplit[1] + "/" + imgSplit[2] + "/" + imgSplit[3] + "/" + imgSplit[4] + "/" + imgSplit[5] + "/" + imgTransformation + "/" + imgSplit[6] + "/" + imgSplit[7];
            }

            if (this.environment.endpoint + this.environment.endpointversion == cloudinaryURL) {
              cloudinaryURL = undefined;
            }

            let cloudSplit = cloudinaryURL.split("/");
            cloudinaryCloud = cloudSplit[3];
            cloudinaryID = cloudSplit[cloudSplit.length - 1];

            if (cloudinaryCloud != '') {
              const cld = new Cloudinary({
                cloud: {
                  cloudName: cloudinaryCloud
                }
              });

              if (cloudinaryID != '') {
                this.img = cld.image(cloudinaryID);
                cloudinaryID = '';
              }
            }

            if (promocion['porcentaje'] != 0 && promocion['tipo'] == "P") {
              this.promocionesEcommerce.push(new PorcentajePromo(
                promocion['abv'].trim(),
                promocion['cantidad1'],
                promocion['cantidad2'],
                promocion['desde'],
                promocion['hasta'],
                promocion['idpromocion'],
                imgenurl,
                promocion['monto'],
                promocion['nombre'],
                promocion['orden'],
                promocion['porcentaje'],
                promocion['regla1'].trim(),
                promocion['regla2'].trim(),
                promocion['sucursal'],
                promocion['lunes'],
                promocion['martes'],
                promocion['miercoles'],
                promocion['jueves'],
                promocion['viernes'],
                promocion['domingo'],
                promocion['sabado'],
                promocion['tipo'],
                this.generarid(5),
                promocion['marca'],
                promocion['codigo'],
                false
                ))
              }

            if (promocion['monto'] > 0 && promocion['tipo'] == "P") {
              this.promocionesEcommerce.push(new MontoPromo(
                promocion['abv'].trim(),
                promocion['cantidad1'],
                promocion['cantidad2'],
                promocion['desde'],
                promocion['hasta'],
                promocion['idpromocion'],
                imgenurl,
                promocion['monto'],
                promocion['nombre'],
                promocion['orden'],
                promocion['porcentaje'],
                promocion['regla1'].trim(),
                promocion['regla2'].trim(),
                promocion['sucursal'],
                promocion['lunes'],
                promocion['martes'],
                promocion['miercoles'],
                promocion['jueves'],
                promocion['viernes'],
                promocion['domingo'],
                promocion['sabado'],
                promocion['tipo'],
                this.generarid(5),
                promocion['marca'],
                promocion['codigo'],
                false
              ))
            }

            if (promocion['monto'] < 0 && promocion['tipo'] == "P") {
              this.promocionesEcommerce.push(new DiferenciaPromo(
                promocion['abv'].trim(),
                promocion['cantidad1'],
                promocion['cantidad2'],
                promocion['desde'],
                promocion['hasta'],
                promocion['idpromocion'],
                imgenurl,
                promocion['monto'],
                promocion['nombre'],
                promocion['orden'],
                promocion['porcentaje'],
                promocion['regla1'].trim(),
                promocion['regla2'].trim(),
                promocion['sucursal'],
                promocion['lunes'],
                promocion['martes'],
                promocion['miercoles'],
                promocion['jueves'],
                promocion['viernes'],
                promocion['domingo'],
                promocion['sabado'],
                promocion['tipo'],
                this.generarid(5),
                promocion['marca'],
                promocion['codigo'],
                false
              ))
            }

            if (promocion['tipo'] == "C" && promocion['imagen'] != "") {
              this.carrouselSlide.next([new DiferenciaPromo(
                promocion['abv'].trim(),
                promocion['cantidad1'],
                promocion['cantidad2'],
                promocion['desde'],
                promocion['hasta'],
                promocion['idpromocion'],
                imgenurl,
                promocion['monto'],
                promocion['nombre'],
                promocion['orden'],
                promocion['porcentaje'],
                promocion['regla1'].trim(),
                promocion['regla2'].trim(),
                promocion['sucursal'],
                promocion['lunes'],
                promocion['martes'],
                promocion['miercoles'],
                promocion['jueves'],
                promocion['viernes'],
                promocion['domingo'],
                promocion['sabado'],
                promocion['tipo'],
                this.generarid(5),
                promocion['marca'],
                promocion['codigo'],
                false
              )]);
              this.getCarrouselSlides();
            }

            const cld = new Cloudinary({
              cloud: {
                cloudName: cloudinaryCloud
              }
            });

            this.img = cld.image(cloudinaryID);
            cloudinaryID = '';

        })
        //console.log(this.promocionesEcommerce)
      }
    }));
  }

  getCarrouselSlides() {
    this.carrouselSlide.subscribe(data => {
      if (data.length != 0) {
        if (this.slidesArray.length == 0) {
          this.slidesArray.push(data[0]);
        } else {
          if (!this.containsSlide(data[0], this.slidesArray)) {
            this.slidesArray.push(data[0]);
          }
        }
      }
      this.slidesArrayObservable.next(this.slidesArray);
    });
  }

  returnSlides() {
    return this.slidesArrayObservable.asObservable();
  }

  containsSlide(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].imagen == obj.imagen) {
        return true;
      }
    }
    return false;
  }

  eliminarPromos(articulo: ArticulosGeneral) {
    articulo.setCantidad(0);
    articulo.setModificacionPrecio(0);
    articulo.getPromos().forEach((promo: PromocionesEccomerce) => {
      promo.resetArticulos();
    });
  }

  async sumarPromo(promocion: ArticulosGeneral, numero: number) {
    promocion.forzarAddPromo(numero);
  }

  sincronizarCarritoFrontyBack() {
    this.carritoObservables.unshift(this.enviarArticulosNuevosPetición())
  }

  /**
   * Obtiene el carrito del usuario, Si hay un carrito offline cargado levanta el modal correspondiente
   * para elegir si combinarlos o no
   * @returns el observable para ejecutar la consulta async
   */
  iniciarCarrito(){
    let params = new HttpParams();
    this.sincronizandoCarritoConBack = true;
    //console.log('iniciarCarrito sincronizandoCarritoConBack ', this.sincronizandoCarritoConBack)
    return this.getGenericoSeguro(params, 'itempedidoweb').pipe(tap({
      next:itemscarrito => {
        this.sincronizandoCarritoConBack = false;
        //console.log('iniciar carrito itempedidoweb respuesta (en uso)',itemscarrito)
        const backupCarritoWeb : BackUptArticulo[] = itemscarrito['registros'];
        const ambosCarritosNoVacios = this.backUpCarritoOffline.length && backupCarritoWeb.length;
        if(ambosCarritosNoVacios){
          this.modal.open('hay-carrito-offline');
          let obs = this.getHayCarritoOfflineRespuestaObservable().subscribe({
            next: respuestaUsuario =>{
              this.sincronizandoCarritoConBack = false;
              this.modal.close('hay-carrito-offline')
              if(respuestaUsuario === 'DESCARTAR CARRITO WEB'){
                const descartarCarritoWeb = true;
                this.cargarBackupsEnCarrito(descartarCarritoWeb, backupCarritoWeb, this.backUpCarritoOffline);
                this.terminoDeIniciarCarro.next('Cargado Carro')
              }
              if(respuestaUsuario === 'COMBINAR CARRITOS'){
                const combinarCarritoWeb = false;
                this.cargarBackupsEnCarrito(combinarCarritoWeb, backupCarritoWeb, this.backUpCarritoOffline);
                this.terminoDeIniciarCarro.next('Cargado Carro')
              }
              obs.unsubscribe();
            },
            error: error=>{
              this.sincronizandoCarritoConBack = false;
            }
          })
          return
        }
        this.cargarBackupsEnCarrito(false, backupCarritoWeb, this.backUpCarritoOffline);
        this.terminoDeIniciarCarro.next('Cargado Carro')
      },
      error: error=>{
        this.sincronizandoCarritoConBack = false;
      }
    }))
  }
  /**
   * Observable para notificar respuesta del usuario en modal hay-carrito-offline
   * @returns observable para suscribirse
   */
  getHayCarritoOfflineRespuestaObservable() : Observable<string>{
    return this.hayCarritoOfflineRespuestaSubject.asObservable();
  }

  /**
   * Notifica a traves del observable la respuesta del usuario en modal hay-carrito-offline
   * @param respuestaUsuario string con la opción elegida
   */
  enviarCarritoOfflineRespuesta(respuestaUsuario : 'DESCARTAR CARRITO WEB' | 'COMBINAR CARRITOS'){
    this.hayCarritoOfflineRespuestaSubject.next(respuestaUsuario)
  }

  /**
   * Recorre todos los articulos y setea las cantidades segun los backups utilizados
   * @param descartarCarritoweb setea si se debe descartar el carrito previo del usuario
   * @param backupCarritoWeb  articulos del carrito del usuario
   * @param backupCarritoOffline articulos del carrito cargado sin login
   */
  cargarBackupsEnCarrito(descartarCarritoweb : boolean,backupCarritoWeb: BackUptArticulo[], backupCarritoOffline : BackUptArticulo[]) {
    //console.log('guardarCarritoWebEnFront', this.user)
    this.sucursalactiva.articulos.forEach(
      (articulo : ArticulosEcommerce) => {
        let articuloBackupWebEncontrado : boolean = false;
        let articuloBackupOfflineEncontrado : boolean = false;
        backupCarritoWeb.forEach(
          (articuloBackupWeb : BackUptArticulo) =>{
            if ((articuloBackupWeb.marca === articulo.getMarca()) && (articuloBackupWeb.codigo === articulo.getCodigo())) {
              articulo.setCantidadInicial(articuloBackupWeb.cantidad);
              articulo.setCantidad(articuloBackupWeb.cantidad);
              //si queremos descartarlo se lo tenemos que comunicar al back,
              //entonces lo seteamos en 0 asi hay diferencia con la cant inicial y se comunica el descarte
              if(descartarCarritoweb){
                articulo.setCantidad(0);
              }
              this.tienePromoArticulo(articulo).forEach(
                (promo : PromocionesEccomerce) => {
                  articulo.setPromo(promo);
                }
              );
              articulo.operacionesExtras();
              articuloBackupWebEncontrado = true;
            }
          }
        )
        if(articuloBackupWebEncontrado){
          backupCarritoWeb = backupCarritoWeb.filter(
            (articuloBackupWeb : BackUptArticulo) =>{
              const articuloAfiltrar = (articuloBackupWeb.marca === articulo.getMarca()) && (articuloBackupWeb.codigo === articulo.getCodigo());
              return !articuloAfiltrar;
            }
          )
        }

        backupCarritoOffline.forEach(
          (articuloBackupOffline : BackUptArticulo) =>{
            if ((articuloBackupOffline.marca === articulo.getMarca()) && (articuloBackupOffline.codigo === articulo.getCodigo())) {
              articulo.agregarCantidad(articuloBackupOffline.cantidad);
              //console.log('agregado de carrito offline', articulo,backupCarritoOffline)
              articuloBackupOfflineEncontrado = true;
            }
          }
        )
        if(articuloBackupOfflineEncontrado){
          backupCarritoOffline = this.backUpCarritoOffline.filter(
            (articuloBackupOffline : BackUptArticulo) =>{
              const articuloAfiltrar = (articuloBackupOffline.marca === articulo.getMarca()) && (articuloBackupOffline.codigo === articulo.getCodigo())
              return !articuloAfiltrar
            }
          )
          this.backUpCarritoOffline = backupCarritoOffline;
        }
      }
    );
    this.enviarArticulosNuevosPetición().subscribe({
      next:terminoDeEnviar=>{
        this.terminoDeIniciarCarro.next('Cargado Carro')
      }
    });
  }

  getTerminoDeIniciarCarro(){
    return this.terminoDeIniciarCarro.asObservable()
  }

  /**
   * @deprecated siendo reemplazada por enviarArticulosNuevosPetición()
   */
  ajustarCuentas() : Observable<void> {
    return new Observable<void>(sub => {
      //console.log('ajustar cuentas')
      let user = this.user;
      //console.log('ajustar cuentas user',user)

      if (!user) {
        user = JSON.parse(localStorage.getItem('currentUser'));
      }
      //console.log('ajustar cuentas',user)
      if (user) {
        let articulosAlcambio = [];
        // let articulosAlcambio2 = [];
        //se revisan todos los articulos por si se elije la opcion de recompra y varios articulos son actualizados al mismo tiempo
        this.sucursalactiva.articulos.forEach(
          articulo => {
          //console.log('ajustarcuentas articulos ', articulo)
          if ((articulo as ArticulosGeneral).getCantidadInicial() != (articulo as ArticulosGeneral).getCantidad()) {
            //console.log('ajustarcuentas (articulo as ArticulosGeneral).getCantidadInicial() != (articulo as ArticulosGeneral).getCantidad()', (articulo as ArticulosGeneral).getCantidadInicial() != (articulo as ArticulosGeneral).getCantidad())
            if ((articulo as ArticulosGeneral).getCantidad() > (articulo as ArticulosGeneral).getCantidadInicial()) {
              if (articulo.isdeoferta && !articulo.isdePromo) {
                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioOferta(),
                  monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecioOferta(),
                  reemplazo: articulo.getRemplazo(),
                });
              }
/*             if (articulo.isdeoferta && articulo.isdePromo) {
                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioCalculado() / (articulo as ArticulosGeneral).getCantidad(),
                  monto: (articulo as ArticulosGeneral).getPrecioCalculado(),
                  reemplazo: articulo.getRemplazo(),
                }); */
                if (articulo.isdeoferta && articulo.isdePromo) {
                  articulosAlcambio.push({
                    marca: (articulo as ArticulosGeneral).getMarca(),
                    codigo: (articulo as ArticulosGeneral).getCodigo(),
                    cantidad: (articulo as ArticulosGeneral).getCantidad(),
                    precio: (articulo as ArticulosGeneral).getPrecioOferta(),
                    monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecioOferta(),
                    reemplazo: articulo.getRemplazo(),
                  });

              }
              if (!articulo.isdeoferta && articulo.isdePromo) {
                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioCalculado() / (articulo as ArticulosGeneral).getCantidad(),
                  monto: (articulo as ArticulosGeneral).getPrecioCalculado(),
                  reemplazo: articulo.getRemplazo(),
                });
              }
              if (!articulo.isdeoferta && !articulo.isdePromo) {
                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecio(),
                  monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecio(),
                  reemplazo: articulo.getRemplazo(),
                });
              }
              // articulosAlcambio2.push({
              //   marca: (articulo as ArticulosGeneral).getMarca(),
              //   codigo: (articulo as ArticulosGeneral).getCodigo(),
              //   cantidad: (articulo as ArticulosGeneral).getCantidad(),
              //   precio: (articulo as ArticulosGeneral).getPrecio(),
              //   monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecio(),
              //   reemplazo: articulo.getRemplazo(),
              // });
              (articulo as ArticulosGeneral).setCantidadInicial((articulo as ArticulosGeneral).getCantidad());
            }
            else {
              if (articulo.isdeoferta && !articulo.isdePromo) {
                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioOferta(),
                  monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecioOferta(),
                  reemplazo: articulo.getRemplazo(),
                });
              }
              if (articulo.isdeoferta && articulo.isdePromo) {
                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioCalculado() / (articulo as ArticulosGeneral).getCantidad(),
                  monto: (articulo as ArticulosGeneral).getPrecioCalculado(),
                  reemplazo: articulo.getRemplazo(),
                });
              }
              if (!articulo.isdeoferta&& articulo.isdePromo) {
                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecioCalculado() / (articulo as ArticulosGeneral).getCantidad(),
                  monto: (articulo as ArticulosGeneral).getPrecioCalculado(),
                  reemplazo: articulo.getRemplazo(),
                });
              }
              if (!articulo.isdeoferta && !articulo.isdePromo) {
                articulosAlcambio.push({
                  marca: (articulo as ArticulosGeneral).getMarca(),
                  codigo: (articulo as ArticulosGeneral).getCodigo(),
                  cantidad: (articulo as ArticulosGeneral).getCantidad(),
                  precio: (articulo as ArticulosGeneral).getPrecio(),
                  monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecio(),
                  reemplazo: articulo.getRemplazo(),
                });
              }

              // articulosAlcambio2.push({
              //   marca: (articulo as ArticulosGeneral).getMarca(),
              //   codigo: (articulo as ArticulosGeneral).getCodigo(),
              //   cantidad: (articulo as ArticulosGeneral).getCantidad(),
              //   precio: (articulo as ArticulosGeneral).getPrecio(),
              //   monto: (articulo as ArticulosGeneral).getCantidad() * (articulo as ArticulosGeneral).getPrecio(),
              //   reemplazo: articulo.getRemplazo(),
              // });
              (articulo as ArticulosGeneral).setCantidadInicial((articulo as ArticulosGeneral).getCantidad());
            }
          }
        });
        if (articulosAlcambio.length == 0) {
          sub.next();
          sub.complete();
          return;
        }

        if (localStorage.getItem('instancia') == undefined) {
          localStorage.setItem('instancia', '0');
        }

        // this.postGenericoSeguro('promocionweb2', { articulos: articulosAlcambio2, instancia: localStorage.getItem('instancia') }).subscribe(data => {
        //   if (data['instancia'] != undefined) {

        //     //console.log('ajustar cuentas post promocionweb2', data)
        //     localStorage.setItem('instancia', data['instancia']);
        //   }
        // })

        // this.postGenericoSeguro2('itempedidoweb2', { articulos: articulosAlcambio2 }).subscribe(data => {
        //   console.log("postGenericoSeguro2('itempedidoweb2'",data);
        // })

        this.postGenericoSeguro('itempedidoweb', { articulos: articulosAlcambio }).subscribe(data => {
            sub.next();
            sub.complete();
            this.seAgregaronArtículosAlCarrito.next('nuevosItems')
        })
      }
      sub.next();
      sub.complete();
    });
  }

  /**
   * Revisa si hay una diferencia entre la cantidad inicial y actual de todos los articulos, si lo hay manda la actualizacion en peticion.
   * Calcula las promociones y ofertas y genera los objetos promocion en peticion.
   * @returns un observable para llamar la funcion asíncronamente
   */
  enviarArticulosNuevosPetición() : Observable<void> {
    return new Observable<void>(sub => {
      let user = this.user;
      //console.log('enviarArticulosNuevosPetición user',user)
      if (user === undefined) {
        user = JSON.parse(localStorage.getItem('currentUser'));
      }
      this.seAgregaronArtículosAlCarrito.next('nuevosItems')
      //console.log('enviarArticulosNuevosPetición user',user)
      if (user !== undefined) {
        let articulosAlcambio : ArticuloPeticionEnviar[] = [];
        let objetosPromocion : ArticuloPeticionEnviar[] = [];
        // let articulosAlcambio2 : ArticuloPeticionEnviar[] = [];
        //se revisan todos los articulos por si se elije la opcion de recompra y varios articulos son actualizados al mismo tiempo
        this.sucursalactiva.articulos.forEach(
          (articulo : ArticulosEcommerce) => {
            //console.log('ajustarcuentas articulos ', articulo)
            const huboCambioEnCantidadDelArtículo : boolean = articulo.getCantidadInicial() !== articulo.getCantidad()
            if (huboCambioEnCantidadDelArtículo) {
              this.sincronizandoCarritoConBack = true;
              //console.log('huboCambioEnCantidadDelArtículo', huboCambioEnCantidadDelArtículo, articulo)
              if (articulo.isdeoferta) {
                articulosAlcambio.push(
                  {
                    marca: articulo.getMarca(),
                    codigo: articulo.getCodigo(),
                    cantidad: articulo.getCantidad(),
                    precio: articulo.getPrecioOferta(),
                    monto: articulo.getPrecioOferta() * articulo.getCantidad(),
                    reemplazo: articulo.getRemplazo(),
                  } as ArticuloPeticionEnviar
                );
                if(articulo.isdePromo){
                  objetosPromocion = this.calcularMontoDePromociónDeArticulo(articulo, articulo.getPrecioOferta())
                }
              }
              else{
                articulosAlcambio.push(
                  {
                    marca: articulo.getMarca(),
                    codigo: articulo.getCodigo(),
                    cantidad: articulo.getCantidad(),
                    precio: articulo.getPrecio(),
                    monto: articulo.getPrecio() * articulo.getCantidad() ,
                    reemplazo: articulo.getRemplazo(),
                  } as ArticuloPeticionEnviar
                );
                if(articulo.isdePromo){
                  objetosPromocion = this.calcularMontoDePromociónDeArticulo(articulo, articulo.getPrecio())
                }
              }
              //para la nueva peticion que se esta trabajando
              // articulosAlcambio2.push(
              //   {
              //     marca: articulo.getMarca(),
              //     codigo: articulo.getCodigo(),
              //     cantidad: articulo.getCantidad(),
              //     precio: articulo.getPrecio(),
              //     monto: articulo.getCantidad() * articulo.getPrecio(),
              //     reemplazo: articulo.getRemplazo(),
              //   } as ArticuloPeticionEnviar
              // );
              (articulo).setCantidadInicial(articulo.getCantidad());
            }
/*
            if(this.datosEnvio) {
              let fleteArt = new ArticulosEcommerce(
                parseInt(this.marcaFlete),
                parseInt(this.codigoFlete),
                this.datosEnvio.costo,
                this.datosEnvio.costo,
                0, 'Flete', 'ENVIO A ' + this.datosEnvio.nombreCiudad, 1,'Codigo Postal: ' + this.datosEnvio.cp, 0, 0, 0,'FLETES',
                parseInt(this.marcaFlete),1000,1000,this.datosEnvio.costo,'',0,this.datosEnvio.costo,'Flete',undefined,2,
                [{ nombre: 'No Reemplazar', modo: '2', idcss: 'c9ft', activo: true }]
              );

              articulosAlcambio.push(
                {
                  marca: fleteArt.getMarca(),
                  codigo: fleteArt.getCodigo(),
                  cantidad: fleteArt.getCantidad(),
                  precio: fleteArt.getPrecio(),
                  monto: fleteArt.getPrecio(),
                  reemplazo: fleteArt.getRemplazo(),
                } as ArticuloPeticionEnviar
              );
            }*/
          }
        );

        if (articulosAlcambio.length == 0) {
          this.sincronizandoCarritoConBack = false;
          sub.next();
          sub.complete();
          return;
        }

        if(objetosPromocion.length){
          articulosAlcambio = articulosAlcambio.concat(objetosPromocion)
        }

        console.log('enviarArticulosNuevosPetición objetos post', articulosAlcambio);
        if (localStorage.getItem('instancia') == undefined) {
          localStorage.setItem('instancia', '0');
        }
        console.log('sincronizandoCarritoConBack',this.sincronizandoCarritoConBack)
        // this.postGenericoSeguro('promocionweb2', { articulos: articulosAlcambio2, instancia: localStorage.getItem('instancia') }).subscribe({
        //   next:data => {
        //     this.sincronizandoCarritoConBack = false;
        //     if (data['instancia'] != undefined) {
        //       //console.log('ajustar cuentas post promocionweb2', data)
        //       localStorage.setItem('instancia', data['instancia']);
        //     }
        //   },
        //   error: error=>{
        //     this.sincronizandoCarritoConBack = false;
        //   }
        // })
        // this.postGenericoSeguro2('itempedidoweb2', { articulos: articulosAlcambio2 }).subscribe({
        //   next:data => {
        //     this.sincronizandoCarritoConBack = false;
        //     console.log("postGenericoSeguro2('itempedidoweb2'",data);
        //   },
        //   error:error=>{
        //     this.sincronizandoCarritoConBack = false;
        //   }
        // })
        this.postGenericoSeguro('itempedidoweb', { articulos: articulosAlcambio }).subscribe({
          next:data => {
            this.sincronizandoCarritoConBack = false;
            sub.next();
            sub.complete();
            this.seAgregaronArtículosAlCarrito.next('nuevosItems');
            this.actualizarCarritoDeOtrasTabs();
            console.log('actualizarcarito')
          },
          error: error =>{
            this.sincronizandoCarritoConBack = false;
          }
        })
      }
      sub.next();
      sub.complete();
    });
  }

  /**
   * Calcula el objeto promoción que realiza el descuento del artículo
   * @param articulo artículo al que se aplica la promoción
   * @param precioArticulo precio a usar del artículo en los calculos (puede ser o no precio de oferta)
   * @returns objeto promoción que compensa el valor original de los artículos
   */
  calcularMontoDePromociónDeArticulo(articulo : ArticulosEcommerce, precioArticulo : number) : ArticuloPeticionEnviar[]{
    console.log('calcularMontoDePromociónDeArticulo mispromos',articulo.misPromo)
    let objetosPromocion : ArticuloPeticionEnviar[] = [];
    let cantidadDeArticulo = articulo.getCantidad();
    const promocionesDelArticulo = articulo.misPromo.slice().sort(
      (promoA,promoB) =>{
        return promoA.getOrden() - promoB.getOrden()
      }
    )
    promocionesDelArticulo.forEach(
      promocion => {
        if(promocion.getregla2()){
          console.log('calcularMontoDePromociónDeArticulo promo combo falta implementar');
          return
        }
        while(cantidadDeArticulo >= promocion.getCantidad1()){
          console.log('calcularMontoDePromociónDeArticulo promocion', promocion)
          if(promocion instanceof PorcentajePromo){
            console.log('porcentaje',promocion.montoTotalADescontar, precioArticulo,promocion.getCantidad1(), promocion.getPorcentaje() / 100)
            promocion.montoTotalADescontar += precioArticulo * promocion.getCantidad1() * (promocion.getPorcentaje() / 100);
          }

          if(promocion instanceof MontoPromo){
            console.log('monto positivo')
            promocion.montoTotalADescontar += precioArticulo * promocion.getCantidad1() - promocion.getDescontado();
          }

          if(promocion instanceof DiferenciaPromo){
            console.log('monto negativo')
            promocion.montoTotalADescontar += promocion.getDescontado();
          }
          cantidadDeArticulo = cantidadDeArticulo - promocion.getCantidad1();
          console.log('cantidaddearticulo',cantidadDeArticulo)
        }
        if(promocion.montoTotalADescontar !== 0){
          objetosPromocion.push(
            {
              marca: promocion.getMarcaPromocion(),
              codigo: promocion.getCodigoPromocion(),
              cantidad: 1,
              precio: promocion.montoTotalADescontar * -1,
              monto: promocion.montoTotalADescontar * -1,
              reemplazo: 1,
              //nombre: promocion.getNombrePromocion()
            } as ArticuloPeticionEnviar
          )
          promocion.montoTotalADescontar = 0;
        }
      }
    )

    console.log('calcularMontoDePromociónDeArticulo promociones generadas', objetosPromocion)
    return objetosPromocion;
  }

  getPedidoWeb() {
    let params = new HttpParams();
    return this.getGenericoSeguro(params, 'itempedidoweb').pipe(tap((data) => { }));
  }

  /**
   * Se asignan los objetos promocion a los artículos correspondientes
   */
  async asignarPromociones() {
    let marcapromo1 = 0;
    let codigopromo1 = 0;

    let marcapromo2 = 0;
    let codigopromo2 = 0;
    //console.log('calcularpromociones promocioneseccomerce antes',this.promocionesEcommerce )

    this.promocionesEcommerce.sort(this.ordenarPromos);
    this.promocionesEcommerce?.forEach(
      promo => {
        if (promo.getregla1().trim().length > 0) {
          marcapromo1 = Number.parseInt(promo.getregla1().split('and')[0].trim().split('=')[1]);
          codigopromo1 = Number.parseInt(promo.getregla1().split('and')[1].trim().split('=')[1]);
        }
        if (promo.getregla2().trim().length > 0) {
          marcapromo2 = Number.parseInt(promo.getregla2().split('and')[0].trim().split('=')[1]);
          codigopromo2 = Number.parseInt(promo.getregla2().split('and')[1].trim().split('=')[1]);
        }
        //console.log('codigo y marca promo', codigopromo1,marcapromo1)

        this.sucursalactiva?.articulos?.forEach(
          articulo => {
            if (articulo.getMarca() == marcapromo1 && articulo.getCodigo() == codigopromo1) {
              //console.log('calcularpromociones articulo en promo antes set', articulo)
              promo.setArticulo1(articulo as ArticulosEcommerce);
              if (promo.getTipo() == "P") {
                articulo.setPromo(promo);
              }
              //console.log('calcularpromociones articulo en promo despues set', articulo)

            }
            if (articulo.getMarca() == marcapromo2 && articulo.getCodigo() == codigopromo2) {
              promo.setArticulo2(articulo as ArticulosEcommerce);
              if (promo.getTipo() == "P") {
                articulo.setPromo(promo);
              }
            }
            let promos = articulo.getPromos() as PromocionesEccomerce[];
            promos.sort(this.ordenarPromos);
          }
        );
      }
    )
    //console.log('calcularpromociones promocioneseccomerce despues',this.promocionesEcommerce )

  }

  ordenarPromos = (a: PromocionesEccomerce, b: PromocionesEccomerce): number => {
    if (a.getOrden() > b.getOrden()) {
      return 1;
    }
    if (a.getOrden() < b.getOrden()) {
      return -1;
    }
    return 0;
  }

  getPromocionesEcommerce(){
    return this.promocionesEcommerce
  }

  getArticulosDePromociones() {
    let articulosDepromociones: ArticulosGeneral[] = []
    this.promocionesEcommerce.forEach(
      promocion => {
        if (promocion.getArticulo1() != undefined) {
          articulosDepromociones.push(promocion.getArticulo1());
        }
      }
    )
    //console.log('getArticulosDePromociones', articulosDepromociones)
    return articulosDepromociones;
  }

  cambiarPagina(event: number) {
    let paginado: ArticulosGeneral[] = []
    this.paginaActual = event;
    for (let index = this.paginaActual * 60; index < ((this.paginaActual + 1) * 60); index++) {
      if (this.resultadosDebusqueda[index] != undefined) {
        paginado.push(this.resultadosDebusqueda[index]);
      }
    }
    this.lastSearch.next(paginado);
  }

  cambiarPaginaOferta(event: number) {
    let paginado: ArticulosGeneral[] = []
    this.paginaActualOfertas = event;
    for (let index = this.paginaActualOfertas * 30; index < ((this.paginaActualOfertas + 1) * 30); index++) {
      if (this.ofertas[index] != undefined) {
        paginado.push(this.ofertas[index]);
      }
    }
    this.lastSearch.next(paginado);
  }

  getPaginas() {
    return this.paginadorProvider.asObservable();
  }

  getPaginasOfertas() {
    return this.paginadorProviderOfertas.asObservable();
  }

  getArrayPaginas(paginas: any[]) {
    this.misPaginas = paginas;
  }

  getPaginaEmitter(emitter: EventEmitter<number>) {
    this.paginaEmitter = emitter;
  }

  cambiarPaginaElegida() {
    this.paginaElegida = {
      nombre: '1',
      numero: 0,
      activa: true
    }

    if (this.paginaEmitter !== undefined) {
      let pagine = 0;
      let index = 0;
      this.misPaginas.forEach(k => {
        k.activa = false;
        if (this.paginaElegida.numero == k.numero) {
          k.activa = true;
          pagine = index;
        }
        index++;
      });
      return this.paginaEmitter.emit(this.paginaElegida.numero);
    }
  }

  filtrarTag(tag: TagsEcommerce) {
    if (!this.tagsActivos.has(-100)) {
      this.tagsActivos.set(-100, this.resultadosDebusqueda)
    }
    let refiltrado: ArticulosGeneral[] = [];
    this.resultadosDebusqueda.forEach(k => {
      for (let tags of k.getTag()) {
        if (tags.tag == tag.tag) {
          refiltrado.push(k);
          break;
        }
      }
    });
    this.tagsActivos.set(tag.tag, refiltrado);
    this.resultadosDebusqueda = refiltrado;
    this.paginado();
  }

  cerrartag(tag: TagsEcommerce) {
    let tasact: number[] = [];
    let refiltrado: ArticulosGeneral[] = [];
    this.tagsActivos.forEach((art, tagnum) => {
      if (tag.tag == tagnum) {
        if (tag.selecionado) {
          tasact.push(tag.tag);
        }
      }
    });

    this.tagsActivos.get(-100).forEach(art => {
      art.getTag().forEach(tagArt => {
        tasact.forEach(tagsActivos => {
          if (tagArt.tag == tagsActivos) {
            refiltrado.push(art)
          }
        })
      })
    })
    if (tasact.length == 0) {
      this.resultadosDebusqueda = this.tagsActivos.get(-100);
    }
    else {
      this.resultadosDebusqueda = refiltrado;
    }
    this.paginado();
  }

  iniciarbusqueda(palabraBusqueda: string) {
    let myBusquedaExtras: ResultadosDeBusquedaInterface = {
      totaldeArticulos: 0,
      textodebusqueda: palabraBusqueda,
      tagsEnbusqueda: new Map(),
    };
    let contador = 0;
    this.paginaActual = 0;
    this.fueBusquedaEscrita = true;
    this.resultadosDebusqueda = [];
    let minirsultados: ArticulosGeneral[] = [];
    this.articulos2 = [];
    if (palabraBusqueda.length > 1) {
      this.sucursalactiva?.articulos?.forEach(k => {
        if (k.getDescripCion().toLocaleLowerCase().includes(palabraBusqueda.toLocaleLowerCase())) {
          this.resultadosDebusqueda.push(k);
          k.getTag().forEach(tag => {
            this.tags.forEach(tagreal => {
              tagreal.selecionado = false;
              if (tag.tag === tagreal.tag) {
                if (!(myBusquedaExtras.tagsEnbusqueda.has(tagreal.tag))) {
                  myBusquedaExtras.tagsEnbusqueda.set(tagreal.tag, tagreal);
                }
              }
            })
          })

          if (contador < 7) {
            minirsultados.push(k);
          }
          contador++;
          myBusquedaExtras.totaldeArticulos++;
        }
      });

      this.resultadosDebusqueda = this.busquedaPorNombre();
      this.numBusqueda = this.resultadosDebusqueda.length;
      this.resultadosDebusquedaOriginales = this.resultadosDebusqueda;
      myBusquedaExtras.totaldeArticulos = this.numBusqueda;

      this.minisearch.next(minirsultados);
      this.paginado();
      this.resultadoDeBusquedaSubject.next(myBusquedaExtras);
      this.seRealizoUnaBusquedaSubject.next('se hizo busqueda')
    }
    else {
      //console.log('palabrabusqueda',palabraBusqueda, palabraBusqueda === '')
      if(palabraBusqueda === '' && this.sucursalactiva.articulos){
        //console.log(this.sucursalactiva.articulos)
        this.resultadosDebusqueda = this.sucursalactiva.articulos.slice();
        this.resultadosDebusqueda = this.busquedaPorNombre()
        this.numBusqueda = this.resultadosDebusqueda.length;
        this.resultadosDebusquedaOriginales = this.resultadosDebusqueda
        //console.log('resultadosbusqueda --', this.resultadosDebusqueda)

      }
      this.paginado();
      this.resultadoDeBusquedaSubject.next({
        totaldeArticulos: 0,
        textodebusqueda: '',
      });
    }
  }

  busquedaPorNombre() {
    var sort = this.resultadosDebusqueda.sort((a, b) => (a.getDescripCion() > b.getDescripCion()) ? 1 : ((b.getDescripCion() > a.getDescripCion()) ? -1 : 0))
    var newSort = [];
    var oldSort = [];
    var flagSort = false;
    var arrFlag = [];

    this.busqueda = this.busqueda.toUpperCase();
    let arrBusqueda = this.busqueda.split("");
    sort.forEach(
      element => {
        let arrStr = element.getDescripCion().split(" ");
        arrFlag = [];
        for(let i = 0; i < arrBusqueda.length; i++) {
          const element2 = arrBusqueda[i];
          if (element2 === arrStr[0][i]) {
            flagSort = true;
            arrFlag.push(flagSort);
          }
          else {
            flagSort = false;
            arrFlag.push(flagSort);
          }
        }
        for(let i = 0; i < arrFlag.length; i++){
          const flag = arrFlag[i];
          if (!flag) {
            flagSort = false;
          }
        }

        if (flagSort) {
          newSort.push(element);
        }
        else {
          oldSort.push(element);
        }
      }
    );
    for (let i = 0; i < oldSort.length; i++) {
      const element = oldSort[i];
      newSort.push(element);
    }
    return newSort;

  }

  paginado() {
    let paginado: ArticulosGeneral[] = [];
    let paginas = this.resultadosDebusqueda.length / 60;
    this.paginadorProvider.next(paginas);
    if (paginas > 1) {
      for (let index = 0; index < 60; index++) {
        paginado.push(this.resultadosDebusqueda[index]);
      }
      this.paginaActual = 0;
    }
    else {
      paginado = this.resultadosDebusqueda;
      this.paginaActual = 0;
    }
    this.lastSearch.next(paginado);
  }

  paginadoSuc() {
    this.resultadosDebusqueda.sort(this.ordenAlfabetico);
    this.paginado();
    this.filterOn = true;

    this.lastSearch.next(this.lastSearch.value);
  }

  getAnchoPagina(ancho: number) {
    this.anchoPagina = ancho;
    return ancho;
  }

  async busquedaCategoria(categoria: CategoriasWebMenu, categoriaPadre: CategoriasWebMenu) {
    //console.log('busquedacategoria()',categoria, categoriaPadre)
    let myBusquedaExtras: ResultadosDeBusquedaInterface = {
      totaldeArticulos: 0,
      textodebusqueda: categoria.nombre,
      categoria: categoriaPadre,
    };
    let fecha = new Date();
    let newArticulos = [];
    this.paginaActual = 0;
    this.fueBusquedaEscrita = false;
    this.resultadosDebusqueda = [];
    this.numBusqueda = 0;
    //console.log('busquedacategoria mapapedidos', this.mapaPedidos)
    let array = this.mapaPedidos.get(categoria.categoria) || [];
    if (categoria.subCategorias != undefined) {
      categoria.subCategorias.forEach(k => {
        let kmap = this.mapaPedidos.get(k.categoria);
        if (kmap != undefined) {
          array = array.concat(kmap);
        }
        k.subCategorias.forEach(l => {
          let lmap = this.mapaPedidos.get(l.categoria);
          if (lmap != undefined) {
            array = array.concat(lmap);
          }
        })
      })
    }
    //console.log('busquedacategoia array', array)
    if (array.length > 0) {
      let newArray = [];
      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (!this.containsObject(element, newArray)) {
          newArray.push(element);
        }
      }
      newArray.forEach(marcacod => {
        if (marcacod) {
          this.sucursalactiva?.articulos.forEach(k => {

            if (k.getMarca() == marcacod.marca && k.getCodigo() == marcacod.codigo) {
              if (k.isdeoferta === true && k.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {
                newArticulos.push(k);
              }
              if (k.isdeoferta === false) {
                newArticulos.push(k);
              }
              this.resultadosDebusqueda = newArticulos;
              if (this.resultadosDebusqueda.length === 0) {
                myBusquedaExtras.totaldeArticulos;
              }
              else {
                myBusquedaExtras.totaldeArticulos++;
              }
            }
          })
        }
      });
    }
    //console.log('busquedacategoria() resultadosdebusqueda', this.resultadosDebusqueda)
    this.resultadosDebusquedaOriginales = this.resultadosDebusqueda;
    this.numBusqueda = this.resultadosDebusqueda.length;
    myBusquedaExtras.totaldeArticulos = this.numBusqueda;

    this.paginado();
    this.resultadoDeBusquedaSubject.next(myBusquedaExtras);
  }

  async iniciarbusquedaPorCodigoMarca(categoria: CategoriasWebMenu, categoriaPadre: CategoriasWebMenu) {
    let myBusquedaExtras: ResultadosDeBusquedaInterface = {
      totaldeArticulos: 0,
      textodebusqueda: categoria.nombre,
      categoria: categoriaPadre,
    };
    let fecha = new Date();
    let newArticulos = [];
    this.paginaActual = 0;
    this.fueBusquedaEscrita = false;
    this.resultadosDebusqueda = [];
    this.numBusqueda = 0;

    let array = this.mapaPedidos.get(categoria.categoria) || [];

    if (categoria.subCategorias != undefined) {
      categoria.subCategorias.forEach(k => {
        array = array.concat(this.mapaPedidos.get(k.categoria));
      })
    }
    if (array.length > 0) {
      array.forEach(marcacod => {
        if (marcacod) {
          this.sucursalactiva?.articulos.forEach(k => {

            if (k.getMarca() == marcacod.marca && k.getCodigo() == marcacod.codigo) {
              if (k.isdeoferta === true && k.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {
                newArticulos.push(k);
              }
              if (k.isdeoferta === false) {
                newArticulos.push(k);
              }
              this.resultadosDebusqueda = newArticulos;
              if (this.resultadosDebusqueda.length === 0) {
                myBusquedaExtras.totaldeArticulos;
              }
              else {
                myBusquedaExtras.totaldeArticulos++;
              }
            }
          })
        }
      });
    }
    this.numBusqueda = this.resultadosDebusqueda.length;
    myBusquedaExtras.totaldeArticulos = this.numBusqueda;

    let paginado: ArticulosGeneral[] = [];
    let paginas = this.resultadosDebusqueda.length / 60;
    this.paginadorProvider.next(paginas);
    if (paginas > 1) {
      for (let index = 0; index < 60; index++) {
        paginado.push(this.resultadosDebusqueda[index]);
      }
      this.paginaActual = 0;
    }
    else {
      paginado = this.resultadosDebusqueda;
      this.paginaActual = 0;
    }
    this.lastSearch.next(paginado);
    this.resultadoDeBusquedaSubject.next(myBusquedaExtras);
  }
  //marca es parte de la pk del artículo, marcareal es un campo dedicado para que sea facil de cambiar y generar familias de productos para ofertas o similar,
  //si no realiza búsqueda revisar si artículos tienen marcareal seteada
  async busquedaPorMarca(categoria: CategoriasWebMenu) {
    if(!this.sucursalactiva.articulos){
      return
    }
    console.log('busquedaxmarca categoria', categoria)
    let myBusquedaExtras: ResultadosDeBusquedaInterface = {
      totaldeArticulos: 0,
      textodebusqueda: categoria.nombre
    };
    let fecha = new Date();
    let newArticulos = [];
    this.paginaActual = 0;
    this.fueBusquedaEscrita = false;
    this.resultadosDebusqueda = [];
    this.numBusqueda = 0;
    this.sucursalactiva?.articulos.forEach(k => {
      if (k.marcareal == categoria.marca) {
        if (k.getMarcaReal() == k.marcareal && k.getCodigo() == k.codigo) {
          if (k.isdeoferta === true && k.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {
            newArticulos.push(k);
          }
          if (k.isdeoferta === false) {
            newArticulos.push(k);
          }
          this.resultadosDebusqueda = newArticulos;

          if (this.resultadosDebusqueda.length === 0) {
            myBusquedaExtras.totaldeArticulos;
          }
          else {
            myBusquedaExtras.totaldeArticulos++;
          }
        }
      }
    })
    console.log('busquedaxmarca resultados de busqueda', this.resultadosDebusqueda)
    this.numBusqueda = this.resultadosDebusqueda.length;
    myBusquedaExtras.totaldeArticulos = this.numBusqueda;
    this.resultadosDebusquedaOriginales = this.resultadosDebusqueda;
    let paginado: ArticulosGeneral[] = [];
    let paginas = this.resultadosDebusqueda.length / 60;
    this.paginadorProvider.next(paginas);
    if (paginas > 1) {
      for (let index = 0; index < 60; index++) {
        paginado.push(this.resultadosDebusqueda[index]);
      }
      this.paginaActual = 0;
    }
    else {
      paginado = this.resultadosDebusqueda;
      this.paginaActual = 0;
    }
    this.lastSearch.next(paginado);
    this.resultadoDeBusquedaSubject.next(myBusquedaExtras);
  }

  getNumBusqueda() {
    return this.numBusqueda;
  }

  generarid(dimencion: number): string {
    let result = '';
    let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < dimencion; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getUsuarioProvider() {
    return this.userProvider.asObservable();
  }

  getSucursalesProvider() {
    return this.sucursalestemp.asObservable();
  }

  getActualSucursal() {
    return this.sucursalactivatemp.asObservable();
  }

  getLastSearchProvider() {
    return this.lastSearch.asObservable();
  }

  getMiniSearchProvider() {
    return this.minisearch.asObservable();
  }

  getSucursalActiva() {
    return this.sucursalactiva;
  }

  getSucursales() : SucursalEcommerce[] {
    return this.sucursales;
  }

  getFueBusquedaEscrita() {
    return this.fueBusquedaEscrita;
  }

  getGenericoSeguro(params: HttpParams, ruta: string) {
    this.restartInactivityCheck()
    let environment = localStorage.getItem('environment')
    let user = JSON.parse(localStorage.getItem('currentUser'))
    //console.log('environment', environment);
    //console.log('user', user)

    if (this.environmenturl != undefined) {
      //console.log('environmenturl + ruta', this.environmenturl.toString() + ruta)
      //console.log('this.user.token',this.user.token)
      return this.httpClient.get(this.environmenturl.toString() + ruta, {
        headers: { "Authorization": this.user.token }, params
      });
    } else {
      return this.httpClient.get(environment + ruta, {
        headers: { "Authorization": user.token }, params
      });
    }
  }

  postGenericoSeguro(url: string, body: object) {
    this.restartInactivityCheck()
    return this.httpClient.post(this.environmenturl + url, body, {
      headers: { "Authorization": this.user.token }
    });
  }

  postGenericoSeguro2(url: string, body: object) {
    return this.httpClient.post('186.109.143.226:8059' + url, body, {
      headers: { "Authorization": this.user.token }
    });
  }

  putGenericoSeguro(url: string, body: object) {
    this.restartInactivityCheck()
    return this.httpClient.put(this.environmenturl + url, body, {
      headers: { "Authorization": this.user.token }
    });
  }

  /**
   * Carga los articulos del pedido en historial al 'carrito' actual
   * @param pedidoAnterior
   */
  cargarEstePedidoDeHistorialEnCart(pedidoAnterior: HistorialPedidos) {
    //this.resetEccomerce();
    pedidoAnterior.items.forEach(
      (artEnPedido : BackUptArticulo) => {
        this.sucursalactiva.articulos.forEach(
          (articulo : ArticulosEcommerce) => {
            if (artEnPedido.codigo === articulo.getCodigo() && artEnPedido.marca === articulo.getMarca()) {
              articulo.agregarCantidad(artEnPedido.cantidad);
              this.tienePromoArticulo(articulo).forEach(
                (promocion : PromocionesEccomerce) => {
                articulo.setPromo(promocion);
              })
              articulo.operacionesExtras();
            }
          }
        );
      }
    );
    this.sincronizarCarritoFrontyBack();
  }

  getHistorialDePedidos() {
    let httparams = new HttpParams();
    if (!this.pendingPedidos) {
      this.pendingPedidos = true;
      return this.getGenericoSeguro(httparams, 'pedidoweb').pipe(tap(data => {
        this.pendingPedidos = false;
        this.historiaPedidos = data['registros'];
        for (let k of this.historiaPedidos) {
          k.myitemsdetalle = [];
          k.itemsreales = [];
          if (k.monto != 0) {
            for (let arta of k.items) {
              if (arta.precio > 0) {
                let imgenurl = new URL("/assets/logo/promo.png", this.environmenturl).toString();
                let articuloCopy = new ArticulosEcommerce(
                  arta.marca,
                  arta.codigo,
                  arta.precio,
                  arta.precio,
                  0,
                  "",
                  arta.descripcion,
                  0,
                  "",
                  0,
                  0,
                  0,
                  '0',
                  arta.cantidad,
                  0,
                  0,
                  arta.marca,
                  '',
                  0,
                  0,
                  imgenurl,
                  "",
                  -1,
                  [],
                  false,
                  arta.cantidad,
                  arta.fraccion
                  );
                articuloCopy.setCantidad(arta.cantidad);
                articuloCopy.setImgDownload(articuloCopy.getImgDownload());
                articuloCopy.setFoto(articuloCopy.getFotoImagen());

                k.myitemsdetalle.push(articuloCopy);
                k.itemsreales.push(articuloCopy);
              }

              this.sucursalactiva.articulos.forEach(artreal => {
/*                 if (artreal.getMarca() == 2190 && artreal.getCodigo() == 432) {
                  if (artreal.getDescripCion() == "ACEITE CAÑUELAS GIRASOL-OLIVA" && arta.descripcion == "*ACEITE CAÑUELAS GIRASOL-OLIVA") {
                    console.log(arta, artreal);
                  }
                } */
                if (arta.codigo == artreal.getCodigo() && arta.marca == artreal.getMarca() && arta.descripcion == artreal.getDescripCion()) {
                  let articuloCopy = new ArticulosEcommerce
                    (
                      artreal.getMarca(),
                      artreal.getCodigo(),
                      arta.precio,
                      arta.precio,
                      artreal.getCantidadMaxima(),
                      artreal.getDescripcionAdicional(),
                      arta.descripcion,
                      0,
                      artreal.getPresentacion(),
                      0,
                      0,
                      0,
                      '0',
                      0,
                      0,
                      0,
                      artreal.getMarcaReal(),
                      '',
                      0,
                      0,
                      artreal.getOtrosPrecios(),
                      artreal.getFotoImagen(),
                      -1,
                      [],
                      false,
                      arta.cantidad,
                      arta.fraccion
                    );

                  articuloCopy.setCantidad(arta.cantidad);
                  if (artreal.getImgDownload()) {
                    articuloCopy.setImgDownload(artreal.getImgDownload());
                    articuloCopy.setFoto(artreal.getFotoImagen());
                  }

                  k.myitemsdetalle.push(articuloCopy);
                  k.itemsreales.push(articuloCopy);
                }
              })
            }
            let newItemsReales = k.itemsreales;
            let newItemsDetalles = k.myitemsdetalle;
            for (let i = 0; i < k.items.length; i++) {
              const element = k.items[i];
              newItemsReales.splice(i, 1);
              newItemsDetalles.splice(i, 1);
              if (element.marca == parseInt(this.marcaFlete) && element.codigo == parseInt(this.codigoFlete)) {
                let fleteArt = new ArticulosEcommerce(parseInt(this.marcaFlete), parseInt(this.codigoFlete), k.items[k.items.length - 1].monto, k.items[k.items.length - 1].monto, 0, 'FLETE', 'FLETE A DOMICILIO', 1, 'COSTO DE ENVIO', 0, 0, 0, 'FLETES', parseInt(this.marcaFlete), 1000, 1000, k.items[k.items.length - 1].monto, '', 0, k.items[k.items.length - 1].monto, 'FLETE', undefined, 2, [{ nombre: 'No Reemplazar', modo: '2', idcss: 'c9ft', activo: true }]);
                fleteArt.cantiadadpedido = 1;
                k.myitemsdetalle.push(fleteArt);
                k.itemsreales.push(fleteArt);
              }
            }
          }
          else {
            this.historiaPedidos = this.historiaPedidos.filter((item) => item.monto !== 0);
          }
        }
        this.historialtemp.next(this.historiaPedidos);
      }));
    } else {
      return empty();
    }
  }

  obtenerHistorialDePedidosCargados() : HistorialPedidos[]{
    return this.historiaPedidos
  }

  tienePromoArticulo(artreal: ArticulosGeneral): PromocionesEccomerce[] {
    let mispromos: PromocionesEccomerce[] = [];

    if (artreal.getPromos().length > 0) {
      return [];
    }

    this.promocionesEcommerce.forEach(promo => {
      if (promo.getCodigoA1() == artreal.getCodigo() && promo.getMarcaA1() == artreal.getMarca()) {
        mispromos.push(promo);
      }
      if (promo.getCodigoA2() == artreal.getCodigo() && promo.getMarcaA2() == artreal.getMarca()) {
        mispromos.push(promo);
      }
    })
    return mispromos;
  }

  getHistorialProvider() {
    return this.historialtemp.asObservable();
  }

  getGenerico(params: HttpParams, ruta: string) {
    this.restartInactivityCheck()
    return this.httpClient.get(this.environmenturl.toString() + ruta, {
      headers: { "Cache-Control": "max-age=10800" }, params
    });
  }

  postGenerico(url: string, body: object) {
    this.restartInactivityCheck()
    return this.httpClient.post(this.environmenturl.toString() + url, body);
  }

  putGenerico(url: string, body: object) {
    this.restartInactivityCheck()
    return this.httpClient.put(this.environmenturl.toString() + url, body);
  }

  loginPost(url: string, body: object) {
    return this.httpClient.post(this.environment.endpoint + url, body);
  }

  setmailAtencionAlCliente(mail : string){
    this.mailAtencionAlCliente = mail
  }

  getmailAtencionAlCliente() : string{
    return this.mailAtencionAlCliente
  }

  settelefonoAtencionAlCliente(telefono : string){
    this.telefonoAtencionAlCliente = telefono;
  }
  getDataEmpresa(){
    return this.dataEmpresa;
  }
  getworkWithA(){
    return this.workWithAs;
  }
  getEnvioExpress(){
    return this.envioExpress;
  }
  getinfoProv(){
    return this.infoProv;
  }
  getretiroSucursal(){
    return this.retiroSucursal;
  }
  getinfoCompra(){
    return this.infoCompra;
  }
  getinfoMP(){
    return this.infoMP;
  }

  getObservacionesEnvios()
  {
    return this.observacionesEnvio;
  }

  getTextoCerrarPedido()
  {
    return this.textoCerrarPedido;
  }

  getTerminosCondiciones()
  {
    return this.terminos_condiciones;
  }
  getInformacionEnvio()
  {
    return this.informacion_envio;
  }
  getPoliticaDevoluciones()
  {
    return this.politica_devoluciones;
  }

  gettelefonoAtencionAlCliente():string {
    return this.telefonoAtencionAlCliente;
  }

  getSeRealizoUnaBusquedaObservable(){
    return this.seRealizoUnaBusquedaObservable;
  }

  getReemplazos(){
    return this.remplazos;
  }

  getObservableSeAgregaronItemsAlCarrito(){
    return this.seAgregaronArtículosAlCarrito.asObservable()
  }

  getAvisoArticulosSucursalCargados(){
    return this.articulosSucursalCargados.asObservable()
  }

  getsincronizandoCarritoConBack(){
    return this.sincronizandoCarritoConBack
  }
}
