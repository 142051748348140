<div normal>
        <modal-default-mmaxapp>
                <div class="tarjetaconfirmar">
                        <div class="titulo">
                                No supera el monto minimo.
                        </div>
                        <div class="leyendaconfirmar">
                                No puede continuar ya que no supera el monto minimo de ${{montoMinimo}}.
                                <br>
                                <br>
                                Por favor, supere el monto para continuar.
                        </div>
                        <div class="botones">
                                <button class="buttonpc whibutton" (click)="aceptar()">Aceptar</button>
                        </div>
                </div>
        </modal-default-mmaxapp>
</div>