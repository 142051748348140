import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {
  @Input() color: string;
  @Input() width : string;
  @Input() height : string;

  constructor() { }

  ngOnInit(): void {
    this.color = this.color || getComputedStyle(document.documentElement).getPropertyValue('--first-ecommerce-color');
    document.documentElement.style.setProperty('--color', this.color);
    this.width = this.width || '100px';
    document.documentElement.style.setProperty('--width', this.width);
    this.height = this.height || '100px';
    document.documentElement.style.setProperty('--height', this.height);
  }
}
