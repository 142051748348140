<div *ngIf="modo==='normal'">
  <div class="franje">
    Marcas Destacadas
  </div>
  <section class="marcasBox" *ngIf="marcasD.length != 0; else notBrands">
    <div class="bigbox">
      <div *ngFor="let marca of marcasD" class="box">
        <div class="imagebox" (click)="irAmarcas(marca)" onclick="window.scrollTo(0,0)">
          <img class="image" [src]="marca.imagen" [alt]="marca.nombre" [width]="300" [height]="300" >
        </div>
      </div>

    </div>
  </section>
  <ng-template #notBrands>
    <section style="display: flex; justify-content: center">
      <div class="notBrands">
        No se encontraron marcas habilitadas.
      </div>
    </section>
  </ng-template>
  <mmax-helpfootbar></mmax-helpfootbar>

  <foo-mariano-max></foo-mariano-max>
</div>
<div *ngIf="modo==='pc-land'">
  <div class="marcas">
    <div class="marcacontainer">
      <div *ngFor="let marca of marcasD" class="marca" (click)="irAmarcas(marca)" onclick="window.scrollTo(0,0)">
        <img class="image" [src]="marca.imagen" [alt]="marca.nombre" [width]="250" [height]="250">
      </div>
    </div>
  </div>
</div>
