import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'projects/app-shared/src/public-api';
import { ArticulosEcommerce, ArticulosGeneral, EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';
import { MenuMmaxInterface } from '../etc/mmax-menu.interface';
import { MarianomGeneral } from '../marianom-general.service';

@Component({
  selector: 'mmax-search-results',
  templateUrl: './mmax-search-results.component.html',
  styleUrls: ['./mmax-search-results.component.scss']
})
export class MmaxSearchResultsComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() myData: string;
  @Input() login: string;
  menues: { nombre: string; url: string; urlmobile: string; icourl: string; }[];

  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private route: ActivatedRoute, private modal: ModalService) {
    this.modo = this.modo || 'normal'
    this.resultadosDeLaBusqueda = [];
    this.genServ.getLastSearchProvider().subscribe(data => {
      //console.log('nuevos items busqeuda', data)
      this.resultadosDeLaBusqueda = data;
      let fecha = new Date();
      let newArticulos = [];
      for (let i = 0; i < this.resultadosDeLaBusqueda.length; i++) {
        const element = this.resultadosDeLaBusqueda[i];
        if (element.isdeoferta === true && element.deOferta.fechahasta > fecha.toISOString().split('T')[0]) {

          newArticulos.push(element);

        }
        if (element.isdeoferta === false) {
          newArticulos.push(element);
        }
      }

      this.resultadosDeLaBusqueda = newArticulos;

    })
    this.menues = [
      {
        nombre: 'Mis Datos',
        url: '/misdatos',
        urlmobile: '/app-mobile/app/account',
        icourl: '../../assets/icons/credit-card-outline-white.svg'
      }
    ];
    this.isLogued = this.serv.isloged();
    this.swicher = false;
    this.genServ.getUsuarioProvider().subscribe(data => {

      if (data != undefined) {
        this.isLogued = this.serv.isloged();
        this.ngOnInit();
      }


    });
    this.myData = this.myData || 'none';
    this.login = this.login || 'none';
    this.beneficiosTrue = false;
    genServ.getAvisoArticulosSucursalCargados().subscribe({
      next: articulosCargados =>{
        this.genServ.iniciarbusqueda('');
      }
    })
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.modo = data['mode'] || this.modo || 'normal';

    });
    this.genServ.returnBeneficios().subscribe(data => {
      if (data == 'S') {
        this.beneficiosTrue = true;
      }
    })
    if (this.isLogued === false) {
      this.myData = 'none';
      this.login = 'block';
    } else {
      this.myData = 'flex';
      this.login = 'none';
    }
  }

  get toggleSideBar(){
    return this.serv.getOcultarSideBarBusqueda()
  }

  get categorias() {
    return this.genServ.getCategoriasWeb();
  }

  get menus(): MenuMmaxInterface[] {
    let beneficios = {
      nombre: 'Beneficios',
      ruta: 'beneficios',
      activo: false
    };

    for (let i = 0; i < this.serv.rutas.length; i++) {
      const element = this.serv.rutas[i];

      if (this.beneficiosTrue = true) {
        if (window.innerWidth < 1000) {
          if (!this.containsObject(beneficios, this.serv.rutas)) {
            this.serv.rutas.push(beneficios);
          }
        } else {
          if (this.containsObject(beneficios, this.serv.rutas)) {
            this.serv.rutas.splice(-1);
          }
        }
      }
    }
    return this.serv.rutas
  }

  openLogin() {
    this.serv.gotoURL('', this.route);
    setTimeout(() => {
      this.modal.open('login-modal-1');
    }, 10);
  }

  cerrarSesion() {
    this.modal.open('modal-salir');
  }

  gotoURL(url: string) {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.gotoURL(url, this.route);

  }

  menuclick(menus: MenuMmaxInterface) {
    if (!this.serv.isloged()) {
      if (menus.ruta == "historial" || menus.ruta == "sucursales") {
        this.serv.abrirModalLogin();
      } else {
        this.serv.gotoURL("/" + menus.ruta, this.route);
        this.serv.activatethis(menus);
      }
    } else {
      this.serv.gotoURL("/" + menus.ruta, this.route);
      this.serv.activatethis(menus);
    }

  }

  containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].nombre == obj.nombre) {

        return true;
      }
    }

    return false;
  }


  reiniciarFiltroCategoria(categoriaSeleccionada : any){
    let seCerroDespliegueDeCategoria = categoriaSeleccionada.desplegado === false
    //console.log('reiniciarFiltroCategoria',categoriaSeleccionada)
    if(seCerroDespliegueDeCategoria){
      this.genServ.iniciarbusqueda('');
    }
  }


  resultadosDeLaBusqueda: ArticulosGeneral[];
  swicher: boolean;
  isLogued: boolean;
  beneficiosTrue: boolean
}
