import { Component } from '@angular/core';
import { MarianomGeneral } from '../app-shared/marianom-general.service';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
})

export class AppComponent {
  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService) {

  }

  ngOnInit(): void {
    this.serv.initMaster();
  }

  get nombre() {
    return this.genServ.getNombreSitio();
  }

  get isClosed(): boolean {
    return this.serv.desplegado;
  }
}
