<div *ngIf="modo==='normal'">
  <div *ngIf='!cargando; else elseBlock'>
    <div class="infobox infoboxred">
      <table class="table-turnos">
        <tr class="column">
          <th class="filas centerfiles emptyFila"></th>
          <th *ngFor="let col of columnas" class="filas centerfiles">{{col}}</th>
        </tr>
        <tr *ngFor="let turno of diasdisponibles" class="turnColumn">
          <th class="filas">{{turno.dia}}</th>
          <th *ngFor="let turnin of turno.turnos;" class="filas centerfiles">

            <div class="roundedOne" *ngIf="turnin.activado == true; else roundFalse">
              <input [disabled]="!turnin.activado" class="inputRound" [id]="turnin.id" type="checkbox"
                [checked]="turnin.selecionado" (change)="onchange($event, turnin)" />
              <label [for]="turnin.id"></label>
            </div>
          </th>
        </tr>
      </table>
    </div>
  </div>

  <ng-template #roundFalse>
    <div class="roundedFalse">🚫</div>
  </ng-template>

  <ng-template #elseBlock>
    <div *ngIf="!error; else notTurnos">
      <div class="infobox infoboxred">
        Recuperando Turnos...
      </div>
    </div>
  </ng-template>

  <ng-template #notTurnos>
    <div class="infobox infoboxred">
      No hay turnos disponibles.
    </div>
  </ng-template>
</div>
