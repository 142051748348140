import { OfertasEcommerce } from '../interfaces/ofertas-ecommerce.interface';
import { TagsEcommerce } from '../interfaces/tags-eccomerce.interface';
import { PromocionesEccomerce } from './promociones-class';

export class DiferenciaPromo extends PromocionesEccomerce {
  setTag(tag: TagsEcommerce) {
    throw new Error('Method not implemented.');
  }

  getTag(): TagsEcommerce[] {
    throw new Error('Method not implemented.');
  }

  getCantidad() {
    return this.counter;
  }

  getCantidadMayorista() {
    return 0;
  }

  getRemplazos() {
    throw new Error('Method not implemented.');
  }

  isdeOferta(): boolean {
    return false;
  }

  setdeOferta(ofertas: OfertasEcommerce) {
    throw new Error('Method not implemented.');
  }

  getPromosActivas(): string[] {
    return [];
  }

  getDescontadoTotal(): number {
    return this.monto * this.counter;
  }

  getDescontado(): number {
    return this.monto;
  }

  operacionesExtras() {
    // console.log('se ejecuto  DiferenciaPromo');
  }

  preconPromo(precio: number, cantidad: number, modificacionActual: number) {
    return this.monto;
  }

  setMyBlob(body: Blob) {
    this.myblob = body;
  }

  getMyBlob(): Blob {
    return this.myblob;
  }

  setRemplazo(numero: number) {
    throw new Error('Method not implemented.');
  }

  getPrecioPartido(): number {
    throw new Error('Method not implemented.');
  }

  getOtrosPrecios(): string {
    throw new Error('Method not implemented.');
  }

  getRemplazo() {
    throw new Error('Method not implemented.');
  }

  setModificacionPrecio(arg0: number) {
    throw new Error('Method not implemented.');
  }

  setPromo(k: PromocionesEccomerce) {
    throw new Error('Method not implemented.');
  }

  getPrecioNormal() {
    return 0;
  }

  getPrecioMayorista() {
    return 0;
  }

  getPrecioAmostrar() {
    return this.getPrecioCalculado();
  }

  getPresentacion() {
    return ' ';
  }

  getDescripcionAdicional() {
    return ' ';
  }

  getCantidadMaxima() {
    return 0;
  }

  getMarcaReal() {
    return '';
  }

  agregarCantidad(numero: number) {
    if (this.esDoblePromo()) {
      this.articulo1.agregarCantidad(numero * this.cantidadenPromo());
      this.articulo2.agregarCantidad(numero * this.cantidadenPromo());
    } else {
      this.articuloenPromo().agregarCantidad(numero * this.cantidadenPromo());
      this.articuloenPromo().operacionesExtras();
    }
  }

  setCantidad(arg0: number) {
    // console.log(' se seteo diferencia promo');
  }

  getPrecioCalculado(): number {
    if (this.articuloenPromo() !== undefined) {
      let cantiPromo = 1;

      if (this.articuloenPromo().getCantidad() != 0) {
        if (
          this.articuloenPromo().getCantidad() % this.cantidadenPromo() ==
          0
        ) {
          cantiPromo =
            this.articuloenPromo().getCantidad() / this.cantidadenPromo();
        }
      }

      if (this.articulo1.isdeOferta() == true) {
        return (
          (this.articuloenPromo().getPrecioOferta() * this.cantidadenPromo() +
            this.monto) *
          cantiPromo
        );
      } else {
        return (
          (this.articuloenPromo().getPrecio() * this.cantidadenPromo() +
            this.monto) *
          cantiPromo
        );
      }
    } else {
      return 0;
    }
  }

  getPrecioOferta(): number {
    return this.getPrecioOferta();
  }

  getPrecio() {
    return this.monto;
  }

  getImgDownload(): boolean {
    return this.descargo;
  }

  setImgDownload(valor: boolean) {
    this.descargo = valor;
  }

  hacerCalculos() {
    return this.getCantidad() * this.getPrecio() * 1;
  }

  getFraccion() {
    return 0;
  }

  aplicarPromocion(precio: number) {
    return precio + this.monto;
  }
  misPromo: PromocionesEccomerce[];
  deOferta: OfertasEcommerce;
  isdeoferta: boolean;
  isdePromo: boolean;

  private myblob: Blob;
}
