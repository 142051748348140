import { Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { TagsEcommerce } from 'projects/ecommerce-generico/src/lib/interfaces/tags-eccomerce.interface';
import { ArticulosEcommerce, ArticulosGeneral, CategoriasWebMenu, EcommerceGenericoService, RemplazosMMax, ResultadosDeBusquedaInterface } from 'projects/ecommerce-generico/src/public-api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CheckboxCategorias, CheckboxSubcategoria } from '../etc/categoria-checkbox.interface';
import { MarianomGeneral } from '../marianom-general.service';
import { PaginadorMmaxComponent } from '../paginador-mmax/paginador-mmax.component';


@Component({
  selector: 'lista-productos-view',
  templateUrl: './lista-productos-view.component.html',
  styleUrls: ['./lista-productos-view.component.scss'],

})

export class ListaProductosViewComponent implements OnInit,OnChanges {
  @Input() articulosIn: ArticulosGeneral[]
  @Input() modelo: string;

  nActCarrusel: number;
  ofertaSlide: OfertaSlide[];
  numeropaginas: number;
  numeropaginasOfertas: number;
  misreemplazo: RemplazosMMax[];
  reemplazo: CheckboxCategorias[];
  gaseosas: CheckboxCategorias[];
  myExtrasResultado: ResultadosDeBusquedaInterface;
  categoriasClickeables: CategoriaClickeable[];
  min: number;
  max: number;
  priceFilterOn: boolean;
  private _destroyed$: Subject<any>;
  cantidadArticulosActuales: number;
  articulosFiltrados : ArticulosGeneral[] = [];
  filtroElegidoNombre : string ;

  constructor(private serv: MarianomGeneral, private genServ : EcommerceGenericoService, private servGeneral : EcommerceGenericoService) {
    this.categoriasClickeables = [];
    this._destroyed$ = new Subject();
    this.nActCarrusel = this.nActCarrusel || 0;
    this.ofertaSlide = [];
    this.reemplazo = [];
    this.misreemplazo = [];
    this.numeropaginas = 0;
    this.min = 0;
    this.max = 0;
    this.priceFilterOn = false;
    this.initFiltros();
    this.genServ.getPaginas().pipe(takeUntil(this._destroyed$)).subscribe({
      next:data => {
        this.numeropaginas = data;
        this.initFiltros();
      },
    });

    this.genServ.getPaginasOfertas().pipe(takeUntil(this._destroyed$)).subscribe({
      next:data => {
        this.numeropaginasOfertas = data;
      },
    });
    this.genServ.getTerminoDeIniciarCarro().subscribe({
      next: avisoTerminoDeCargar =>{
        //console.log('getTerminoDeIniciarCarro')
        this.genServ.getReemplazosProvider().pipe(takeUntil(this._destroyed$)).subscribe({
          next:data => {
            //console.log('getReemplazosProvider')
            let subcat: CheckboxSubcategoria[] = [];
            this.misreemplazo = data;
            this.misreemplazo.forEach(k => {
              subcat.push({
                nombre: k.nombre,
                activada: false,
                id: k.modo
              });
            });
            this.reemplazo.push({
              nombre: 'Reemplazo de productos',
              categorias: subcat
            });
            this.serv.marcarFaltantesConRemplazo(this.reemplazo[0].categorias[0])
           }
        });
      }
    })
/*     this.servGeneral.getSeRealizoUnaBusquedaObservable().subscribe({
      next:seRealizoUnaBusqueda =>{
        this.reiniciarFiltroPrecios();
        this.articulosFiltrados = this.articulosIn.slice()
      }
    }) */
  }


  ngOnInit(): void {
    if(this.modelo === 'historialDetail'){
      console.log(this.articulos)
    }
    this.modelo = this.modelo || 'normal';
    let index = 0;
    this.articulosFiltrados = this.articulosIn.slice()
    this.articulos.forEach(k => {
      this.ofertaSlide.push({
        activado: false,
        id: this.genServ.generarid(3),
      })
      index++;
    })
    if (this.ofertaSlide.length > 0) {
      this.ofertaSlide[0].activado = true;
    }
    if(this.reemplazo[0]){
      console.log(this.reemplazo, this.reemplazo[0].categorias[0])
      this.serv.marcarFaltantesConRemplazo(this.reemplazo[0].categorias[0])
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log('cambios lista productos', changes)
    this.articulosFiltrados = this.articulosIn.slice();
    this.reiniciarFiltroPrecios();
  }

  get ofertas() {
    return this.genServ.getOfertas()
  };

  get tags() {
    let resultado: TagsEcommerce[] = [];
    if (this.myExtrasResultado.tagsEnbusqueda) {

    }
    this.myExtrasResultado?.tagsEnbusqueda?.forEach((k, v) => {
      resultado.push(k);
    })
    return resultado
  }

  get numBusqueda() {

    return this.genServ.getNumBusqueda();
  }

  get fueBusquedaEscrita() {
    return this.genServ.getFueBusquedaEscrita();
  }

  get numOfertas() {
    return this.genServ.getNumPagOfertas();
  }

  get articulos(): ArticulosGeneral[] {
    let options = document.getElementById('options')
    if (options !== null && options.childElementCount === 2) {
      options.removeChild(options.querySelector('#selector'))
    }
    if (this.articulosIn == undefined) {
      return [];

    }
    else {
      if(!this.cantidadArticulosActuales){
        this.cantidadArticulosActuales = this.articulosIn.length
      }
      else if(this.cantidadArticulosActuales !== this.articulosIn.length){
        this.cantidadArticulosActuales = this.articulosIn.length;
        this.ngmin = null;
        this.ngmax = null;
      }
      return this.articulosIn;
    }
  }

  filtrarArticulos(){
    this.articulosFiltrados = this.articulosIn.filter(
      articulo =>{
        return this.pasaLosFiltros(articulo as ArticulosEcommerce)
      }
    )
    this.cantidadArticulosActuales = this.articulosFiltrados.length
    //console.log('filtrarArticulos',this.articulosFiltrados)
  }

  pasaLosFiltros(articulo : ArticulosEcommerce){
    //console.log('pasa los filtros',articulo, this.ngmin, this.ngmax);
    let minimo, maximo;
    minimo = this.ngmin;
    maximo = this.ngmax;
    if(!this.ngmin){
      minimo = -1;
    }
    if(!this.ngmax){
      maximo = 9999999999;
    }
    if(articulo.getFraccion()){
      return articulo.getPrecioMayorista() * articulo.getFraccion()>=minimo && articulo.getPrecioMayorista() * articulo.getFraccion()<=maximo;

    }
    else{
      return articulo.getPrecioMayorista()>=minimo && articulo.getPrecioMayorista()<=maximo;
    }
  }

  private async initFiltros() {
    let mayorPrecio: CheckboxSubcategoria = {
      nombre: 'Mayor Precio',
      activada: false,
      id: '1',
    }
    let menorPrecio: CheckboxSubcategoria = {
      nombre: 'Menor Precio',
      activada: false,
      id: '0'
    }
    let marcaaz: CheckboxSubcategoria = {
      nombre: 'Nombre (ascendente)',
      activada: false,
      id: 'a'
    }
    let marcadecen: CheckboxSubcategoria = {
      nombre: 'Nombre (descendente)',
      activada: false,
      id: 'z'
    }
    this.gaseosas = [
      {
        nombre: 'Elegir Filtro',
        categorias: [mayorPrecio, menorPrecio, marcaaz, marcadecen]
      }
    ];

    this.genServ.getExtraResultadosProvider().pipe(takeUntil(this._destroyed$)).subscribe({
      next:data => {
        this.categoriasClickeables = [];
        this.myExtrasResultado = data || {
          totaldeArticulos: 0,
          textodebusqueda: '',
        };
        if(this.myExtrasResultado.categoria == undefined) {
          this.categoriasClickeables.push({
            nombre: 'Busqueda',
            activable: false
          });
          this.categoriasClickeables.push({
            nombre: this.myExtrasResultado.textodebusqueda,
            activable: false
          });
        }
        else {
          this.categoriasClickeables.push({
            nombre: 'Busqueda',
            activable: false
          });
          this.categoriasClickeables.push({
            nombre: this.myExtrasResultado.categoria.nombre,
            activable: true,
            categoria: this.myExtrasResultado.categoria
          });
          this.myExtrasResultado.categoria.subCategorias.forEach(
            k => {
              k.subCategorias.forEach(
                tirdtier => {
                  if (tirdtier.nombre === this.myExtrasResultado.textodebusqueda) {
                    this.categoriasClickeables.push({
                      nombre: k.nombre,
                      activable: true,
                      categoria: k,
                    })
                    this.categoriasClickeables.push({
                      nombre: tirdtier.nombre,
                      activable: true,
                      categoria: tirdtier,
                    })
                  }
                }
              )
            }
          )
        }
      },
    });
  }

  clickOnCate(marca: CategoriasWebMenu, actiable: boolean) {
    marca.desplegado = false;
    if(actiable) {
      this.genServ.iniciarbusquedaPorCodigoMarca(marca, this.myExtrasResultado.categoria);
    }
  }

  categoriaSelec(event: CheckboxSubcategoria) {
    console.log('categoria selec')
    this.serv.marcarCarroConRemplazo(event)

  }

  seleccionadoFiltro(filtro: CheckboxSubcategoria) {
    this.gaseosas[0].categorias.forEach(k => {
      k.activada = false;
    });
    this.gaseosas[0].nombre = filtro.nombre;
    filtro.activada = true;
    this.genServ.ordenarArticulosPor(filtro.id, filtro);
    this.genServ.cambiarPaginaElegida();
    this.filtroElegidoNombre = filtro.nombre;
  }


  cerrarTag(tag: TagsEcommerce) {
    if (tag.selecionado) {
      tag.selecionado = false;
      this.genServ.cerrartag(tag)
    }
    else {
      tag.selecionado = true;
      this.genServ.filtrarTag(tag)
    }

  }

  onPageSelect(event) {
    this.genServ.cambiarPagina(event);
    this.gaseosas[0].categorias.forEach(k => {
      if (k.activada) {
        this.seleccionadoFiltro(k);
      }
    })

  }

  onPageSelectOferta(event) {
    this.genServ.cambiarPagina(event);
  }

  private async desactivarOfertas(index: string) {
    let sape = 0;
    this.ofertaSlide.forEach(k => {
      if (k.id === index) {
        k.activado = true;
        this.nActCarrusel = sape;
      }
      else {
        k.activado = false;
      }
      sape++;
    });

  }

  onPreviousClickPr() {
    const div = document.getElementById('slimarticulosPromos');
    div.scrollLeft -= 327;
  }

  onNextClickPr() {
    const div = document.getElementById('slimarticulosPromos');
    div.scrollLeft += 327;
  }

  onPreviousClickOf() {
    const div = document.getElementById('slimarticulosOfertas');
    div.scrollLeft -= 327;
  }

  onNextClickOf() {
    const div = document.getElementById('slimarticulosOfertas');
    div.scrollLeft += 327;
  }

  onchange(item: OfertaSlide) {
    this.desactivarOfertas(item.id);

  }

  anchoPag() {
    let ancho = document.getElementById("articulos");
    this.genServ.getAnchoPagina(ancho.clientWidth);
  }

  precioMin() {
    this.min = parseInt((<HTMLInputElement>document.getElementById("precioMin")).value);
  }

  precioMax() {
    this.max = parseInt((<HTMLInputElement>document.getElementById("precioMax")).value);
  }

  ngmin : number = 0;
  ngmax : number = 99999;


  reiniciarFiltroPrecios(){
    this.ngmin = null;
    this.ngmax = null;
  }

}

interface OfertaSlide {
  activado: boolean,
  id: string,
}
interface CategoriaClickeable {
  nombre: string,
  categoria?: CategoriasWebMenu,
  activable: boolean;
}
