/*
 * Public API Surface of ecommerce-generico
*/
export * from './lib/class/articulos-general.interface'
export * from './lib/class/promociones-class';
export * from './lib/class/porcentaje-promo.class';
export * from './lib/class/diferencia-promo.class';
export * from './lib/class/monto-calculado-promo';
export * from './lib/interfaces/categorias-webmenu.interface';
export * from './lib/interfaces/turnos-retiro.interface';
export * from './lib/interfaces/articulos-ecommerce.interface';
export * from './lib/interfaces/images-generic.interface';
export * from './lib/interfaces/sucursales-eccomerce.interface';
export * from './lib/interfaces/usuario.interface';
export * from './lib/interfaces/marcas-ecommerce.interface';
export * from './lib/interfaces/remplazos-ecommerce.interface';
export * from './lib/ecommerce-generico.service';
export * from './lib/ecommerce-generico.module';
export * from './lib/interfaces/pedidos-eccomerce.interface';
export * from './lib/interfaces/resultado-busqueda.interface';
export * from './lib/interfaces/historial-ecommerce-mmax';