import { Component, OnInit } from '@angular/core';
import { ModalService } from 'projects/app-shared/src/public-api';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';

@Component({
  selector: 'modal-data-empresa',
  templateUrl: './modal-data-empresa.component.html',
  styleUrls: ['./modal-data-empresa.component.scss']
})

export class ModalDataEmpresaComponent implements OnInit {

  constructor(private modal: ModalService, private genServ : EcommerceGenericoService) { }

  ngOnInit(): void {
  }

  aceptar() {
    this.modal.close('modal-data-empresa');
  }

  get DataEmpresa(){
    return this.genServ.getDataEmpresa()
  }

  get errorMessage() {
    return this.genServ.getErrorMessage();
  }

}
