import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MarianomGeneral } from '../marianom-general.service';
import { EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'foo-mariano-max',
  templateUrl: './foo-mariano-max.component.html',
  styleUrls: ['./foo-mariano-max.component.scss']
})

export class FooMarianoMaxComponent implements OnInit {
  @Input('mode') modo: string;

  terminos_condiciones:string;
  informacion_envio:string;
  politica_devoluciones:string;
  data_Empresa:string;
  work_With_As:string;
  info_Prov:string;

  constructor(private genServ: EcommerceGenericoService,private modal: ModalService,private cdr: ChangeDetectorRef) {
    this.modo = this.modo || 'normal';
  }

  ngOnInit(): void {
      this.terminos_condiciones = this.genServ.getTerminosCondiciones();
      this.informacion_envio = this.genServ.getInformacionEnvio();
      this.politica_devoluciones = this.genServ.getPoliticaDevoluciones();
      this.data_Empresa = this.genServ.getDataEmpresa();
      this.work_With_As = this.genServ.getworkWithA();
      this.info_Prov = this.genServ.getinfoProv();

      this.cdr.detectChanges();
  }

  get telefonoAtencionAlCliente(){
    return this.genServ.gettelefonoAtencionAlCliente()
  }

  get redes(){
    return this.genServ.returnRedes();
  }


  goToInfo(label:string) {
    switch (label) {
      case 'modal-data-empresa':
        this.modal.open('modal-data-empresa');
        break;
      case 'modal-info-prov':
        this.modal.open('modal-info-prov');
        break;
      case 'modal-work-withAs':
        this.modal.open('modal-work-withAs');
        break;
      default:
        this.modal.open('modal-no-implemented');
        break;
    }
  }
}
