<div class="barcontainer">
  <div class="topbarcontainer">
    <div class="logo noselect" (click)="iraLand()" onclick="window.scrollTo(0,0)"
      [ngStyle]="{'position': logoPosition}">
      <img [src]="logosrc" [width]="275" [height]="180" class="logoimg" alt="logo">
    </div>
    <div class="menuses noselect">

      <ng-container *ngFor="let menus of menus">
        <div *ngIf="!menus.oculto"
             [@openClose]="menus.activo ? 'open' : 'closed'"
             [ngClass]="menus.activo ? 'menu-activo' : ''"
             (click)="menuclick(menus)"
             onclick="window.scrollTo(0,0)"
             class="menu">
          {{menus.nombre || 'No Disponible'}}
        </div>
      </ng-container>
    </div>
    <div class="usuario">
      <div class="containermini">
        <mmax-usuario-budget></mmax-usuario-budget>
      </div>

    </div>
    <div class="categi-mobile leftmargin noselect">
      <categorias-generico></categorias-generico>
    </div>
    <div class="carrita-mobile">
      <carrito-generico></carrito-generico>
    </div>
  </div>
  <div FollowBar class="topbarlowcontainer">
    <div *ngIf="scroll" class="logo noselect" (click)="iraLand()" onclick="window.scrollTo(0,0)">
      <img [src]="logosrc" class="logomini" alt="mmax-logo">
    </div>
    <div class="logo noselect" (click)="iraLand()" onclick="window.scrollTo(0,0)"></div>
    <div class="contenedorcategories">
      <categorias-generico></categorias-generico>
    </div>
    <div class="contenedorsearch">
      <searchview-generic></searchview-generic>

    </div>

    <div class="carrita">
      <carrito-generico></carrito-generico>
    </div>
  </div>
  <div class="store-topbarcontainer" *ngIf="this.nombreSucursal != ' Sin Definir'; else NotUser">
    <span class="store-text" style="margin: 0 auto">
      Sucursal {{ nombreSucursal}}
    </span>
  </div>

  <ng-template #NotUser>
    <div class="store-topbarcontainer">
      <span class="store-text" style="margin: 0 auto"></span>
    </div>
  </ng-template>
</div>

<modal-open-point [mode]="'noBackground'" id="sucursal-modal-1">

  <div noBackground class="mybody">
    <sucursales-mmax [mode]="'modal-pc'" style="width: 90%;"></sucursales-mmax>
    <div class="cerrar" (click)="cerrarModale('sucursal-modal-1')">X</div>
  </div>

</modal-open-point>

<modal-open-point [deshabilitarClickFueraDeContexto]="true" id="hay-carrito-offline">
  <div normal>
    <hay-carrito-offline></hay-carrito-offline>
  </div>
</modal-open-point>
