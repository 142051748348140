<div *ngIf="modo==='normal'">
  <div class="buublesinfo noselect">
    <div *ngFor="let ico of icons" class="bubble" (click)="goToTuto(ico.label)">
      <div class="bubblediv">
        <svg-icon class="bubbleico" [src]="ico.src" [svgStyle]="{'width.px':90,'height.px' : 90,'fill':'var(--first-ecommerce-color)'}" ></svg-icon>
      </div>
      <div class="bubletext">
        {{ico.label}}
      </div>
    </div>
  </div>
</div>
<modal-open-point id="modal-retiro-sucursal">
  <div normal class="bubletext">
      <modal-retiro-sucursal></modal-retiro-sucursal>
  </div>
</modal-open-point>

<modal-open-point id="modal-compra-online">
  <div normal class="bubletext">
      <modal-compra-online></modal-compra-online>
  </div>
</modal-open-point>

<modal-open-point id="modal-medio-pago">
  <div normal class="bubletext">
      <modal-medio-pago></modal-medio-pago>
  </div>
</modal-open-point>
