import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, finalize, startWith } from 'rxjs/operators';
import { MarianomGeneral } from './marianom-general.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private serv: MarianomGeneral) {


  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let urlSplitted = req.url.split('/');
    let api = urlSplitted[urlSplitted.length - 2];
    let endpoint = urlSplitted[urlSplitted.length - 1];

    // console.log(api+' '+endpoint);
    //this.closeLoadder.push(true);

    if ((this.serv.whitelist.has(api.trim()) || this.serv.whitelist.has(endpoint.trim()))) {
      this.serv.llamarSpinner();

      return next.handle(req).pipe(

        finalize(() => {
          this.closeLoader();
        })
      );

    }
    else {
      return next.handle(req);
    }




  }
  closeLoader() {
    this.serv.cerrarSpinner();
  }
}
