<div class="caja">
  <div class="leftb">
    <div class="labels noselect">
      <div class="estado" [ngClass]="{'pagado': jistoria.estado==='Pagado'}" >
        {{jistoria.situacion}}
      </div>
      <div class="fechad">
        Pedido <b class="idpedido">{{jistoria.idPedido}}</b> - {{jistoria.fecha}}
      </div>
      <div (click)="verDetalle()" class="verdetalle">
        <div class="plus">
          Ver Detalles
        </div>
        <!-- <div class="icon">
          <svg-icon src="../../assets/icons/magnify.svg" [svgStyle]="{'width':30,'height' : 30,'fill':'var(--first-ecommerce-color)'}" ></svg-icon>
        </div> -->
      </div>
    </div>
    <div class="productos">
      <div *ngFor="let art of articulosDePedido" class="productocontainer">
        <img class="product" [src]="art.getFotoImagen()" loading="lazy">
      </div>
    </div>

  </div>
  <div class="lefta">
    <div class="precio">
      <div *ngIf="!costoFlete" >
        {{jistoria.monto | currency}}
      </div>
      <div *ngIf="costoFlete" >
        {{jistoria.monto | currency}}
        <div>
          <span *ngIf="costoFlete > 0">
            ({{costoFlete | currency }} Flete)
          </span>
          <span *ngIf="costoFlete<=0" >
            Envío Gratis
          </span>
        </div>
      </div>
    </div>
      <button class="botncont" (click)="repetirCompra()" >
        Repetir Compra
      </button>
      <button class="botncont" (click)="pagar()" [ngStyle]="{'display': displayPagoDiferido}">
        Pagar
      </button>
  </div>
</div>
