<div normal>
        <modal-default-mmaxapp>
                <div class="tarjetavaciar">
                        <div class="titulo">
                                Cambiar Sucursal de Retiro
                        </div>
                        <div class="leyendavaciar">
                                El cambio de la Sucursal de retiro puede modificar el precio y la disponibilidad de
                                algunos productos.
                                <br>
                                ¿Desea cambiar de sucursal de retiro?
                        </div>
                        <div class="botones">
                                <button class="buttonpc whibutton">Cancelar</button>
                                <button class="buttonpc blubutton spacerRed">Cambiar</button>
                        </div>
                </div>
        </modal-default-mmaxapp>
</div>