import { Component, OnInit, Input } from '@angular/core';
import { GenericoSlideAnim } from '../etc/generico-animation'
import { MarianomGeneral } from '../marianom-general.service';
import { MenuMmaxInterface } from '../../app-shared/etc/mmax-menu.interface';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CategoriasWebMenu, EcommerceGenericoService } from 'projects/ecommerce-generico/src/public-api';
import { ModalService } from 'projects/app-shared/src/public-api';

@Component({
  selector: 'categorias-generico',
  templateUrl: './categorias-generico.component.html',
  styleUrls: ['./categorias-generico.component.scss'],
  animations: [GenericoSlideAnim]

})

export class CategoriasGenericoComponent implements OnInit {
  @Input('mode') modo: string;
  @Input() login: string;
  @Input() myData: string;
  @Input() categoria: CategoriasWebMenu;

  menues: { nombre: string; url: string; urlmobile: string; icourl: string; }[];
  swicher: boolean;
  isLogued: boolean;
  width: number = window.innerWidth;
  constructor(private serv: MarianomGeneral, private genServ: EcommerceGenericoService, private route: ActivatedRoute, private modal: ModalService) {
    this.modo = this.modo || 'normal';
    this.login = this.login || 'none';
    this.myData = this.login || 'none';
    this.swicher = false;

    this.menues = [
      {
        nombre: 'Mis Datos',
        url: '/misdatos',
        urlmobile: '/app-mobile/app/account',
        icourl: '../../assets/icons/credit-card-outline-white.svg'
      }
    ];

    this.isLogued = this.serv.isloged()
  }

  ngOnInit(): void {
    if (this.isLogued === false) {
      this.myData = 'none';
      this.login = 'block';
    } else {
      this.myData = 'flex';
      this.login = 'none';
    }
  }

  get menus(): MenuMmaxInterface[] {
    return this.serv.rutas
  }

  get desplegado(): boolean {
    return this.serv.desplegadocat;
  }

  get categorias() {
    return this.genServ.getCategoriasWeb();
  }

  openLogin() {
    this.modal.open('login-modal-1');
    this.abrirCerrar();
  }

  cerrarSesion() {
    this.modal.open('modal-salir');
    this.abrirCerrar();
  }

  cerrarCateg(event: CategoriasWebMenu) {
    for (let i = 0; i < this.categorias.length; i++) {
      const element = this.categorias[i];
      element.desplegado = false;
    }

    if (event.desplegado === true) {
      event.desplegado = false;
    }
    if (event.desplegado === false) {
      event.desplegado = true;
    }
  }

  abrirCerrar() {
    if (this.serv.isloged() === false) {
      this.myData = 'none';
      this.login = 'block';
    } else {
      this.myData = 'flex';
      this.login = 'none';
    }

    if (this.enVistaProductosPantallaTablet()) {
      this.serv.toggleSideBarBusqueda();
      return
    }

    if (this.enVistaPantallaEscritorio()) {   
      if (this.serv.getOcultarSideBarBusqueda() == false)
        this.serv.toggleSideBarBusqueda();

      this.serv.desplegaryCerrar(!this.desplegado);
      return
    }

    this.serv.desplegaryCerrar(!this.desplegado);
    this.serv.toggleSideBarBusqueda()
  }

  gotoURL(url: string) {
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.gotoURL(url, this.route);
    this.abrirCerrar();
  }

  gotoBusqueda(url: string) {/// METODO A CAMBIAR, NAVIGATOR
    for (let i = 0; i < this.menus.length; i++) {
      const element = this.menus[i];
      element.activo = false;
    }
    this.serv.gotoURL(url, this.route);
    this.serv.goToProducts(true);
  }

  menuclick(menus: MenuMmaxInterface) {
    if (!this.serv.isloged()) {
      if (menus.ruta == "historial" || menus.ruta == "sucursales") {
        this.serv.abrirModalLogin();
      } else {
        this.serv.gotoURL("/" + menus.ruta, this.route);
        this.serv.activatethis(menus);
      }
    } else {
      this.serv.gotoURL("/" + menus.ruta, this.route);
      this.serv.activatethis(menus);
    }

  }

  iraLand() {
    this.serv.navigator.goLandPage(this.route);
    this.serv.cerrarUI();
  }

  enVistaProductosPantallaTablet() {
    this.width = window.innerWidth;
    console.log('enVistaProductosPantallaTablet', this.serv.getMenuActivoNombre() === 'Productos', this.width > 599, this.width < 1000, this.route.url)
    console.log('desplegado=', this.desplegado)
    return (this.serv.getMenuActivoNombre() === 'Productos' && this.width > 599 && this.width < 1000)
  }

  enVistaPantallaEscritorio() {
    this.width = window.innerWidth;
    return (this.width > 1000)
  }

  reiniciarFiltroCategoria(categoriaSeleccionada: any) {
    let seCerroDespliegueDeCategoria = categoriaSeleccionada.desplegado === false
    //console.log('reiniciarFiltroCategoria',categoriaSeleccionada)
    if (seCerroDespliegueDeCategoria) {
      this.genServ.iniciarbusqueda('');
    }
  }
}
