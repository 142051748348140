<div normal>
        <modal-default-mmaxapp>
                <div class="tarjetaconfirmar">
                        <div class="titulo">
                                Necesita completar el pago.
                        </div>
                        <div class="leyendaconfirmar">
                                No puede continuar hasta que el pago este completo.
                                <br>
                                <br>
                                Si tiene algun problema comuniquese con el proveedor. Gracias!
                        </div>
                        <div class="botones">
                                <button class="buttonpc whibutton" (click)="aceptar()">Aceptar</button>
                        </div>
                </div>
        </modal-default-mmaxapp>
</div>